/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe and its
 * suppliers and are protected by all applicable intellectual property laws,
 * including trade secret and or copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import styled from 'styled-components';

const FilterBadgeContainer = styled.div`
  border-radius: 50%;
  background-color: #444444;
  color: white;
  width: 20px;
  height: 18px;
  position: absolute;
  top: 9px;
  right: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  padding-top: 2px;
`;

export const FilterBadge = ({ count, type }) => {
  return <FilterBadgeContainer data-testid={`${type}-badge`}>{count}</FilterBadgeContainer>;
};
