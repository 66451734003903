/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import Button from '@react/react-spectrum/Button';
import Heading from '@react/react-spectrum/Heading';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CTAMegasignIcon from 'dc-icons/organizer/emptystateillustrations/S_IlluEmptyStateSendAgreementsInBulk_192x144.svg';
import CTAWebformIcon from '../../images/s_emptystate_webform_192x144.svg';
import CTAReusableTemplateIcon from 'dc-icons/organizer/emptystateillustrations/S_IlluEmptyStateCreateReusableTemplates_192x144.svg';
import CTAAgreementIcon from 'dc-icons/organizer/emptystateillustrations/S_IlluEmptyStateFillSign_280x192.svg';

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 84px;
  text-align: center;
`;

const Title = styled(Heading)`
  margin: 24px 0 0;
`;

const Body = styled.p`
  margin: 12px 12px 20px;
  max-width: 480px;
`;

const CTA = styled(Button)`
  margin-bottom: 20px;
`;

const getIcon = type => {
  switch (type) {
    case 'megasign':
      return <CTAMegasignIcon />;
    case 'webform':
      return <CTAWebformIcon />;
    case 'template':
      return <CTAReusableTemplateIcon />;
    default:
      return <CTAAgreementIcon />;
  }
};

const CallToAction = ({ heading, description, illustration, action, onClick }) => {
  return (
    <MainContainer className="call-to-action-container">
      {getIcon(illustration)}
      <Title variant="pageTitle">{heading}</Title>
      <Body>{description}</Body>
      {!!action && (
        <CTA variant="cta" onClick={onClick}>
          {action}
        </CTA>
      )}
    </MainContainer>
  );
};

CallToAction.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  illustration: PropTypes.string.isRequired,
  action: PropTypes.string,
  onClick: PropTypes.func
};

export default CallToAction;
