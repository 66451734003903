/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
class CallToActionUtil {
  static getTypeAndState(type, state) {
    return state ? `${type}.${state}` : type;
  }

  static hasAction(
    type,
    state,
    canUserSendForSignature,
    canUserCreateTemplate,
    userHasNoSignAccess,
    canUserCreateWebForm
  ) {
    const typeAndState = CallToActionUtil.getTypeAndState(type, state);
    // inclusion rule: type.state or type
    switch (typeAndState) {
      case 'all':
      case 'agreement.waiting_for_others':
        return userHasNoSignAccess || canUserSendForSignature;
      case 'template':
        return canUserCreateTemplate;
      case 'webform':
        return canUserCreateWebForm;
      case 'webform.all':
      case 'webform.waiting_for_others':
      case 'webform.completed':
      case 'megasign':
        return true;
      default:
        return false;
    }
  }

  static hasHeaderCTAButton(
    type,
    state,
    canUserSendForSignature,
    canUserCreateTemplate,
    isMobile,
    userHasNoSignAccess,
    canUserCreateWebForm,
    canUserSendInBulk
  ) {
    const typeAndState = CallToActionUtil.getTypeAndState(type, state);
    switch (typeAndState) {
      case 'all':
        return !isMobile && (userHasNoSignAccess || canUserSendForSignature);
      case 'template':
        return canUserCreateTemplate && !isMobile;
      case 'webform':
        return canUserCreateWebForm && !isMobile;
      case 'megasign':
        return canUserSendInBulk && !isMobile;
      default:
        return false;
    }
  }

  // Determine if we have agreement count in other state
  static hasFacetCount(queryFacets) {
    return queryFacets && queryFacets.all.count > 0;
  }

  // Key to be used for localization string and action type based of agreement state and type
  static getActionKey(type, state, suffix) {
    const typeAndState = CallToActionUtil.getTypeAndState(type, state);
    const actionKey = suffix ? `${typeAndState}.${suffix}` : typeAndState;
    return actionKey;
  }

  static getIntlKey(section, type, state, suffix, isDCWeb = false) {
    const actionKey = CallToActionUtil.getActionKey(type, state, suffix);
    const intlKey = `call_to_action.${actionKey}.${section}`;
    if (isDCWeb && actionKey === 'template' && section === 'button') {
      // in Acrobat Web the CTA is 'Create e-sign template' instead of 'Create a template' in Acrobat Sign
      return `${intlKey}.dc_web`;
    }
    return intlKey;
  }
}
export default CallToActionUtil;
