import Env from './env';
import { isMobile } from './env';
import SignApi from './api';
import UserSettings from './userSettings';
import UserSettingsSearch from './userSettingsSearch';
import UserShares from './userShares';
import Groups from './groups';
import User from './user';
import UserGroups from './userGroups';
import UserEsignLimits from './userEsignLimits';
import Floodgate from './floodgate';
import UnresolvedBounceIndicatorFeature from './unresolvedBounceIndicatorFeatureForGroup';

let storesPromise;

let stores = {
  Api: null,
  Floodgate: new Floodgate(),
  Env,

  // made available in App.js once locale is loaded
  Intl: {},

  /**
   * aggregate stores ready - wait for all dependencies
   *
   * @return {Promise<any[]>}
   */
  ready: () =>
    storesPromise ||
    (storesPromise = Promise.all([
      SignApi.ready(),
      isMobile(),
      // other parallel Promises here...
      stores.Floodgate.ready()
    ])
      .then(resolved => {
        stores.Api = resolved[0];
        stores.isMobile = !!resolved[1];
        stores.UserSettings = new UserSettings(stores.Api);
        stores.UserSettings.fetch(); // manage agreement list is always logged in

        stores.UserSettingsSearch = new UserSettingsSearch(stores.Api);
        stores.UserSettingsSearch.fetch();

        // Don't need to fetch user shares when in advanced account share switched mode
        if (!stores.Env.sharer) {
          // Non-blocking call to fetch user shares
          stores.UserShares = new UserShares(stores.Api);
          stores.UserShares.fetch();
          // Don't need to fetch UnresolvedBounceIndicatorFeature when in advanced account share mode
          stores.UnresolvedBounceIndicatorFeatureForGroup = new UnresolvedBounceIndicatorFeature(stores.Api);
        }

        // Don't need to fetch user esignLimits when in Adobe sign
        if (stores.Env.isDCWeb) {
          // Non-blocking call to fetch user's esign limits
          stores.UserEsignAccess = new UserEsignLimits();
          stores.UserEsignAccess.fetch();
        }

        // Non-blocking call to fetch user info
        stores.User = new User(stores.Api);
        stores.User.fetch();

        return Promise.all([stores.UserSettings.ready(), stores.UserSettingsSearch.ready()]);
      })
      .then(() => {
        const settings = stores.UserSettings.settings;
        if (settings.get('usersInMultipleGroupsEnabled')) {
          // Group List
          stores.Groups = new Groups(stores.Api);
          stores.Groups.fetch();

          // User to Groups
          stores.UserGroups = new UserGroups(stores.Api);
          stores.UserGroups.fetch();
        }
      })
      .then(() => ({
        Api: stores.Api,
        UserSettings: stores.UserSettings,
        UserSettingsSearch: stores.UserSettingsSearch
      }))),

  // Used by unit tests
  reset: () => {
    stores.Api = null;
    stores.UserSettings = stores.UserSettingsSearch = stores.UserShares = stores.Groups = stores.UserGroups = undefined;
    storesPromise = undefined;
  }
};

// expose to parent app (primarily for test harness)
Env.plugin.stores = stores;

export default stores;
