/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import Button from '@react/react-spectrum/Button';
import Label from '@react/react-spectrum/Label';
import { daysUntilDate } from '../../../utils/DateUtil';

// To prevent table hover/selection highlight showing as action button background
const ButtonContainer = styled.div`
  & .spectrum-Button--primary {
    background-color: white;
  }
`;

const getEndingSoonLabelProps = date => {
  const endingInDays = daysUntilDate(date);
  let id, variant;
  if (endingInDays < 0) {
    if (endingInDays > -14) {
      id = 'end_date.ended_within_14_days';
    } else if (endingInDays > -30) {
      id = 'end_date.ended_within_30_days';
    }
    variant = 'grey';
  } else if (endingInDays < 30) {
    id = 'end_date.within_30_days';
    variant = 'red';
  } else if (endingInDays < 90) {
    id = 'end_date.within_90_days';
    variant = 'orange';
  }
  return { id, variant };
};

const EndDateConfirmedCell = ({ date, isConfirmed, onClick, rowHasFocus, rowIndex, intl }) => {
  if (isConfirmed) {
    const { id, variant } = getEndingSoonLabelProps(date);
    return id ? <Label variant={variant}>{intl.formatMessage({ id })}</Label> : null;
  } else {
    return (
      <ButtonContainer>
        <Button
          label={intl.formatMessage({ id: 'end_date.review' })}
          onClick={() => onClick(rowIndex)}
          tabIndex={rowHasFocus ? 0 : -1}
          variant="primary"
        />
      </ButtonContainer>
    );
  }
};

EndDateConfirmedCell.propTypes = {
  date: PropTypes.string.isRequired,
  intl: PropTypes.shape.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  rowHasFocus: PropTypes.bool.isRequired,
  rowIndex: PropTypes.number.isRequired
};

export default injectIntl(EndDateConfirmedCell);
