import { logger } from 'as-ducati-utils';
import signAnalytics from './analytics';

const signLogger = logger.getLogger('sign-manage');
const analytics = signAnalytics.forLogger();

/**
 * log an error to console and send it to analytics server
 *
 * @param name {string} subtype identifier, component name, etc.
 * @param err {object|string} the error
 */
signLogger.server = (name, err, context) => {
  // log local
  signLogger.error(name, err);

  // send to server
  analytics.setContext(Object.assign({}, context));
  analytics.send(name, err);
};

export default signLogger;
export { logger };
