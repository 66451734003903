import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import moment from 'moment';
import styled from 'styled-components';
import Dialog from '@react/react-spectrum/Dialog';
import RadioGroup from '@react/react-spectrum/RadioGroup';
import Radio from '@react/react-spectrum/Radio';
import Wait from '@react/react-spectrum/Wait';
import Datepicker from '@react/react-spectrum/Datepicker';
import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import ViewAgreementIcon from '@react/react-spectrum/Icon/LinkOutLight';
import { media, isMedia } from '../../utils/mediaQuery';
import Preview from './Preview';
import { localizeComponent } from '../../utils/DropinComponentLocalizer';
import RoutingUtil from '../../utils/RoutingUtil';
import { analyticsFor } from '../../utils/analytics';
import log from '../../utils/logger';

const StyledDialog = styled(Dialog)`
  flex-direction: row;
  padding: 0;
  max-width: 1176px;
  background: white !important;
  .spectrum-Dialog-header {
    display: none;
  }
  .spectrum-Dialog-footer {
    display: none;
  }

  @media ${media.phone} {
    max-width: 400px;
  }
`;

const ActionContainer = styled.div`
  flex: 0 0 340px;
  overflow: auto;
  margin: 40px;
`;

const PreviewContainer = styled.div`
  background-color: #f5f5f5;
  flex: 0 1 756px;
  margin: 0;
  overflow: auto;
`;

const ActionHeader = styled.div`
  border-bottom: 2px solid #e1e1e1;
  width: 95%;
`;

const ActionFooter = styled.div`
  text-align: right;
  margin: 0 16px 7px 0;
`;

const StyledWait = styled(Wait)`
  &&& {
    position: relative;
  }
`;

const ViewAgreement = styled(Button)`
  margin: 0 0 56px;
`;

const StyledHeading = styled(Heading)`
  flex: 1 1 auto;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  outline: none;
  color: #2c2c2c !important;
`;

const DatePickerSection = styled.section`
  margin-bottom: 8px;
`;

const StyledDatepicker = styled(Datepicker)`
  width: 100%;
  .spectrum-Textfield {
    width: 100%;
    max-width: 270px;
  }
`;

const ReviewEndDates = styled.div``;

const ErrorMessage = styled.div`
  display: ${props => (props.show ? 'inherit' : 'none')};
  color: #d7373f;
  max-width: 300px;
`;

const SectionLabel = styled.p`
  margin: 24px 0 16px 0;
  font-size: 14px;
  text-transform: none;
`;

const InformationLabel = styled.p`
  margin: 4px 0;
  font-size: 13px;
  text-transform: none;
  color: #959595;
`;

const DatePickerLabel = styled.p`
  margin: 1px 0 24px;
  color: #959595;
  font-size: 14px;
  text-transform: none;
  margin-top: 16px;
  font-style: italic;
`;

const StyledRadioGroup = styled(RadioGroup)`
  .spectrum-Radio {
    min-width: 277px;
    border-color: #e1e1e1;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    padding: 4px 16px;
    margin-right: 2px;
    margin-bottom: 8px;

    &.isActive {
      border-color: #1473e6;
      border-width: 2px;
      .spectrum-Radio-label {
        color: #4b4b4b !important;
      }
    }
  }
`;

const EndDateOption = styled(Radio)``;

const NoDateOption = styled(Radio)`
  .spectrum-Radio-label {
    color: #959595 !important;
    font-size: 14px;
    font-style: italic;
  }
`;

const FOUND_DATE = 'FOUND_DATE';
const NO_DATE = 'NO_DATE';
const DATEFORMAT = 'YYYY-MM-DD';
const SENSEI = 'SENSEI';
const USER = 'USER';
const AGREEMENT = 'AGREEMENT';
const ESIGN = 'ESIGN';
const EMPTY_LOCATIONS = 'EMPTY_LOCATIONS';
const FOUND_END_DATE = 'found_end_date';
const EDIT_END_DATE = 'edit_end_date';
const NO_END_DATE = 'no_end_date';
const CONFIRMED = 'confirmed';
const DISMISSED = 'dismissed';
const VIEW_AGREEMENT = 'view_agreement';

const analytics = analyticsFor(analyticsFor.REVIEW_END_DATE_ACTION);

const fetchMetadata = metadata => metadata.fetch();

@observer
class ReviewEndDateBase extends Component {
  @observable
  isDatePickerEmpty = true;

  @observable
  selectedRadio = '';

  @observable
  invalidDate = false;

  @observable
  loading = false;

  @observable
  viewAgreementUrl = '';

  get endDates() {
    let terminationDates = this.metadata.values.map(model => {
      return {
        date: model.get('date'),
        probability: model.get('probability')
      };
    });
    if (terminationDates && terminationDates.length > 0) {
      terminationDates.sort((d1, d2) => d2.probability - d1.probability);
      terminationDates = terminationDates.map(model => model.date);
      // Remove duplicate dates if any from the array
      terminationDates = [...new Set(terminationDates)];
    }
    return terminationDates;
  }

  @computed
  get endDate() {
    const endDates = this.metadata.values;
    if (endDates !== undefined && endDates.length !== 0) {
      return endDates.at(0).get('date');
    }
    return '';
  }

  get agreementUrl() {
    let { agreementType } = this.props;
    // DCES-4320130: Sign context board sends agreement type as 'AGREEMENT' instead of 'ESIGN'
    agreementType = agreementType === AGREEMENT ? ESIGN : agreementType;
    const type = agreementType ? agreementType.toLowerCase() : 'esign';
    const url = `/public/agreements/view/${this.props.agreementId}?app=dc&type=${type}`;
    return RoutingUtil.transformPathForDCWeb(url);
  }

  @computed
  get isConfirmDisabled() {
    return this.loading || this.invalidDate || (this.isDatePickerEmpty && this.selectedRadio === '');
  }

  getDateLocations(selectedDate) {
    if (!selectedDate || selectedDate === NO_DATE) {
      return '';
    }
    const date = this.metadata.values.filter(value => value.get('date').includes(selectedDate));
    if (date && date.length !== 0) {
      let dateLocations = date[0].get('sentence');
      if (dateLocations && dateLocations.locations && dateLocations.locations.length === 0) {
        // Fallback to show overlay on only the date if sentence locations being returned is empty
        dateLocations = date[0].get('string');
      }
      // Case when empty location is returned by the API
      if (dateLocations && dateLocations.locations && dateLocations.locations.length === 0) {
        log.warn('agreementMetadata: date location is empty', {
          agreementId: this.props.agreementId,
          dateLocations: dateLocations
        });
        return EMPTY_LOCATIONS;
      }
      return dateLocations;
    }
    return '';
  }

  @action
  setLoading(val) {
    this.loading = val;
  }

  @action
  updateObservables() {
    if (this.endDates.length !== 0) {
      this.selectedRadio = this.selectedRadio ? this.selectedRadio : this.endDates[0];
    }
    this.viewAgreementUrl = this.agreementUrl;
  }

  constructor(props) {
    super(props);
    this.metadata = new props.Api.Gateway.AgreementMetadata.Agreements.Expirations({
      id: this.props.agreementId
    });
    this.methodFound = SENSEI;
    this.dialogRef = React.createRef();
    this.datepicker = null;
    this.datepickerRef = element => {
      this.datepicker = element;
    };
  }

  componentDidMount() {
    this.setLoading(true);
    Promise.all([fetchMetadata(this.metadata)])
      .then(() => {
        this.updateObservables();
        this.setLoading(false);

        // Set tabindex of the calendar button to 0, to allow for keyboard focus. (DCES-4271498)
        if (this.datepicker) {
          const calendarEl = ReactDOM.findDOMNode(this.datepicker.overlayTriggerRef);
          calendarEl.tabIndex = 0;
        }
      })
      .catch(error => {
        this.setLoading(false);
        this.props.showToast({
          type: 'error',
          message: error.message
        });
      });
  }

  @action
  onChangeDatepicker(date) {
    if (!date) {
      this.isDatePickerEmpty = true;
      this.invalidDate = false;
      return;
    }
    this.isDatePickerEmpty = false;
    // Clear the radio options when the datepicker value is selected
    this.selectedRadio = '';
    // DCES-4319048 - endOf("day") - Set end of day selected
    const newDate = moment(date, DATEFORMAT, true).endOf('day');
    this.invalidDate = !newDate.isValid();
    this.chosenDate = newDate;
    this.methodFound = USER;
  }

  @action
  onChangeRadio(selected) {
    this.selectedRadio = selected;
    // Clear the datepicker values when radio option is selected
    this.datepicker.state.valueText = '';
    this.datepicker.state.value = '';
    this.datepicker.state.invalid = false;
    this.invalidDate = false;
    this.isDatePickerEmpty = false;
    if (this.selectedRadio === NO_DATE) {
      this.chosenDate = '';
      this.methodFound = USER;
    } else {
      this.chosenDate = selected;
      this.methodFound = SENSEI;
    }
  }

  @action
  onSuccess(date) {
    const { formatMessage } = this.props.intl;
    this.dialogRef.current && this.dialogRef.current.props.onClose();
    this.props.onModify(this.endDate);
    analytics.success();
    this.props.showToast({
      text: formatMessage({ id: 'end_date_dialog.success' }),
      type: 'success'
    });
  }

  viewFullAgreement() {
    analytics.setContext({ action: VIEW_AGREEMENT });
    analytics.send();
    window.open(this.viewAgreementUrl, '_blank');
  }

  /**
   * Updates the agreement model to save the new end date
   */
  updateAgreementEndDate() {
    let newEndDate;
    const oldDates = this.endDates;
    if (this.selectedRadio !== '') {
      // If the user has selected the option - 'Agreement has no end date' then set newEndDate as ''
      if (this.selectedRadio === NO_DATE) {
        newEndDate = '';
        analytics.setContext({ option: NO_END_DATE, action: CONFIRMED });
      } else {
        newEndDate = this.chosenDate ? this.chosenDate : this.endDates[0];
        const index = this.endDates && newEndDate ? this.endDates.indexOf(newEndDate) : -1;
        analytics.setContext({ option: FOUND_END_DATE, action: CONFIRMED, endDate: newEndDate, index: index });
      }
    } else {
      newEndDate = this.chosenDate !== '' ? this.chosenDate.toISOString() : '';
      analytics.setContext({ option: EDIT_END_DATE, action: CONFIRMED, endDate: newEndDate });
    }

    this.setLoading(true);
    const values = this.metadata.values;

    if (this.methodFound === SENSEI) {
      this.model = values.findWhere({ date: newEndDate });
    }

    // remove all dates and set only 1 date in the collection
    values.reset();
    if (newEndDate !== '') {
      // If one of the found dates is selected then preserve the whole model
      if (this.methodFound === SENSEI) {
        values.reset(this.model);
      } else {
        values.add({
          date: newEndDate,
          methodFound: USER
        });
      }
    }

    this.metadata
      .save({ userConfirmed: true })
      .then(() => {
        this.setLoading(false);
        this.onSuccess(this.endDate);
      })
      .catch(error => {
        this.setLoading(false);
        // Clear the end date in the model if it was not updated due to some error and then reset the old dates back in the model
        values.reset(oldDates);
        analytics.failed(error);
        this.dialogRef.current && this.dialogRef.current.props.onClose();
        this.props.showToast(error);
      });
  }

  onCancel() {
    analytics.setContext({ action: DISMISSED });
    analytics.send();
    this.props.onClose();
  }

  getEndDateRadioOptions() {
    const { intl } = this.props;
    const radioOptions = [];
    const radioOptionKey = 'radio-option-';

    this.endDates.forEach((date, index) => {
      radioOptions.push(
        <EndDateOption
          key={radioOptionKey + FOUND_DATE + index}
          value={date}
          label={null}
          disabled={this.loading}
          className={this.selectedRadio === date ? 'isActive' : ''}
        >
          <FormattedDate value={date} year="numeric" month="short" day="2-digit" />
        </EndDateOption>
      );
    });

    // no date option
    radioOptions.push(
      <NoDateOption
        key={radioOptionKey + NO_DATE}
        value={NO_DATE}
        disabled={this.loading}
        className={this.selectedRadio === NO_DATE ? 'isActive' : ''}
        label={intl.formatMessage({ id: 'end_date_dialog.no_end_date' })}
      />
    );
    return radioOptions;
  }

  render() {
    const { showToast, onClose, Api, agreementId, intl, agreementName } = this.props,
      { formatMessage } = intl,
      container = window.document.body,
      isPhone = isMedia(media.phone);

    return (
      <StyledDialog
        backdropClickable={true}
        container={container}
        showToast={showToast}
        onClose={onClose}
        ref={this.dialogRef}
      >
        {!isPhone && (
          <PreviewContainer>
            <Preview
              Api={Api}
              intl={intl}
              showToast={showToast}
              agreementId={agreementId}
              agreementName={agreementName}
              agreementUrl={this.viewAgreementUrl}
              dateLocations={this.getDateLocations(this.selectedRadio)}
            />
          </PreviewContainer>
        )}
        <ActionContainer>
          {this.loading && <StyledWait centered />}
          <ActionHeader>
            <StyledHeading variant="pageTitle">{formatMessage({ id: 'end_date_dialog.title' })}</StyledHeading>
          </ActionHeader>
          <SectionLabel>{formatMessage({ id: 'end_date_dialog.label' })}</SectionLabel>
          <ReviewEndDates>
            <StyledRadioGroup
              vertical
              selectedValue={this.selectedRadio}
              onChange={selected => this.onChangeRadio(selected)}
              disabled={this.loading}
            >
              {this.getEndDateRadioOptions()}
            </StyledRadioGroup>
          </ReviewEndDates>
          <DatePickerSection>
            <InformationLabel>{formatMessage({ id: 'end_date_dialog.date_picker_label' })}</InformationLabel>
            <StyledDatepicker
              onChange={date => this.onChangeDatepicker(date)}
              onBlur={e => {
                this.onChangeDatepicker(e.target.value);
              }}
              placement="right"
              placeholder={formatMessage({ id: 'end_date_dialog.datepicker_placeholder' })}
              type="date"
              ref={this.datepickerRef}
              invalid={this.invalidDate}
              disabled={this.loading}
              zIndex={1}
            />
            <ErrorMessage show={this.invalidDate}>
              {formatMessage({ id: 'end_date_dialog.datepicker_error' })}
            </ErrorMessage>
            <DatePickerLabel>{formatMessage({ id: 'end_date_dialog.date_picker_info' })}</DatePickerLabel>
          </DatePickerSection>
          {this.viewAgreementUrl && (
            <ViewAgreement variant="action" onClick={() => this.viewFullAgreement()} icon={<ViewAgreementIcon />}>
              {formatMessage({ id: 'end_date_dialog.view_full_agreement' })}
            </ViewAgreement>
          )}
          <ActionFooter>
            <Button onClick={() => this.onCancel()}>{formatMessage({ id: 'dialog.cancel' })}</Button>
            <Button variant="cta" disabled={this.isConfirmDisabled} onClick={() => this.updateAgreementEndDate()}>
              {formatMessage({ id: 'dialog.done' })}
            </Button>
          </ActionFooter>
        </ActionContainer>
      </StyledDialog>
    );
  }
}

ReviewEndDateBase.propTypes = {
  intl: PropTypes.shape.isRequired,
  onModify: PropTypes.func,
  showToast: PropTypes.func.isRequired,
  agreementId: PropTypes.string.isRequired,
  agreementName: PropTypes.string.isRequired,
  agreementType: PropTypes.string,
  Api: PropTypes.objectOf(PropTypes.any).isRequired
};

export { ReviewEndDateBase };

const ReviewEndDateDropin = localizeComponent(ReviewEndDateBase);
export default ReviewEndDateDropin;
