/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

import EmptySearchIcon from 'dc-icons/organizer/emptystateillustrations/S_IlluNoSearchResults_180x160.svg';
import getLocalizedGoUrl from '../../utils/GoURLUtil';

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 84px;
  text-align: center;
`;

const Title = styled.p`
  font-size: 30px;
  font-weight: lighter;
  margin: 0;
`;

const Message = styled.p`
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const StyledText = styled.p`
  margin: 0;
`;

export const SEARCH_ERROR_TYPES = {
  INVALID_QUERY: 'invalid_query',
  TIMED_OUT: 'timed_out'
};

const formatToken = (query, token, tokenPos) => {
  return (
    <StyledText>
      {query.substr(0, tokenPos - 1)}
      <strong>{query.substr(tokenPos - 1, token.length)}</strong>
      {query.substr(tokenPos - 1 + token.length)}
    </StyledText>
  );
};

const formatMessage = (message, token) => {
  const errorStrings = message.split(token);
  return (
    <StyledText>
      {errorStrings[0]}
      <strong>{token}</strong>
      {errorStrings[1]}
    </StyledText>
  );
};

const SearchError = ({ intl, error, query, token, tokenPos, message }) => {
  return (
    <MainContainer className={'EmptySearch'}>
      <EmptySearchIcon />
      <Title>{intl.formatMessage({ id: `${error}.title` })}</Title>
      {token && message && (
        <Message>
          <StyledText>{formatToken(query, token, tokenPos)}</StyledText>
          <StyledText>{message.includes(token) ? formatMessage(message, token) : message}</StyledText>
        </Message>
      )}
      <StyledText
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage(
            { id: `${error}.body` },
            {
              a: chunks => `<a target="_blank" href="${getLocalizedGoUrl('sgql')}">${chunks}</a>`
            }
          )
        }}
      />
    </MainContainer>
  );
};

export default injectIntl(SearchError);
