/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/* global __webpack_public_path__:true DROPIN_NAME_VERSION */
/* eslint no-unused-vars: 0, no-global-assign: 0, camelcase:0 */

import { discovery } from 'dc-core';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { getLocalizedApp } from './localizedApp';
import stores from './stores';
import logger from './utils/logger';

const Env = stores.Env;
const isAutoRender = Env.plugin && Env.plugin.domRoot;

function manage_sign_app_load(serverPath) {
  if (isAutoRender) {
    return Promise.reject(new Error('sign-manage-dropin: Refusing load when auto-render is on.'));
  }
  // __webpack_public_path__ = serverPath;
  // return import(/* webpackChunkName: "sign_manage" */ './view/signmanage/SignManageContainer')
  //     .then(dropin => dropin.default);
  // We need to set the public path so that require loads the
  // next module from the correct server.  Otherwise it would
  // load from the application host
  return import(/* webpackChunkName: 'sign-manage' */ './localizedApp').then(dropin => {
    return dropin.getLocalizedApp(serverPath);
  });
}

function sign_review_end_date_load(serverPath, callback) {
  // modify __webpack_public_path__ only if serverPath is defined
  if (serverPath) {
    // translations are relative to /static/js, but serverPath
    // already has that - remove it to avoid duplication.
    serverPath = serverPath.replace('/static/js', '');

    // We need to set the public path so that require loads the next module from the correct server.  Otherwise it would
    // load from the application host
    __webpack_public_path__ = serverPath;
  }

  return import(/* webpackChunkName: "reviewEndDateDropin" */ './dropins/EndDate/ReviewEndDate').then(dropin => {
    if (callback) {
      callback(dropin.default);
    }
    return dropin.default;
  });
}

function sign_end_date_calendar_integration_load(serverPath, callback) {
  // modify __webpack_public_path__ only if serverPath is defined
  if (serverPath) {
    // translations are relative to /static/js, but serverPath
    // already has that - remove it to avoid duplication.
    serverPath = serverPath.replace('/static/js', '');

    // We need to set the public path so that require loads the next module from the correct server.  Otherwise it would
    // load from the application host
    __webpack_public_path__ = serverPath;
  }

  return import(
    /* webpackChunkName: "endDateCalendarIntegrationDropin" */ './dropins/EndDate/EndDateCalendarIntegration'
  ).then(dropin => {
    if (callback) {
      callback(dropin.default);
    }
    return dropin.default;
  });
}

const dropins = {
  'sign-manage': {
    id: 'sign-manage',
    name: 'SignManage',
    label: 'SignManage',
    load: manage_sign_app_load,
    // helper function to allow on-demand (non-router-based) rendering by container
    renderInto(domRoot, props) {
      props = props || {};

      const LocalizedApp = getLocalizedApp(props.serverPath);
      return ReactDOM.render(
        <BrowserRouter>
          <LocalizedApp {...props} />
        </BrowserRouter>,
        document.querySelector(domRoot)
      );
    },
    ready() {
      return stores.ready();
    }
  },
  'sign-review-end-date': {
    id: 'sign-review-end-date',
    load: sign_review_end_date_load
  },
  'sign-end-date-calendar-integration': {
    id: 'sign-end-date-calendar-integration',
    load: sign_end_date_calendar_integration_load
  }
};

// dc-discovery needs the dropins to be exported in a specific format.
// window.adobe_dc_sdk.components["DROPINNAME"].dropins["DROPINNAME"].load -> called in _loadBootstrap method.
window.adobe_dc_sdk = window.adobe_dc_sdk || {};
window.adobe_dc_sdk.components = window.adobe_dc_sdk.components || {};
window.adobe_dc_sdk.components['sign-manage'] = {
  dropins
};

if (isAutoRender) {
  logger.info(`auto-rendering into ${Env.plugin.domRoot}`);
  // call ready() to mimic managepage
  dropins['sign-manage'].ready().then(() => {
    dropins['sign-manage'].renderInto(Env.plugin.domRoot, Env.plugin);
  });
}
