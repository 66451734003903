import logger from '../utils/logger';

/**
 * User Groups Information: /users/byId/groups
 */
export default class UserGroups {
  constructor(Api) {
    this.Api = Api;
    this.groups = new Api.Users.Groups(null, { userId: 'me' });
    this.promise = null;
  }

  fetch() {
    this.promise = this.groups
      .fetch()
      .then(() => {
        this.userGroupsAvailable = true;
        this.primaryGroup = this.groups.findWhere({ isPrimaryGroup: true });
        return this;
      })
      .catch(e => {
        this.userGroupsAvailable = false;
        logger.warn('Unable to get user groups', { error: e });
      });
  }

  /**
   * Get the primary group id for this user.
   * @returns Group id, undefined if the user groups is not available.
   */
  getPrimaryGroupId() {
    return this.primaryGroup && this.primaryGroup.get('id');
  }

  ready() {
    return this.promise;
  }
}
