/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

function isEmpty(str) {
  return !str || str.length === 0 || !str.trim();
}

// replace(/\u200E/g,'') was added to combat: DCES-4210388
function sanitizeDate(str) {
  return str ? str.replace(/\u200E/g, '') : str;
}

export { isEmpty, sanitizeDate }; // eslint-disable-line import/prefer-default-export
