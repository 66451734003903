/**
 * Analytics event type - used in 'event.type' property
 * @type {Object}
 */
export const ANALYTICS_TYPES = {
  NAV_LINK: 'NavLink',
  STATUS_TAB_LINK: 'StatusTabLink',
  END_DATE_TAB_LINK: 'EndDateTabLink',
  HEADER_BACK: 'HeaderBack',
  AGREEMENT_SEARCH_RESULT: 'AgreementSearchResult',
  AGREEMENT_SELECTED: 'AgreementSelected',
  // AGREEMENT_VIEW_NAV: 'AgreementViewNav', // double click is disabled
  ROW_DOUBLE_CLICK: 'RowDoubleClick',
  COLUMN_SORT: 'ColumnSort',
  AGREEMENT_ACTION: 'AgreementAction',
  REVIEW_END_DATE_ACTION: 'ReviewEndDateAction',
  END_DATE_CALENDAR_ACTION: 'EndDateCalendarAction',
  INLINE_ACTION: 'InlineAction',
  SEARCH_INPUT_SUBMIT: 'SearchInputSubmit',
  FILTER_ICON_CLICK: 'FilterIconClick',
  FILTER_OPEN: 'FilterOpen',
  FILTER_APPLY: 'FilterApply',
  FILTER_CANCEL: 'FilterCancel',
  FILTER_RESET: 'FilterReset',
  FILTER_TAG_CLICK: 'FilterTagClick',
  FILTER_TAG_CLEAR: 'FilterTagClear',
  QUERYABLE_FIELD_SELECTED: 'QueryableFieldSelected',
  DATE_FILTER_SELECTED: 'DateFilterSelected',
  DATE_PICKER_CHANGED: 'DatePickerChanged',
  GROUP_FILTER_SELECTED: 'GroupFilterSelected',
  CALL_TO_ACTION: 'CallToAction',
  CALL_TO_ACTION_SHOWN: 'CallToActionShown',
  CALL_TO_ACTION_HEADER: 'CallToActionHeader',
  EMPTY_STATE_SHOWN: 'EmptyStateShown',
  ERROR: 'Error',
  DRAWER_TOGGLE_BUTTON: 'DrawerToggleButton',
  ACCOUNT_SHARE_OPEN: 'AccountShareOpen',
  ACCOUNT_SHARE_LOAD: 'AccountShareLoad',
  ACCOUNT_SHARE_SELECT: 'AccountShareSelect',
  ACCOUNT_SHARE_FILTER: 'AccountShareFilter',
  FILTERPANEL_OPEN: 'FilterPanelOpen',
  FILTERPANEL_CLOSE: 'FilterPanelClose',
  FILTERPANEL_RESET: 'FilterPanelReset',
  FILTERPANEL_SEARCH: 'FilterPanelSearch',
  FILTERPANEL_DEEPLINK: 'FilterPanelDeeplink'
};

export const AGREEMENT_TYPES = {
  LIBRARY_TEMPLATE: 'LIBRARY_TEMPLATE'
};
