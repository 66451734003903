/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextWithInfoCell from './TextWithInfoCell';
import styled from 'styled-components';
import InlineActions from '../../Button/InlineActions';

export const RecipientContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  .hover-button {
    display: none;
  }
  .pinned-button + .hover-button {
    display: flex;
    visibility: hidden;
  }
  .title-info {
    flex-grow: 1;
  }
`;

export const InlineActionsContainer = styled.div`
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
`;

const RecipientTableCell = ({ recipient, company, total, completedCount, actions, intl }) => {
  const info = total > 1 ? intl.formatMessage({ id: 'x_of_total_completed' }, { x: completedCount, total }) : company;
  return (
    <RecipientContainer>
      <TextWithInfoCell className="recipient-table-cell" text={recipient || '\u2014'} info={info} />
      {!!actions.length && (
        <InlineActionsContainer>
          <InlineActions actions={actions} className="inline-actions" />
        </InlineActionsContainer>
      )}
    </RecipientContainer>
  );
};

RecipientTableCell.propTypes = {
  recipient: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  total: PropTypes.number,
  completedCount: PropTypes.number,
  actions: PropTypes.arrayOf(PropTypes.string),
  intl: PropTypes.shape.isRequired
};

RecipientTableCell.defaultProps = {
  actions: []
};

// export the component with the localizations injected
export default injectIntl(RecipientTableCell);
