/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import Button from '@react/react-spectrum/Button';

// To prevent clicks on action button from toggling selection
const HoverActionContainer = styled.div`
  & .spectrum-Button-label {
    pointer-events: none;
  }

  & .spectrum-Button--secondary {
    background-color: white;
  }

  & .spectrum-Button + .spectrum-Button {
    margin-left: 8px;
  }
`;

const shouldOpenInNewTab = e => {
  let openInNewTab = false;
  if (e.ctrlKey || e.metaKey) {
    openInNewTab = true;
  }
  return openInNewTab;
};

const ContextActionsCell = ({ primaryAction, primaryActionLabelId, onClick, intl }) => {
  return (
    <HoverActionContainer>
      <Button
        variant="secondary"
        label={intl.formatMessage({ id: 'action.view' })}
        onClick={e => onClick('view', shouldOpenInNewTab(e))}
      />
      <Button
        variant="cta"
        label={intl.formatMessage({ id: primaryActionLabelId })}
        onClick={() => {
          onClick(primaryAction);
        }}
      />
    </HoverActionContainer>
  );
};

ContextActionsCell.propTypes = {
  primaryAction: PropTypes.string.isRequired,
  primaryActionLabelId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  intl: PropTypes.shape.isRequired
};

// export the component with the localizations injected
export default injectIntl(ContextActionsCell);
