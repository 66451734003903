/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import Button from '@react/react-spectrum/Button';
import CalendarIcon from 'dc-icons/Sign-DesignAssets/manage/s_calendaradd_18_n.svg';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';

// To prevent table hover/selection highlight showing as action button background
const ButtonContainer = styled.div`
  & .spectrum-Button--primary {
    background-color: white;
  }
`;

const EndDateCalendarCell = ({ date, onClick, rowHasFocus, rowIndex, intl }) => {
  const { formatMessage } = intl,
    messages = formatMessage({ id: 'call_to_action.end_date.calendar.tooltip' });
  if (date === null) {
    return null;
  }
  return (
    <OverlayTrigger placement="top" trigger="hover">
      <ButtonContainer>
        <Button
          // className use in sign-dropin to show coachmark
          className="ending_soon_calendar"
          onClick={() => onClick(rowIndex)}
          icon={<CalendarIcon />}
          tabIndex={rowHasFocus ? 0 : -1}
          aria-label={messages}
          quiet
        />
      </ButtonContainer>
      <Tooltip>{messages}</Tooltip>
    </OverlayTrigger>
  );
};

EndDateCalendarCell.propTypes = {
  date: PropTypes.string.isRequired,
  intl: PropTypes.shape.isRequired,
  onClick: PropTypes.func.isRequired,
  rowHasFocus: PropTypes.bool.isRequired,
  rowIndex: PropTypes.number.isRequired
};

export default injectIntl(EndDateCalendarCell);
