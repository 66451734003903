/* eslint import/no-webpack-loader-syntax: off */
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { providers } from 'dc-core';
import { action, observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import styled, { createGlobalStyle } from 'styled-components';
import SpectrumProvider from '@react/react-spectrum/Provider';
import { warning } from '@react/react-spectrum/Toast';
import stores from './stores';
import log from './utils/logger';
import analytics from './utils/analytics';
import { postEvent } from './utils/Events';
import App from './view/signmanage/SignManageContainer';

let ExtraStyle = null;
const isJest = typeof jest !== 'undefined' && jest.fn && jest.mock;
if (!isJest) {
  const spectrumCSS =
    require('!raw-loader!@react/react-spectrum/spectrum-css/sidenav/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/sidenav/multiStops/light.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/table/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/table/multiStops/light.css') +
    require('!raw-loader!@react/react-spectrum/TableView/style/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/search/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/search/multiStops/light.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/tags/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/tags/multiStops/light.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/calendar/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/calendar/multiStops/light.css') +
    require('!raw-loader!@react/react-spectrum/Select/style/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/accordion/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/inputgroup/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/fieldlabel/index.css');
  ExtraStyle = createGlobalStyle`
    ${spectrumCSS}; /* globally inject table view css as dc-core doesn't provide this component */
  `;
}

const StyledSpectrumProvider = styled(SpectrumProvider)`
  background-color: var(--dc-global-color-background, white);
  display: flex;
  flex-direction: column;
  height: 100%;
`;

let serverPath;
let Env = stores.Env;

const DEFAULT_LOCALE = 'en-US';

// Loads translations for given locale.
function loadTranslation(loc) {
  log.info(`translation: ${loc}`);

  // modify __webpack_public_path__ only if serverPath is defined
  if (serverPath) {
    // translations are relative to /static/js, but serverPath
    // already has that - remove it to avoid duplication.
    serverPath = serverPath.replace('/static/js', '');

    // eslint-disable-next-line no-undef
    __webpack_public_path__ = serverPath;
  }
  return import(
    /* webpackChunkName: 'translations-[request]' */
    `./translations/${loc}.json`
  );
}

/**
 * Main App class -- a localized version of this class is returned to discovery
 * via the bootstrap load() method in index.js
 */
@observer
class LocalizedApp extends Component {
  @observable
  ready = false;

  @observable
  theme = null;

  messages = null;

  constructor(props) {
    super(props);
    if (props.locale) {
      Env.locale = props.locale;
    }
    this.locale = Env.locale;
    // this.locale = 'r0-r0';  // TEST

    // extract props before Floodgate call -- Last one wins!
    Object.assign(Env, App.defaultProps, Env.plugin, this.props);

    // call init to ensure stores are ready.
    this.init();
  }

  componentWillUnmount() {
    // If we subscribed to theme changes, unsubscribe now
    if (this.themeListener) {
      providers.theme().then(themeProvider => {
        themeProvider.unsubscribe(this.themeListener);
      });
    }
  }

  // ensure stores are ready
  init() {
    this.initTheme();
    stores
      .ready()
      .then(() => this.loadMessages())
      .then(() => {
        this.setReady();
        analytics.pageLoaded();
      });
  }

  initTheme() {
    if (Env.isDCWeb) {
      providers.theme().then(themeProvider => {
        themeProvider.getTheme().then(theme => {
          this.setTheme(theme);
          this.themeListener = this.setTheme.bind(this);
          themeProvider.subscribe(this.themeListener);
        });
      });
    } else {
      this.setTheme('light');
    }
  }

  @action
  setReady() {
    this.ready = true;
  }

  @action
  setTheme(theme) {
    this.theme = theme;
  }

  @computed
  get canRender() {
    return this.ready && this.messages && this.theme;
  }

  loadMessages() {
    return loadTranslation(this.locale)
      .then(messages => (this.messages = messages))
      .catch(e => {
        let msg = `Missing translation for ${this.locale}.`;
        let promise;
        log.warn(msg);
        if (this.locale !== DEFAULT_LOCALE) {
          this.locale = DEFAULT_LOCALE;
          promise = this.loadMessages();
          msg += ` Defaulting to ${DEFAULT_LOCALE}.`;
        }
        console.warn(e);
        warning(msg);
        return promise;
      });
  }

  getTypekitId() {
    const typekitIds = {
      'ja-jp': 'pcg0npo',
      'ko-kr': 'wmm2qek',
      'zh-cn': 'giv3kdk',
      'zh-tw': 'bvi5ncj'
    };
    return Env.isDCWeb ? typekitIds[this.locale.toLowerCase()] || 'bxf0ivf' : Env.plugin.typekitId;
  }

  getToastPlacement() {
    return Env.isDCWeb ? 'bottom' : undefined;
  }

  render() {
    log.info(`localized app: locale-data=${this.ready}, messages=${!!this.messages}`);
    if (!this.canRender) {
      return null;
    }

    // Fix for DCES-4252583
    postEvent({ type: 'PAGE_LOAD', data: { pageName: 'MANAGE' } });

    return (
      <IntlProvider locale={this.locale} messages={this.messages}>
        <StyledSpectrumProvider
          theme={this.theme}
          toastPlacement={this.getToastPlacement()}
          typekitId={this.getTypekitId()}
        >
          {ExtraStyle && <ExtraStyle />}
          <App {...this.props} showContextActions={!Env.isDCWeb} />
        </StyledSpectrumProvider>
      </IntlProvider>
    );
  }
}

export function getLocalizedApp(_serverPath) {
  serverPath = _serverPath;
  return LocalizedApp;
}
