import FilterHelper from './FilterHelper';

const ALWAYS_DISPLAY_COUNTER = ['state.waiting_for_others', 'state.waiting_for_you'];
const ACCOUNT_SHARE_ENABLED = [
  'state.waiting_for_you',
  'state.waiting_for_my_acceptance',
  'state.waiting_for_my_acknowledgement',
  'state.waiting_for_my_approval',
  'state.waiting_for_my_delegation',
  'state.waiting_for_my_form_filling',
  'state.waiting_for_my_signature',
  'status_tab.waiting_for_you'
];

class NavUtil {
  static labelKeyForStatus = (status, isSharedAccount) =>
    `${status}${isSharedAccount && ACCOUNT_SHARE_ENABLED.includes(status) ? '.shared' : ''}`;

  // Get Navigation Item
  static getNavItems(facets, hiddenNavItems, showFacetCount, onChange, isSharedAccount, onClick, isPowerAutomateOrgProvisioningCompleted) {
    const noPrefix = val => val.split('.')[1];
    const facetCount = type => facets[noPrefix(type)].count;
    const showCounter = name => showFacetCount || ALWAYS_DISPLAY_COUNTER.includes(name);
    const removeHidden = item => !hiddenNavItems.includes(item);
    const typeNavs = FilterHelper.filterGroupsWithPrefix('agreement_type');
    const stateNavs = FilterHelper.filterGroupsWithPrefix('state');

    return Object.keys(typeNavs)
      .filter(removeHidden)
      .map(typeNav => {
        const isAgreementGroup = typeNav === 'agreement_type.agreement';
        const children = Object.keys(stateNavs)
          .filter(removeHidden)
          .map(stateNav => {
            return {
              value: stateNav,
              labelKey: this.labelKeyForStatus(stateNav, isSharedAccount),
              count: showCounter(stateNav) ? facetCount(stateNav) : undefined,
              onSelect: () => onChange('agreement', noPrefix(stateNav), facetCount(stateNav), showCounter(stateNav)),
              onClick,
              isPowerAutomateOrgProvisioningCompleted
            };
          });

        return {
          value: typeNav,
          labelKey: typeNav,
          isHeader: isAgreementGroup,
          count: !isAgreementGroup && showCounter(typeNav) ? facetCount(typeNav) : undefined,
          children: isAgreementGroup ? children : [],
          onSelect: isAgreementGroup
            ? null
            : () => onChange(noPrefix(typeNav), undefined, facetCount(typeNav), showCounter(typeNav))
        };
      });
  }

  static getStateTabs(stateFacets, agreementState, isSharedAccount) {
    // Return default tab list entry when stateFacets is being computed to prevent TabList render issue
    if (Object.entries(stateFacets).length === 0) {
      return [{ value: 'all', labelKey: 'status_tab.all', count: 0, selected: true }];
    }

    const tabs = [];
    Object.keys(stateFacets).forEach(facet => {
      // Deleted items are not displayed
      if (facet === 'deleted') {
        return;
      }
      const count = stateFacets[facet].count;
      // Certain tabs are always visible
      if (count > 0 || ['all', 'waiting_for_others', 'completed'].includes(facet)) {
        tabs.push({
          value: facet,
          labelKey: this.labelKeyForStatus(`status_tab.${facet}`, isSharedAccount),
          selected: (facet === 'all' && !agreementState) || agreementState === facet,
          count
        });
      }
    });
    return tabs;
  }

  static getEndDateTabs(endDateFacets, endDateFilter, endDateEndedFacets = {}) {
    let facetsList = ['all', 'confirmed', 'unconfirmed', 'ended'];
    const endDateEndedCount = endDateEndedFacets.all ? endDateEndedFacets.all.count : 0;
    return facetsList.map(facet => ({
      value: facet,
      labelKey: `end_date_tab.${facet}`,
      selected: facet === endDateFilter,
      count: facet === 'ended' ? endDateEndedCount : endDateFacets[facet] ? endDateFacets[facet].count : 0
    }));
  }
}

export default NavUtil;
