/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/* eslint import/prefer-default-export:0 */
import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { locale2 } from 'dc-core';
import { IntlProvider, injectIntl } from 'react-intl';
import { getMessages } from './MessageUtils';

/**
 * Wrap the component provided with an IntlProvider
 * @param ComponentClass the component that will be wrapped with intl
 * @param dropinId the parent dropin for the component
 * @returns {Target|*}
 * @constructor
 */
function localizeComponent(ComponentClass) {
  if (!ComponentClass) {
    throw new Error('Missing target component');
  }

  const IntlInjectedClass = injectIntl(ComponentClass, { withRef: true });

  /**
   * Higher-order component generator which returns the target component with
   * 'locale' and 'messages' properties added. Assumes they're used by target's
   * IntlProvider. Actual loading of messages is done via passed function so
   * that it occurs in the target component's folder.
   *
   * This is a copy of what is in LocaleAPI.jsx (from dc-core) so that we can avoid the promises and the flicker
   *
   * @param {Function} WrappedComponent - Component with localized text
   * @param {Function} loadTranslations - Function which loads translations in
   *      context of wrapped component. Takes one parameter, locale, e.g. "fr-FR".
   *      Returns promise which resolves with map of translations.
   *
   * @return Target component with 'locale' and 'messages' properties.
   */
  @observer
  class ComponentWithIntl extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.loadMessages();
    }

    /** Map of localized strings */
    @action
    setMessages(value) {
      this.messages = value;
    }

    @observable messages;

    loadMessages() {
      const locale = locale2.getLocale();
      getMessages(locale).then(messages => {
        this.setMessages(messages);
      });
    }

    render() {
      if (!this.messages) {
        return null;
      }

      return (
        <IntlProvider locale={locale2.getLocale()} messages={this.messages}>
          <IntlInjectedClass {...this.props} />
        </IntlProvider>
      );
    }
  }

  return ComponentWithIntl;
}

export { localizeComponent };
