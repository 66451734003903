import logger from '../utils/logger';

/**
 * Shares for user: /users/me/shares
 */
export default class UserShares {
  constructor(Api) {
    this.Api = Api;
    this.shares = new Api.Users.Shares(null, { userId: 'me' });
    this.promise = null;
  }

  doFetch(cursor) {
    const data = {
      relationship: this.Api.Helpers.AccountShare.SHARE_RELATIONSHIP.SHAREE,
      status: this.Api.Helpers.AccountShare.SHARE_STATUS.ACTIVE
    };

    if (cursor) {
      data.cursor = cursor;
    }
    return this.shares.fetch({ data, remove: false });
  }

  async fetchUntil(condition) {
    while (this.shares.page.nextCursor && !condition()) {
      await this.doFetch(this.shares.page.nextCursor);
    }
  }

  fetchUntilShareAvailable(shareId) {
    return this.fetchUntil(() => this.shares.get(shareId));
  }

  fetchAnyRemainingShares() {
    return this.fetchUntil(() => false);
  }

  fetch() {
    this.promise = this.doFetch()
      .then(() => {
        this.sharesAvailable = true;
      })
      .catch(e => {
        this.sharesAvailable = false;
        logger.warn('Unable to get user shares', { error: e });
      });
  }

  /**
   * Fetch the ID of the sharer in account share with the given ID.
   * @param {string} shareId The ID of an account share
   * @returns The ID of the sharer, undefined if the account share is not found.
   */
  getSharerId(shareId) {
    const share = this.shares.get(shareId);
    return share ? share.get('sharer').id : undefined;
  }

  ready() {
    return this.promise;
  }
}
