/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import TruncatedText from 'dc-shared-ui-components/TruncatedText';
import { AlertIcon } from './TitleTableCell';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';
import Button from '@react/react-spectrum/Button';
import { injectIntl } from 'react-intl';
import BouncedIcon from '../../BouncedIcon/BouncedIcon.js';

const FIXED_HEIGHT = 18;

const OverflowContainer = styled.div`
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  height: 18px;
  font-size: 14px;

  &.primary {
    color: #4b4b4b;
  }

  &.info {
    color: #6e6e6e;
  }

  button {
    height: 18px;
  }
`;

const InfoText = styled(TruncatedText)`
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #cacaca;

  &:last-child {
    border-right: none;
  }
`;

const StyledIconEnclosingDiv = styled.div`
  height: 18px;
  width: 18px;
  margin-right: 4px;
`;

const CompositeTableCell = ({
  className = 'composite-cell',
  primary,
  secondary,
  tertiary,
  error,
  isBounced = false,
  intl
}) => {
  const hasContent = !!(primary || secondary || tertiary);
  const bouncedIconTooltipAndAriaLabelText = intl.formatMessage({
    id: 'notification_bounced_back_message'
  });
  const getInfoText = (data, showBouncedIcon) => {
    return (
      data &&
      data.length &&
      data.map(info => {
        return (
          <>
            {showBouncedIcon && (
              <StyledIconEnclosingDiv
                role="alert"
                data-automation-id="bounced-icon-md"
                title={bouncedIconTooltipAndAriaLabelText}
                aria-label={bouncedIconTooltipAndAriaLabelText}
              >
                <BouncedIcon />
              </StyledIconEnclosingDiv>
            )}
            <InfoText className="info-text" title="info text" text={info} placement="middle" height={FIXED_HEIGHT} />
          </>
        );
      })
    );
  };

  const secondaryInfo = getInfoText(secondary, isBounced);
  const tertiaryInfo = getInfoText(tertiary, false);

  return (
    <OverflowContainer className={className}>
      {hasContent ? (
        <React.Fragment>
          <Row className="primary">
            {primary ? (
              <TruncatedText
                className="primary-text"
                title="primary text"
                text={primary}
                placement="middle"
                height={FIXED_HEIGHT}
              />
            ) : (
              '\u2014'
            )}
            {error ? (
              <OverlayTrigger placement="top" trigger="hover">
                <Button variant="tool" icon={<AlertIcon size="S" />} aria-label={error} />
                <Tooltip variant="error">{error}</Tooltip>
              </OverlayTrigger>
            ) : null}
          </Row>
          {secondary && <Row className="info">{secondaryInfo}</Row>}
          {tertiary && <Row className="info">{tertiaryInfo}</Row>}
        </React.Fragment>
      ) : (
        <Row className="primary">{'\u2014'}</Row>
      )}
    </OverflowContainer>
  );
};

CompositeTableCell.propTypes = {
  className: PropTypes.string,
  primary: PropTypes.string,
  secondary: PropTypes.arrayOf(PropTypes.string),
  tertiary: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.string,
  isBounced: PropTypes.bool,
  intl: PropTypes.shape.isRequired
};

export default injectIntl(CompositeTableCell);
