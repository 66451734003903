import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { locale2 } from 'dc-core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getMessages } from '../../utils/MessageUtils';
import { analyticsFor } from '../../utils/analytics';
import DateFilter from '../DateFilter';
import QueryableField from '../QueryableField';
import GroupFilter from '../GroupFilter';
import { media } from '../../utils/mediaQuery';
import bindAndCatchAndThrow from '../../utils/bindAndCatchAndThrow';

import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import Link from '@react/react-spectrum/Link';
import Checkbox from '@react/react-spectrum/Checkbox';
import Dialog from '@react/react-spectrum/Dialog';

const StyledCheckbox = styled(Checkbox)`
  padding-top: 6px;
`;

const PopoverFooter = styled.div`
  text-align: right;
`;

const LeftLink = styled(Link)`
  float: left;
  line-height: 2em;
  width: 100%;
`;

const StyledDialog = styled(Dialog)`
  @media ${media.zoom400Percent} {
    &.spectrum-Dialog {
      padding: 15px;
      .spectrum-Dialog-header {
        padding-bottom: 19px;
      }
      .spectrum-Dialog-footer {
      padding-top: 5px;
      }
    }
`;

class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibility: props.visibility,
      queryableField: props.queryableField,
      group: props.selectedGroup ? props.selectedGroup.value : undefined,
      dateRangeType: props.dateRangeType,
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  componentWillUnmount() {
    this.props.closePopover();
  }

  @bindAndCatchAndThrow
  onCancel() {
    this.props.sendAnalytics(analyticsFor.FILTER_CANCEL);
    this.props.closePopover();
  }

  @bindAndCatchAndThrow
  onApply() {
    const { dateRangeType, startDate, endDate, group, visibility, queryableField } = this.state;
    this.props.sendAnalytics(analyticsFor.FILTER_APPLY);
    this.props.routingUtil.setDateRangeParams(dateRangeType, startDate, endDate);
    this.props.routingUtil.setGroupParam(group);
    this.props.routingUtil.setVisibilityParam(visibility);
    this.props.routingUtil.setQueryableFieldParam(queryableField);
    this.props.routingUtil.push();
    this.props.closePopover();
  }

  @bindAndCatchAndThrow
  onReset() {
    this.props.sendAnalytics(analyticsFor.FILTER_RESET);
    this.setState({
      dateRangeType: undefined,
      startDate: undefined,
      endDate: undefined,
      group: undefined,
      queryableField: undefined,
      visibility: 'SHOW_VISIBLE'
    });
  }

  render() {
    const { onClose } = this.props;
    const filterContent = (
      <React.Fragment>
        <div>
          {!this.props.renderAsModal && (
            <Heading variant="subtitle2">{this.props.intl.formatMessage({ id: 'filter.date' })}</Heading>
          )}
          <DateFilter
            onChange={(rangeType, startDate, endDate) => {
              this.setState({ dateRangeType: rangeType, startDate, endDate });
            }}
            sendAnalytics={this.props.sendAnalytics}
            rangeType={this.state.dateRangeType}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />
          {this.props.showFieldFilter && (
            <React.Fragment>
              <Heading variant="subtitle2">{this.props.intl.formatMessage({ id: 'filter.field.header' })}</Heading>
              <QueryableField
                onChange={queryableField => {
                  this.setState({ queryableField });
                }}
                sendAnalytics={this.props.sendAnalytics}
                value={this.state.queryableField}
              />
            </React.Fragment>
          )}
          {this.props.groups && (
            <React.Fragment>
              <Heading variant="subtitle2">{this.props.intl.formatMessage({ id: 'filter.group' })}</Heading>
              <GroupFilter
                onChange={group => {
                  this.setState({ group });
                }}
                groups={this.props.groups}
                selectedGroup={this.state.group}
                sendAnalytics={this.props.sendAnalytics}
              />
            </React.Fragment>
          )}
          <StyledCheckbox
            label={this.props.intl.formatMessage({ id: 'filter.visibility.checkbox' })}
            checked={this.state.visibility === 'SHOW_HIDDEN'}
            onChange={checked => {
              this.setState({ visibility: checked ? 'SHOW_HIDDEN' : 'SHOW_VISIBLE' });
            }}
          />
        </div>
        <LeftLink role="button" quiet onClick={() => this.onReset()}>
          {this.props.intl.formatMessage({ id: 'filter.reset' })}
        </LeftLink>
        {!this.props.renderAsModal ? (
          <PopoverFooter>
            <Button onClick={() => this.onCancel()}>{this.props.intl.formatMessage({ id: 'dialog.cancel' })}</Button>
            <Button variant="cta" onClick={() => this.onApply()}>
              {this.props.intl.formatMessage({ id: 'dialog.apply' })}
            </Button>
          </PopoverFooter>
        ) : null}
      </React.Fragment>
    );
    return !this.props.renderAsModal ? (
      <React.Fragment>{filterContent}</React.Fragment>
    ) : (
      <StyledDialog
        backdropClickable={true}
        confirmLabel={this.props.intl.formatMessage({ id: 'dialog.apply' })}
        cancelLabel={this.props.intl.formatMessage({ id: 'dialog.cancel' })}
        title={this.props.intl.formatMessage({ id: 'filter.date' })}
        onConfirm={() => this.onApply()}
        onCancel={() => this.onCancel()}
        onClose={onClose}
      >
        {filterContent}
      </StyledDialog>
    );
  }
}

Filters.defaultProps = {};

Filters.propTypes = {
  routingUtil: PropTypes.shape({
    setDateRange: PropTypes.func.isRequired,
    setGroup: PropTypes.func.isRequired,
    setQueryableField: PropTypes.func.isRequired,
    setVisibility: PropTypes.func.isRequired
  }).isRequired,
  closePopover: PropTypes.func.isRequired,
  sendAnalytics: PropTypes.func.isRequired,
  visibility: PropTypes.string,
  dateRangeType: PropTypes.string,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  selectedGroup: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.object),
  renderAsModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  intl: PropTypes.shape.isRequired
};

const LocalizableFilters = props => (
  <IntlProvider messages={props.messages} locale={props.locale} defaultLocale="en">
    <Filters {...props} />
  </IntlProvider>
);

export default locale2.withTranslations(LocalizableFilters, getMessages);
