/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { sanitizeDate } from '../../../utils/StringUtil';

const StyledDateTableCell = styled.div`
  width: 100%;
`;

const DateTableCell = ({ date, intl }) => {
  const dateTxt = date ? sanitizeDate(intl.formatDate(date)) : '\u2014';
  return <StyledDateTableCell className="date-table-cell">{dateTxt}</StyledDateTableCell>;
};

DateTableCell.propTypes = {
  date: PropTypes.string,
  intl: PropTypes.shape.isRequired
};

export default injectIntl(DateTableCell);
