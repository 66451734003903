/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@react/react-spectrum/Button';

import styled from 'styled-components';

const VisibleWhenFocusedButton = styled(Button)`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus {
    height: 32px;
    left: 0;
    position: static;
    width: auto;
    z-index: 1;
  }
`;

const ShowDetailsCell = ({ onClick, rowData, intl }) => {
  // If the row is not selected we render a "Show details" link.
  // If the row is selected, selection count is 1, we render a "Hide details" link
  // If the row is selected, selection count > 1, we render a "Show details" link
  const isShowAction = !rowData.isSelected || rowData.isMultiSelected;
  const action = isShowAction ? 'show_details' : 'hide_details';

  return (
    <VisibleWhenFocusedButton
      label={intl.formatMessage({ id: `${action}.link` })}
      onClick={() => {
        onClick(rowData.index);
      }}
      variant="action"
      tabIndex={rowData.isFocused ? 0 : -1}
    />
  );
};

ShowDetailsCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
    index: PropTypes.number.isRequired,
    isFocused: PropTypes.bool.isRequired,
    isMultiSelected: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired
  }),
  intl: PropTypes.shape.isRequired
};

// export the component with the localizations injected
export default injectIntl(ShowDetailsCell);
