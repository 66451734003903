/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import TextWithInfoCell from './TextWithInfoCell';

const SenderTableCell = ({ name, company, intl }) => {
  return <TextWithInfoCell className="sender-table-cell" text={name} info={company} />;
};

SenderTableCell.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string,
  intl: PropTypes.shape.isRequired
};

// export the component with the localizations injected
export default injectIntl(SenderTableCell);
