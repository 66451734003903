/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';
import Button from '@react/react-spectrum/Button';
import Remind from 'dc-icons/Sign-DesignAssets/manage/SDC_Reminder_18_N.svg';
import RemindBlue from 'dc-icons/Sign-DesignAssets/manage/SDC_Reminder_18_S.svg';
import Notes from 'dc-icons/Sign-DesignAssets/manage/SDC_Annotate_18_N.svg';
import NotesBlue from 'dc-icons/Sign-DesignAssets/manage/SDC_Annotate_18_S.svg';
import Edit from 'dc-icons/Sign-DesignAssets/manage/SDC_EditUsing_18_N.svg';
import Sign from 'dc-icons/Sign-DesignAssets/manage/SDC_AddSignature_18_N.svg';
import Download from 'dc-icons/Sign-DesignAssets/manage/SDC_DownloadPDF_18_N.svg';
import DownloadFormData from 'dc-icons/Sign-DesignAssets/manage/SDC_DownloadFromData_18_N.svg';

const ActionTooltip = styled(Tooltip)`
  .spectrum-Tooltip-label {
    max-width: 130px;
  }
`;

const getNoteIcon = active => {
  return active ? <NotesBlue /> : <Notes />;
};

const getRemindIcon = active => {
  return active ? <RemindBlue /> : <Remind />;
};

const getIcon = (action, active) => {
  let icon;
  switch (action.name) {
    case 'remind':
      icon = getRemindIcon(active);
      break;
    case 'notes':
      icon = getNoteIcon(active);
      break;
    case 'prefill':
    case 'sign':
      icon = <Sign />;
      break;
    case 'download_pdf':
      icon = <Download />;
      break;
    case 'export_form_data':
      icon = <DownloadFormData />;
      break;
    case 'edit':
      icon = <Edit />;
      break;
    default:
      icon = undefined;
  }
  return icon;
};

const shouldOpenInNewTab = e => {
  let openInNewTab = false;
  if (e.ctrlKey || e.metaKey) {
    openInNewTab = true;
  }
  return openInNewTab;
};

const ActionButton = ({ className, action, active, intl }) => {
  const label = intl.formatMessage({ id: action.id });
  const useInfo = !!action.data;
  const info = action.data;
  const icon = getIcon(action, active);
  const useIcon = !!icon;
  const useTooltip = useIcon;
  const classes = [className, `${action.name}-action`].filter(clz => clz).join(' ');
  const button = (
    <Button
      className={classes}
      icon={icon}
      aria-label={label}
      label={useTooltip ? undefined : label}
      quiet={useIcon}
      onClick={e => action.onClick(action.name, shouldOpenInNewTab(e))}
      variant="action"
    />
  );

  if (!useTooltip) {
    return button;
  }

  return (
    <OverlayTrigger placement="top" trigger="hover" delayShow={500}>
      {button}
      <ActionTooltip>{useInfo ? info : label}</ActionTooltip>
    </OverlayTrigger>
  );
};

ActionButton.propTypes = {
  className: PropTypes.string,
  action: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    data: PropTypes.string
  }).isRequired,
  active: PropTypes.bool, // active shows blue icon indicating button active state, otherwise show gray icon for hover button
  intl: PropTypes.shape.isRequired
};

ActionButton.defaultProps = {
  active: true
};

export default injectIntl(ActionButton);

export { getRemindIcon, getNoteIcon, getIcon };
