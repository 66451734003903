/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import stores from '../stores';

const getLocaleSuffix = territory => {
  switch (territory) {
    case 'us':
    case 'id':
    case 'hr':
    case 'is':
    case 'my':
    case 'vn':
    case 'th':
      return '';
    case 'gb':
      return '_uk';
    default:
      return `_${territory}`;
  }
};

const getLocalizedGoUrl = goUrl => {
  const territory = stores.Env.locale.toLowerCase().split('-')[1];
  const localeSuffix = getLocaleSuffix(territory);
  return `https://www.adobe.com/go/${goUrl}${localeSuffix}`;
};

export default getLocalizedGoUrl;
