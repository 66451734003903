/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

import EmptySearchIcon from 'dc-icons/organizer/emptystateillustrations/S_IlluNoSearchResults_180x160.svg';

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 84px;
  text-align: center;
`;

const Title = styled.p`
  font-size: 30px;
  font-weight: lighter;
  margin: 0;
`;

const Body = styled.p`
  margin: 0;
`;

const EmptyState = ({ intl }) => {
  return (
    <MainContainer className={'EmptySearch'}>
      <EmptySearchIcon />
      <Title>{intl.formatMessage({ id: 'empty_search_results.title' })}</Title>
      <Body>{intl.formatMessage({ id: 'empty_search_results.body' })}</Body>
    </MainContainer>
  );
};

export default injectIntl(EmptyState);
