/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import Button from '@react/react-spectrum/Button';
import HamburgerIcon from 'dc-icons/consumption/s_showmenu_18.svg';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const DrawerToggleButton = ({ onClick, intl }) => {
  return (
    <Button
      className="drawer-toggle-button"
      label={null}
      onClick={onClick}
      variant="tool"
      icon={<HamburgerIcon />}
      aria-label={intl.formatMessage({ id: 'sidenav.button.aria_label' })}
      aria-haspopup="true"
    />
  );
};

DrawerToggleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  intl: PropTypes.shape.isRequired
};

export default injectIntl(DrawerToggleButton);
