import React from 'react';
import { media, isMedia } from '../../utils/mediaQuery';
import ManageNav from './ManageNav';
import DrawerNav from './DrawerNav';
import styled from 'styled-components';

const ResourceNavContainer = styled.div`
  background-color: rgb(250, 250, 250);
  min-width: 220px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 14px;
`;

class ResponsiveNav extends React.Component {
  get resourceNav() {
    const { data, value, close } = this.props;
    return (
      <ResourceNavContainer>
        <ManageNav value={value} data={data} close={close} />
      </ResourceNavContainer>
    );
  }

  get drawerNav() {
    const { show, close } = this.props;
    if (!show) {
      return null;
    }
    return <DrawerNav close={close}>{this.resourceNav}</DrawerNav>;
  }

  render() {
    return isMedia(media.phone) ? this.drawerNav : this.resourceNav;
  }
}

export default ResponsiveNav;
