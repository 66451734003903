/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import ListDataSource from '@react/react-spectrum/ListDataSource';
import IndexPath from '@react/collection-view/src/IndexPath.js';
import Env from '../../stores/env';

const MAX_ALLOWED_SELECTION = 100;

export const getMaxSelectionCount = () => Env.plugin.maxMultiSelect || MAX_ALLOWED_SELECTION;

const section = 0;

export default class SignatureItemsDataSource extends ListDataSource {
  constructor({ pageLimit, skeletonData }) {
    super();

    this.isManualSort = false;
    this.pageLimit = pageLimit;
    this.skeletonData = skeletonData;

    // show & hide skeleton data.
    this.showSkeleton = !!skeletonData;
  }

  get section() {
    return this.sections[section] || [];
  }

  async load(sortDescriptor) {
    this.currentPage = 0;
    if (sortDescriptor) {
      this.sortBy = sortDescriptor.column.sortKey || sortDescriptor.column.key;
      this.sortDirection = sortDescriptor.direction === -1 ? 'asc' : 'desc';
    } else {
      this.sortBy = undefined;
      this.sortDirection = undefined;
    }
    // Trigger the search query for the current page. If the skeleton loader is to be
    // displayed we return the skeleton data. After the actual data has been fetched
    // the search utils will clear the showSkeleton flag and trigger a reload.
    const loadPromise = this.loadCurrentPage();
    return this.showSkeleton ? this.skeletonData : loadPromise;
  }

  async loadMore() {
    if (!this.showSkeleton) {
      this.currentPage++;
      return this.loadCurrentPage();
    }
  }

  // Override to trigger a reload of the data. This is required to reset the hasMore
  // state within the src/utils/CollectionView.js class. Without this the user will be
  // unable to see results beyond the first page of the sorted data.
  async performSort(/* sortDescriptor */) {
    // performSort can be called from the CollectionView when the table is rendered
    // with a different sort descriptor, e.g. when navigating between categories. We
    // don't want to trigger a reload here in that case because already do that in
    // the search utils. Only reload in response to a manual sort.
    if (this.isManualSort) {
      this.isManualSort = false;

      // performSort is called from a componentWillReceiveProps function. Defer
      // the call to reloadData to allow props to be set within the component.
      if (this.skeletonData) this.showSkeleton = true;
      setTimeout(() => this.reloadData(), 0);
    }
  }

  loadCurrentPage() {
    return this.loadFunction(this.currentPage, this.pageLimit, this.sortBy, this.sortDirection).then(
      ({ items }) => items
    );
  }

  setIsManualSort(isManualSort) {
    this.isManualSort = isManualSort;
  }

  setLoadFunction(loadFunction) {
    this.loadFunction = loadFunction;
  }

  setShowSkeleton(showSkeleton) {
    this.showSkeleton = showSkeleton;
  }

  setSkeletonData(skeletonData) {
    this.skeletonData = skeletonData;
  }

  /**
   * This overrides method in @react/collection-view/src/EditableCollectionView.js
   * which determines whether to accept a select action or not.
   *
   * Selection is allowed only if click directly on a checkbox or
   * previously had a selection.
   *
   * These two properties are set on DataSource in SignTable.js
   *
   * @param indexPath {object} This only has the section and current index (not very useful)
   * @return {boolean} true if selection should be allowed, false otherwise
   */
  shouldSelectItem(indexPath) {
    if (!this.collection.allowsMultipleSelection) {
      return true;
    }
    const atMax = this.collection.selectedIndexPaths.length >= getMaxSelectionCount();
    const isUnselect = atMax && this.collection._selection.contains(indexPath);

    // allow un-select if we're at max
    const allowSelect = !atMax || isUnselect;
    if (allowSelect) {
      // clear last error in row data
      this.clearRowError(indexPath.index);
    }
    return allowSelect;
  }

  findIndexForAgreement(agreementId) {
    return this.section.findIndex(item => item.agreement_id === agreementId);
  }

  clearRowError(rowIndex) {
    (this.getRowData(rowIndex) || {}).lastError = null;
  }

  getRowData(rowIndex) {
    return this.section[rowIndex];
  }

  deselect(rowIndex, emit = false) {
    this.collection.selectItem(
      new IndexPath(section, rowIndex),
      /* toggle */ true,
      /* extend */ false,
      /* emit */ emit
    );
  }

  select(rowIndex) {
    this.collection.selectItem(
      new IndexPath(section, rowIndex),
      /* toggle */ true,
      /* extend */ false,
      /* emit */ true
    );
  }

  clearDataErrors(ranges) {
    ranges.forEach(this.clearRowError.bind(this));
  }

  removeRow(rowIndex, isBulk) {
    // DCES-4286748: animation causing flashing for IE11 and Safari for bulk updates
    const animated =
      !isBulk || !/Trident|Safari/.test(window.navigator.userAgent) || /Edg\//.test(window.navigator.userAgent); // not a typo -- "Edg" is Chromium-based Edge
    this.removeItem(new IndexPath(section, rowIndex), animated);
  }

  // This should be called BEFORE bulk actions start to pre-load the
  // data source if we anticipate many rows will be removed.
  softRefresh() {
    // if significant rows will be removed during bulk operation, reload the data
    if (this.collection.selectedIndexPaths.length > this.pageLimit / 3) {
      this.collection.emit('scroll'); // trigger update
    }
  }

  // Update the row data and re-render the row
  updateRowData(rowIndex, data = {}) {
    let rowData = this.getRowData(rowIndex);
    if (!rowData) {
      return;
    }
    Object.assign(rowData, data);

    const rowView = this.collection.getItemView(section, rowIndex);
    if (!rowView) {
      return; // if row is not in view
    }

    rowView.render();
    rowView.flushUpdates();
  }
}
