import logger from '../utils/logger';

/**
 * Settings for user: /user/me/settings
 *
 */
export default class UserSettings {
  constructor(Api) {
    // setting default values
    this.settings = new Api.Users.Settings({
      id: 'me'
    });
    this.promise = null;
  }

  fetch() {
    this.promise = this.settings
      .fetch()
      .then(() => {
        this.settingsAvailable = true;
        return this; // resolved value
      })
      .catch(e => {
        // the failure is caught and not thrown again, so the context board may render
        // even in this scenario.
        this.settingsAvailable = false;
        logger.warn('Unable to get User settings', { error: e });
      });
  }

  ready() {
    return this.promise;
  }

  doesUserHaveSettingsToAccessDeletedFolder() {
    return this.settings.get('isPrivacyAdmin') && this.settings.get('purgeQueueEnabled');
  }
}
