import { locale2 as dcCoreLocale } from 'dc-core';
import DeviceUtil from '../utils/DeviceUtil';
import { media, isMedia } from '../utils/mediaQuery';

// get env constants from hosting app
let Env = (window.App && window.App.Env) || {};

// get signmanage-specific Env
let plugin = 'plugins' in Env && 'signmanage' in Env.plugins ? Env.plugins.signmanage : null;
if (!plugin && 'plugins' in Env) {
  Env.plugins.signmanage = {};
}
if (plugin) {
  // expose our version to hosting app
  plugin.version = process.env.REACT_APP_VERSION;
}

// disable routing in sign context board
const signContextPlugin = 'plugins' in Env && 'signcontext' in Env.plugins ? Env.plugins.signcontext : null;
if (signContextPlugin) {
  delete signContextPlugin.routerType;
}

/* TODO - switch back to provider implementation once it has been moved into dc-core
const isMobile = () => {
  return providers
    .discovery()
    .then(() => providers.device())
    .then(deviceAPI => deviceAPI.isMobileDevice() && deviceAPI.isPhoneSize())
    .catch(e => {
      logger.warn('Unable to get user device info', { error: e });
    });
}; */

const isMobile = () => Promise.resolve(DeviceUtil.isMobileDevice() && isMedia(media.phone));

export { isMobile };

export default Object.assign({}, Env, {
  // other globals or overwrite App.Env

  // See: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
  queryParams: new URL(window.location).searchParams,

  devMode: process.env.NODE_ENV === 'development',

  locale: Env.locale || dcCoreLocale.getLocale(),

  // handles case for both DC (window.adobeIMS) or sign (Env.user)
  loggedIn: !!window.adobeIMS || (Env.user && Env.user.loggedIn),

  plugin: plugin || {},

  resetAccountShareCache: () => {
    if (window.App && window.App.Env) {
      //DCES-4298099 - Invalidate /me api cache
      window.App.Env.useCachedAccSharers = false;
    }
  }
});
