import { Analytics } from 'as-ducati-utils';
import { ANALYTICS_TYPES } from './constants';
import Env from '../stores/env';

/**
 *       ***            ***
 *       ***    NOTE    ***
 *       ***            ***
 *
 *  Only anonymous analytics should be
 *  collected due to HIPAA compliance.
 */

const getWorkflow = () => (Env.isDCWeb ? Analytics.WORKFLOW.SIGN_IN_DCWEB : Analytics.WORKFLOW.LIST_VIEW);

const signAnalytics = new Analytics({
  sourceName: 'sign-manage',
  subcategory: 'SignManage',
  workflow: getWorkflow,
  loggedIn: true
});

/**
 * Attach the ANALYTICS_TYPES to exported function
 *   --> fn.MEMBERS
 * @param fn {function}
 * @return fn
 */
const addAnalyticTypes = fn => {
  return Object.assign(fn, ANALYTICS_TYPES);
};

/**
 * default analytics instance
 *
 * Use send() method.
 *
 * To use helpers, set the type first: analytics.type = 'Foo'; analytics.clicked();
 * Or use analyticsFor() to get a type-bound instance.
 */
export default signAnalytics;

// export main class - this should rarely be used directly
export { Analytics };

export const analyticsFor = signAnalytics.analyticsFor;

// Attach ANALYTICS_TYPES for easy access
addAnalyticTypes(signAnalytics);
addAnalyticTypes(analyticsFor);
