/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import ButtonGroup from '@react/react-spectrum/ButtonGroup';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ActionButton from './ActionButton';

// Preventing TableCell enforce focus issue
const InlineActionsGroup = styled(ButtonGroup)`
  & {
    .spectrum-ActionButton,
    .spectrum-ActionButton:hover {
      .spectrum-ActionButton-label,
      svg {
        pointer-events: none;
      }
    }

    .hover-button {
      background-color: #fafafa;
      border-color: #e1e1e1;
      color: #4b4b4b;
    }
  }
`;

const InlineActions = ({ className, actions }) => {
  return (
    <InlineActionsGroup readOnly className={className}>
      {actions.map(action => (
        <ActionButton
          action={action}
          active={!!action.data}
          className={!!action.data ? 'pinned-button' : 'hover-button'}
        />
      ))}
    </InlineActionsGroup>
  );
};

InlineActions.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default InlineActions;
