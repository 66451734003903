/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TruncatedText from 'dc-shared-ui-components/TruncatedText';

const OverflowContainer = styled.div`
  overflow: hidden;
`;

// fixing dc-shared-ui-component bugs of hard-coding height attribute and ignoring component height property
// https://git.corp.adobe.com/dc/dc-shared-ui-components/blob/master/src/TruncatedText/style/TruncatedText.css#L48
const FIXED_HEIGHT = 20;
const TextContainer = styled.div`
  & div:last-child {
    height: ${props => props.height + 'px'};
  }

  display: flex;
  & button {
    height: ${FIXED_HEIGHT}px;
  }
`;

const StyledTruncatedInfoText = styled(TruncatedText)`
  color: var(--spectrum-global-color-gray-700, #6e6e6e);
  font-size: 11px;
`;

const InfoTextContainer = styled(TextContainer)`
  display: flex;
  align-items: center;
`;

const TextWithInfoCell = ({ className, text, info, placement = 'end', children }) => {
  return (
    <OverflowContainer className={className}>
      <TextContainer height={FIXED_HEIGHT}>
        <TruncatedText className="main-text" title={text} text={text} placement={placement} height={FIXED_HEIGHT} />
        {children}
      </TextContainer>
      {info && (
        <InfoTextContainer height={FIXED_HEIGHT}>
          <StyledTruncatedInfoText className="info-text" text={info} placement={placement} height={FIXED_HEIGHT} />
        </InfoTextContainer>
      )}
    </OverflowContainer>
  );
};

TextWithInfoCell.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  info: PropTypes.string,
  placement: PropTypes.string
};

export default TextWithInfoCell;
