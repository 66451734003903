/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2018 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/* eslint react/forbid-prop-types:0 */
import React from 'react';
import { withRouter } from 'react-router';
import { discovery } from 'dc-core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Search from '@react/react-spectrum/Search';
import Wait from '@react/react-spectrum/Wait';
import Button from '@react/react-spectrum/Button';
import InfoOutline from '@react/react-spectrum/Icon/InfoOutline';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';

import { WithToastMessage } from 'as-ducati-core';
import log from '../../utils/logger';

import SignatureItemsDataSource, { getMaxSelectionCount } from '../../model/datasource/SignatureItemsDataSource';
import stores from '../../stores';
import { injectIntl } from 'react-intl';
import { loadDropinClass } from '../../utils/DropinUtil';
import NavUtil from '../../utils/NavUtil';
import ColumnUtil from '../../utils/ColumnUtil';
import CallToActionUtil from '../../utils/CallToActionUtil';
import TableHeader from '../../component/Table/TableHeader';
import SignTable, { getSelectionRanges } from '../../component/Table/SignTable';
import EmptySearchResults from '../../component/EmptyState/EmptySearchResults';
import ErrorState from '../../component/ErrorState';
import CallToAction from '../../component/EmptyState/CallToAction';
import FilterGroup from '../../component/FilterGroup';
import AccountStatus from '../../component/AccountStatus';
import DrawerToggleButton from '../../component/Button/DrawerToggleButton';
import ResponsiveNav from '../../component/SideNav/ResponsiveNav';
import RoutingUtil from '../../utils/RoutingUtil';
import withWindowDimensions from '../../utils/withWindowDimensions';
import { media, isMedia } from '../../utils/mediaQuery';
import SearchUtil from '../../utils/SearchUtil';
import { analyticsFor } from '../../utils/analytics';
import { autorun, isObservableObject, runInAction } from 'mobx';
import StatusTabList from '../../component/Table/StatusTabList';
import FilterHelper from '../../utils/FilterHelper';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import bindAndCatchAndThrow from '../../utils/bindAndCatchAndThrow';
import withErrorBoundary from '../../utils/withErrorBoundary';
import { AGREEMENT_TYPES } from '../../utils/constants';
import ReviewEndDate from '../../dropins/EndDate/ReviewEndDate';
import EndDateCalendarIntegration from '../../dropins/EndDate/EndDateCalendarIntegration';
import getLocalizedGoUrl from '../../utils/GoURLUtil';
import SearchError from '../../component/EmptyState/SearchError';
import DeletedFolderBanner from '../../component/Banner/DeletedFolderBanner';
import FilterIcon from 'dc-icons/organizer/s_filter_18.svg';
import FilterPanel from '../../component/FilterPanel';
import { isJpTagErrlEnabled } from '../../utils/paymentFilterUtil';

const MainView = styled.section`
  display: flex;
  flex: 1;
  height: 100%;

  &,
  nav {
    min-height: 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;

  @media ${media.phone} {
    padding-right: 16px;
  }
`;

const MainContainer = styled(FlexContainer)`
  flex-direction: column;
  margin-right: 15px;

  @media ${media.phone} {
    margin-right: 0;
  }
`;

const ResultsContainer = styled.div`
  display: flex;
  flex: 1;
`;

const WrapperContainer = styled(FlexContainer)`
  flex-direction: column;

  @media ${media.phone} {
    margin-left: 0;
  }
`;

const TableContainer = styled(FlexContainer)`
  flex-direction: column;
  margin-left: 15px;

  @media ${media.phone} {
    margin-left: 0;
  }
`;

const WaitContainer = styled(FlexContainer)`
  align-items: center;
  justify-content: center;
`;

const ModalWaitContainer = styled(FlexContainer)`
  position: fixed;
  height: 64px;
  width: 64px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  outline: none;
`;

const StyledStatusTabList = styled(StatusTabList)`
  &.status-tabs {
    border: none;
    margin: 12px 8px;
  }
`;

const StyledSignTable = styled(SignTable)`
  flex: 1;
  min-height: 300px;
`;

const SignContextBoardContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  ${props => (!props.isHidden ? 'min-width: 300px' : undefined)};
  max-width: 300px;

  & .spectrum-Toast {
    display: ${props => (props.isHidden ? 'none' : 'inline-flex')} !important;
  }

  & .spectrum-CircleLoader {
    display: ${props => (props.isHidden ? 'none' : 'block')} !important;
  }

  & .context_board_single {
    display: ${props => (props.isHidden ? 'none' : 'block')} !important;
  }

  @media ${media.phone} {
    background-color: ${props => (props.isDCWeb ? '#fff' : '#f5f5f5')};
    bottom: 0;
    box-shadow: -3px 0 6px rgba(0, 0, 0, 0.16);
    max-width: 240px;
    min-width: 240px;
    position: ${props => (props.isDCWeb ? 'fixed' : 'absolute')};
    right: 0;
    top: ${props => (props.isDCWeb ? '63px' : '0')};
  }
`;

const TopBar = styled.div`
  align-items: center;
  border-bottom: 1px solid #eee;
  display: flex;
  min-height: 64px;
  padding: 8px 0 8px 26px;

  @media ${media.phone} {
    align-items: flex-start;
    flex-direction: column;
    min-height: initial;
    padding: 8px;
  }

  & .drawer-toggle-button {
    margin-right: 10px;
  }
`;

const HeaderFiltersContainer = styled.div`
  display: flex;
  margin-left: auto;
  padding: 18px 0 8px 26px;
`;

const CallToActionContainer = styled.div`
  display: flex;
  height: 32px;
  white-space: nowrap;
  padding: 18px 0 8px 16px;

  ${({ filterButtonInTableHeader }) => !filterButtonInTableHeader && 'margin-left: auto;'}
`;

const AccountStatusContainer = styled.div`
  align-items: center;
  display: flex;
`;

const SearchContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  @media ${media.phone} {
    width: 100%;
  }
`;

const SearchFilterContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
`;

// Below we specify a flex-basis of 100% to resolve IE 11 display issue
const SearchInput = styled(Search)`
  flex: 1 100%;
  max-width: 400px;

  & input[type='search'] {
    width: 100%;
    padding-left: 35px;

    &::placeholder {
      color: #6e6e6e;
    }
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  padding-right: 10px;
`;

// Limit on number of items returned in Agreement Search API query.
const SEARCH_PAGE_LIMIT = 100;

const accountShareToShareItem = accountShare => {
  const sharer = accountShare.get('sharer');
  return {
    label: sharer.name || sharer.email,
    type: sharer.type,
    value: accountShare.get('id')
  };
};

@injectIntl
class SignManageContainerInternal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.setup();
  }

  componentWillMount() {
    if (stores.Groups && stores.UserGroups) {
      Promise.all([stores.Groups.ready(), stores.UserGroups.ready()]).then(() => {
        this.setState({ groupsAvailable: true });
      });
    }

    if (stores.UserEsignAccess) {
      stores.UserEsignAccess.ready().then(() => {
        const userHasNoSignAccess = stores.UserEsignAccess.doesUserHaveNoSignAccessOrReadOnlySignAccess();
        this.setState({ userHasNoSignAccess: userHasNoSignAccess });
      });
    }

    if (stores.UserShares) {
      stores.UserShares.ready().then(() => {
        if (stores.UserShares.shares.length > 0) {
          this.setState({ accountSharingActive: true });

          if (this.activeShareId) {
            stores.UserShares.fetchUntilShareAvailable(this.activeShareId)
              .catch(error => {
                log.warn('Unable to get share', { error });
              })
              .then(() => {
                this.searchUtil.search();
              });
          }
        } else if (this.activeShareId) {
          // shouldn't happen, but we need to take action if it does
          log.warn('Hash fragment contains account share, none available');
          this.routingUtil.clearShared();
        }
      });

      // if the hash params reference a specific account share, we need to
      // delay our initial search query until the share has been fetched
      if (this.activeShareId) return;
    }

    if (stores.UserSettings) {
      stores.UserSettings.ready().then(() => {
        this.setState({
          canUserSeeDeletedFolder: stores.UserSettings.doesUserHaveSettingsToAccessDeletedFolder()
        });
        this.searchUtil.search();
      });
    } else {
      this.searchUtil.search();
    }

    if (stores.UserSettingsSearch) {
      stores.UserSettingsSearch.ready().then(() => {
        this.setState({
          unresolvedBounceAvailable: stores.UserSettingsSearch.isUnresolvedBounceIndicatorFeatureAvailable()
        });
      });
    }
  }

  checkPowerAutomateOrgProvisioningStatus = () => {
    window.App.Env.plugins.advancedWorkflowsConnect
      .ready()
      .then(() => {
        window.AdvancedWorkflowsConnect.PowerAutomate.utilityMethods
          .isOrgProvisioned()
          .then(() => {
            this.setState({
              isPowerAutomateOrgProvisioningCompleted: true
            });
          })
          .catch(error => {
            log.server(analyticsFor.ERROR, `Error while fetching isOrgProvisioned: ${error}`);
          });
      })
      .catch(error => {
        log.server(
          analyticsFor.ERROR,
          `Error in resolving promise for advancedWorkflowsConnect ready methods ${error}`
        );
      });
  };

  componentDidMount() {
    // Find primary scroll container for Manage page
    this.scrollEl = document.querySelector('main[name="manage-layout"] article');
    if (this.scrollEl) {
      this.scrollEl.addEventListener('scroll', this.onScroll, false);
    }

    if (window.App.Env.plugins.advancedWorkflowsConnect?.pluginUrl) {
      window.App.Utils.loadjs(window.App.Env.plugins.advancedWorkflowsConnect.pluginUrl, { returnPromise: true })
        .then(() => {
          this.checkPowerAutomateOrgProvisioningStatus();
        })
        .catch(error => {
          log.server(analyticsFor.ERROR, `Error while loading the connect repo: ${error}`);
        });
    }
  }

  componentWillUnmount() {
    if (this.scrollEl) {
      this.scrollEl.removeEventListener('scroll', this.onScroll, false);
    }
    if (this.scbDisposer) this.scbDisposer();
    this.unlistenHistory();
  }

  onScroll(e) {
    // Mute scroll events to allow popups to remain open
    e.stopImmediatePropagation();
  }

  setup() {
    this.routingUtil = new RoutingUtil({
      location: this.props.location,
      history: this.props.history,
      // Temporary abuse of isDCWeb prop until we have persistent All category in Sign
      allowPersistentAllCategory: this.props.isDCWeb
    });

    isJpTagErrlEnabled().then(isEnabled => {
      /**
       * DCSIA-12220 - Create a deep link into the filter panel for Sign Manage page to allow desktop clients to access
       * the filters directly. This will be enabled for Japan users only for ERRL Compliance. This should only impact the
       * behavior on initial load, after that the filter panel will be controlled by the user.
       */
      const filterPanelOpen = (isEnabled && !!this.routingUtil.getFilterPanelDeepLink()) || undefined;
      if (filterPanelOpen) {
        this.sendAnalytics(analyticsFor.FILTERPANEL_DEEPLINK, {
          filter_panel: this.routingUtil.getFilterPanelDeepLink()
        });
      }
      this.setState({ jpTagErrlEnabled: isEnabled, filterPanelOpen });
    });

    // eslint-disable-next-line react/no-direct-mutation-state
    this.state = Object.assign(
      { facetQueryPending: true, selectedPACategory: null, isPowerAutomateOrgProvisioningCompleted: false },
      this.routingUtil.getStateFromSearchParams()
    );
    this.unlistenHistory = this.props.history.listen((location, action) => {
      this.routingUtil.setLocation(location);
      const tableViewMounted = !!this.dataSource.collection;
      this.setState(
        prevState => Object.assign({ facetQueryPending: true }, this.routingUtil.getStateFromSearchParams(prevState)),
        () => {
          // update skeleton data
          if (this.props.useSkeleton) {
            this.updateSkeletonData();
            this.dataSource.setShowSkeleton(true);
          }
          this.searchUtil.search(tableViewMounted); // todo: maybe only do the search when the values are new, not just the hash changing
        }
      );
    });
    this.dataSource = new SignatureItemsDataSource({
      pageLimit: SEARCH_PAGE_LIMIT,
      skeletonData: this.props.useSkeleton ? this.getSkeletonData() : undefined
    });

    this.searchUtil = new SearchUtil(this); // todo: this should require passing in the whole component

    loadDropinClass('sign-context')
      .then(dropin => {
        this.setState({ signContextBoardDropin: dropin });
      })
      .catch(error => {
        log.server(analyticsFor.ERROR, `Error loading sign-context dropin: ${error.message || error}`);
        this.setState({ error: true });
      });
  }

  updateSkeletonData() {
    const skeletonData = this.getSkeletonData();
    this.dataSource.setSkeletonData(skeletonData);
  }

  // create skeleton data using facetCount
  getSkeletonData() {
    // Default to 20 items if facet counts aren't available
    let count = 20;
    if (this.state.agreementEndDate) {
      if (this.state.agreementEndDate === 'ended') {
        if (this.state.queryEndedFacets && this.state.queryEndedFacets.all) {
          count = this.state.queryEndedFacets.all.count;
        }
      } else if (this.state.queryFacets && this.state.queryFacets[this.state.agreementEndDate]) {
        count = this.state.queryFacets[this.state.agreementEndDate].count;
      }
    } else {
      if (this.props.facetCounts && this.props.facetCounts[this.agreementTypeOrState]) {
        count = this.props.facetCounts[this.agreementTypeOrState].count;
      }
    }

    const items = [];
    for (let i = 0; i < count; i++) {
      items.push({ useSkeleton: true });
    }
    return items;
  }

  sendAnalytics = (event, customContext) => {
    try {
      const analytics = analyticsFor(event);

      const { timing } = window.performance;

      const context = {
        filterAgreementType: this.state.agreementType,
        filterAgreementState: this.state.agreementState,
        filterParentId: this.state.parentId,
        filterDateRangeType: this.state.dateRangeType,
        filterVisibility: this.state.visibility,
        hasSearchInputValue: !!this.state.searchInputValue,
        hasSearchTerm: this.state.searchTerm && this.state.searchTerm.length > 0 && this.state.searchTerm !== '*',
        hasAccountSharing: !!this.state.accountSharingActive,
        viewingSharedAgreements: this.viewingSharedAgreements,
        pageLoadDuration: timing.loadEventEnd - timing.navigationStart,
        windowDimensionsWidth: this.props.windowDimensions.width,
        windowDimensionsHeight: this.props.windowDimensions.height,
        hasModifiedRoute: this.routingUtil.hasModifiedRoute,
        timeSinceLoad: Date.now() - timing.navigationStart
      };

      if (context.hasSearchTerm) {
        Object.assign(context, {
          searchTermLength: this.state.searchTerm.length,
          searchTermWordCount: this.state.searchTerm.split(' ').length,
          searchTermHasAtSign: this.state.searchTerm.indexOf('@') >= 0,
          searchTermHasQuotes: this.state.searchTerm.indexOf('"') >= 0,
          searchRequestId: this.searchUtil.searchRequestTrackingId,
          searchWithin: this.state.queryableField
        });
      }

      if (stores.User.userInfoAvailable) {
        context.accountId = stores.User.accountId;
      }

      // console.log("analytics context", event, Object.assign({}, context, customContext));

      analytics.setContext(Object.assign({}, context, customContext));
      analytics.send();
    } catch (exception) {
      console.error('logging exception', exception);
    }
  };

  getHiddenNavItems = () => {
    let hiddenNav = this.hiddenNavCache;
    const withAllCategory = items => (!this.state.useSearchOrFilter ? ['agreement_type.all'].concat(items) : items);
    const NAV_ITEM_SETTINGS = {
      'agreement_type.template': 'documentLibraryCreationVisible',
      'agreement_type.webform': 'widgetCreationVisible',
      'agreement_type.megasign': 'canUseMulticastWorkflows'
    };

    // Compute the hide list when no hidden nav cached
    if (!hiddenNav) {
      // Hidden Nav conditionally based on settings
      const settings = stores.UserSettings.settings;
      hiddenNav = Object.keys(NAV_ITEM_SETTINGS).reduce(
        (memo, key) => {
          if (!settings.get(NAV_ITEM_SETTINGS[key])) memo.push(key);
          return memo;
        },
        ['state.archive']
      );

      // Hidden Nav conditionally based on counter
      const facets = this.allFacets;
      const noPrefix = val => val.split('.')[1];
      hiddenNav = hiddenNav.reduce(
        (memo, key) => {
          if (facets[noPrefix(key)].count === 0) memo.push(key);
          return memo;
        },
        ['state.all']
      );

      // Hide "Legacy Drafts" in Sign
      if (this.props.showDraftAgreements) {
        hiddenNav.push('state.legacy_draft');
      }

      // cache when search or filter is no longer used and latest facet data is available
      if (!this.state.useSearchOrFilter && !this.state.facetQueryPending) {
        this.hiddenNavCache = hiddenNav;
      }
    }

    if (this.state.canUserSeeDeletedFolder) {
      hiddenNav.filter(item => item === 'state.deleted');
    } else {
      if (hiddenNav.indexOf('state.deleted')) hiddenNav.push('state.deleted');
    }
    return withAllCategory(hiddenNav);
  };

  get showNavActionInTableTitle() {
    return this.state.useSearchOrFilter && !!this.state.searchTerm;
  }

  get searchContext() {
    let searchContextId;
    if (this.state.agreementEndDate) {
      searchContextId = 'end_date';
    } else {
      const label = this.props.isDCWeb && this.showingDraft ? 'state.draft.dc_web' : this.agreementTypeOrStateById;
      searchContextId = NavUtil.labelKeyForStatus(label, this.isAccountSharingContext);
    }
    return this.props.intl.formatMessage({
      id: searchContextId
    });
  }

  get showingAll() {
    return this.state.agreementType === 'all';
  }
  get showingDraft() {
    return this.state.agreementState === 'draft';
  }
  // Bulk actions work on one agreement type at a time - disable All case.
  get allowMultiSelect() {
    // Multiselect is not supported yet for deleted items
    if (this.state.agreementState === 'deleted') {
      return false;
    }
    return (
      this.props.allowMultiSelect &&
      !this.showingAll &&
      // TODO temp disable bulk for template
      //  since show/hide is not available.  Re-enable
      //  once we have other bulk actions.
      this.state.agreementType !== 'template'
    );
  }

  get allFacets() {
    return this.props.facetCounts ? this.props.facetCounts : this.state.allFacets;
  }

  get renderable() {
    return !!(this.dataSource.loadFunction && this.allFacets);
  }

  get emptySearchResult() {
    if (this.state.parentId) {
      const queryFacets = this.state.queryFacets;
      if (queryFacets) {
        return this.state.agreementState
          ? queryFacets[this.state.agreementState].count === 0
          : Object.values(queryFacets).findIndex(facet => facet.count > 0) === -1;
      }
    } else if (this.state.agreementEndDate) {
      if (this.state.agreementEndDate === 'ended') {
        const queryEndedFacets = this.state.queryEndedFacets;
        return queryEndedFacets && queryEndedFacets.all && queryEndedFacets.all.count === 0;
      }
      const queryFacets = this.state.queryFacets;
      return queryFacets && queryFacets[this.state.agreementEndDate].count === 0;
    } else {
      return this.allFacets[this.agreementTypeOrState] && this.allFacets[this.agreementTypeOrState].count === 0;
    }
  }

  agreementStateOrDefault() {
    return this.state.agreementState || this.state.agreementType !== 'agreement'
      ? this.state.agreementState
      : 'waiting_for_others';
  }

  get agreementTypeOrState() {
    return this.agreementStateOrDefault() || this.state.agreementType;
  }

  get agreementTypeOrStateById() {
    return this.state.agreementType === 'agreement'
      ? `state.${this.agreementStateOrDefault()}`
      : `agreement_type.${this.state.agreementType}`;
  }

  get useCallToAction() {
    return !(this.isAccountSharingContext || this.state.useSearchOrFilter);
  }

  get useMinimumSignTableColumns() {
    const manageNavWidth = 240;
    const contextBoardWidth = this.firstSelectedAgreement && !this.state.openAction ? 300 : 0;
    const signTableWidth = this.props.windowDimensions.width - manageNavWidth - contextBoardWidth;
    return signTableWidth < 570;
  }

  get showConsolidatedView() {
    const { useSearchOrFilter, agreementType } = this.state;
    return FilterHelper.shouldUseConsolidatedGroup(useSearchOrFilter, agreementType);
  }

  get isUMGEnabled() {
    return stores.UserSettings.settings.get('usersInMultipleGroupsEnabled');
  }

  get canUserSendForSignature() {
    return stores.UserSettings.settings.get('userCanSend');
  }

  get canUserSendInBulk() {
    return (
      stores.UserSettings.settings.get('userCanSend') && stores.UserSettings.settings.get('canUseMulticastWorkflows')
    );
  }

  get canUserCreateTemplate() {
    return stores.UserSettings.settings.get('documentLibraryCreationVisible');
  }

  get canUserCreateWebForm() {
    return stores.UserSettings.settings.get('widgetCreationVisible');
  }

  get showGroup() {
    if (this.state.groupsAvailable) {
      let shouldShowGroup = this.showGroupCache;
      if (shouldShowGroup === undefined && this.state.allFacets && !this.state.facetQueryPending) {
        let groupIds = this.state.allFacets.group_id_set ? Object.keys(this.state.allFacets.group_id_set) : [];

        groupIds = groupIds.filter(id => !!stores.Groups.getGroupName(id));
        shouldShowGroup = groupIds.length > 1;

        // cache value if no search or filter applied
        if (!this.state.useSearchOrFilter) this.showGroupCache = shouldShowGroup;
      }
      return !!shouldShowGroup;
    }

    return false;
  }

  get selectedGroup() {
    if (this.state.groupId) {
      return {
        label: stores.Groups.getGroupName(this.state.groupId),
        value: this.state.groupId
      };
    }
  }

  get userGroups() {
    if (this.showGroup) {
      let groups = this.groupsCache;
      // If no group filter is applied, or we don't have a list of groups
      // cached, build the group list now based on the current facet data
      if (!this.state.groupId || !groups) {
        groups = Object.keys(this.allFacets.group_id_set)
          .map(id => ({
            label: stores.Groups.getGroupName(id),
            value: id
          }))
          .filter(group => !!group.label);

        // Cache this list of groups in case a group filter is applied
        this.groupsCache = groups;
      }
      return groups;
    }
  }

  get activeShareId() {
    return this.state.userShare || this.state.groupShare;
  }

  get activeShare() {
    if (!this.state.sharedAgreements) return { value: 'none' };

    if (this.activeShareId) {
      // If the shareId isn't found, we fall back on all shared agreements
      const share = stores.UserShares.shares.get(this.activeShareId);
      if (share) {
        return accountShareToShareItem(share);
      }
    }

    return { value: 'all' };
  }

  // Is account-shared content is being viewed via the share drop-down
  get viewingSharedAgreements() {
    return this.activeShare.value !== 'none';
  }

  // Has a value of true if:
  //  - the user has explicitly switched to a sharer's account, or
  //  - account-shared content is being viewed via the share drop-down
  get isAccountSharingContext() {
    return !!stores.Env.sharer || this.viewingSharedAgreements;
  }

  get firstSelectedAgreement() {
    return this.state.selectedAgreement || (this.state.selectedAgreementList || [])[0];
  }

  get agreementSharerUserId() {
    // We should only pass sharerUserId to context board when viewing account-shared content
    if (!this.viewingSharedAgreements) return;

    // Env.sharer is present when the logged-in user has switched to another account view.
    const user = stores.Env.sharer || stores.Env.user;
    const agreement = this.firstSelectedAgreement;

    return user.seriouslySecureId !== agreement.userId ? agreement.userId : undefined;
  }

  showReviewEndDateDialog(agreementId, agreementName, agreementType) {
    const { intl, showToast } = this.props;
    const container = window.document.body;
    ModalContainer.show(
      <ReviewEndDate
        intl={intl}
        showToast={showToast}
        agreementId={agreementId}
        agreementName={agreementName}
        agreementType={agreementType}
        Api={stores.Api}
        onModify={date => this.endDateModifyAction(agreementId, date)}
      />,
      this,
      container
    );
  }

  showEndDateCalendarIntegrationDialog(agreementId, agreementName, agreementType, agreementEndDate, endDateConfirmed) {
    const { intl } = this.props;
    const container = window.document.body;
    ModalContainer.show(
      <EndDateCalendarIntegration
        intl={intl}
        agreementId={agreementId}
        agreementName={agreementName}
        agreementType={agreementType}
        agreementEndDate={agreementEndDate}
        agreementEndDateConfirmed={endDateConfirmed}
        Api={stores.Api}
      />,
      this,
      container
    );
  }

  @bindAndCatchAndThrow
  handleCallToAction(context) {
    if (context === 'header') this.sendAnalytics(analyticsFor.CALL_TO_ACTION_HEADER);
    else this.sendAnalytics(analyticsFor.CALL_TO_ACTION);
    const { agreementState, agreementType, parentId, parentOwnerId } = this.state;
    const typeAndState = agreementState ? `${agreementType}.${agreementState}` : agreementType;
    const action = agreementType === 'webform' && parentId ? `${agreementType}.getCode` : typeAndState;

    switch (action) {
      case 'megasign':
        this.redirectForMegaSignAction(context);
        break;
      case 'webform':
        this.redirectForWebformsAction(context);
        break;
      case 'webform.getCode':
        // Leverage 'get_code' context board action
        this.triggerOpenAction('get_code', parentId, 'widget', parentOwnerId);
        break;
      case 'template':
        this.redirectForCreateTemplate(context);
        break;
      case 'all':
      case 'agreement.waiting_for_others':
        this.redirectForSendForSignatureAction(context);
        break;
      default:
    }
  }

  @bindAndCatchAndThrow
  handleEndDateAction() {
    // this.sendAnalytics(analyticsFor.CALL_TO_ACTION_HEADER);
    this.routingUtil.setEndDate('unconfirmed');
  }

  redirectForWebformsAction = context => {
    if (this.props.isDCWeb) {
      const contextOption = context === 'header' ? analyticsFor.CALL_TO_ACTION_HEADER : analyticsFor.CALL_TO_ACTION;
      discovery
        .callBootstrapMethod('verb-createwebform', 'executeAction', { context: contextOption })
        .catch(err => log.error(err.message));
    } else {
      RoutingUtil.navigateWithPath('/account/createWidget');
    }
  };

  redirectForMegaSignAction = context => {
    if (this.props.isDCWeb) {
      const contextOption = context === 'header' ? analyticsFor.CALL_TO_ACTION_HEADER : analyticsFor.CALL_TO_ACTION;
      discovery
        .callBootstrapMethod('verb-sendinbulk', 'executeAction', { context: contextOption })
        .catch(err => log.error(err.message));
    } else {
      RoutingUtil.navigateWithPath('/public/composeMega');
    }
  };

  redirectForSendForSignatureAction = context => {
    // If the user is on DCWeb then navigate the user to verb-sendforsignature which will take care of showing upsell
    if (this.props.isDCWeb) {
      const contextOption = context === 'header' ? analyticsFor.CALL_TO_ACTION_HEADER : analyticsFor.CALL_TO_ACTION;
      discovery
        .callBootstrapMethod('verb-sendforsignature', 'initializeAction', {
          context: contextOption,
          showCallback: () => {} // the initialize action is expecting a showCallback, no-op in this case
        })
        .then(() => discovery.callBootstrapMethod('verb-sendforsignature', 'executeAction', { context: contextOption }))
        .catch(err => log.error(err.message));
    } else {
      RoutingUtil.navigateWithPath('/public/compose');
    }
  };

  redirectForCreateTemplate = context => {
    if (this.props.isDCWeb && !this.props.showDraftAgreements) {
      const contextOption = context === 'header' ? analyticsFor.CALL_TO_ACTION_HEADER : analyticsFor.CALL_TO_ACTION;
      discovery
        .callBootstrapMethod('verb-esigntemplate', 'executeAction', { context: contextOption })
        .catch(err => log.error(err.message));
    } else {
      RoutingUtil.navigateWithPath('/account/addDocumentToLibrary');
    }
  };

  redirectToCompleteAction = promise => {
    const nextPage = window.open('', '_self');
    promise
      .then(url => {
        nextPage.location.href = url;
      })
      .catch(e => {
        log.error('e', e);
        this.props.showToast(e);
      });
  };

  triggerOpenAction = (action, agreementId, agreementType, userId, openInNewTab) => {
    this.showWait();
    this.setState(
      () => {
        return {
          openAction: action,
          openInNewTab: openInNewTab,
          scbNonce: Date.now(),
          selectedAgreement: {
            agreementId,
            agreementType,
            userId
          }
        };
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  @bindAndCatchAndThrow
  handleSingleSelectionChange(selectedIndexPaths) {
    let analyticsContext = {};
    let selectedAgreement = null;
    let selection = selectedIndexPaths.sectionIndexSets.get(0);
    let hasSelection = !!selection;
    let filterPanelOpen = this.state.filterPanelOpen;
    if (hasSelection) {
      const rowIndex = selectedIndexPaths.sectionIndexSets.get(0).ranges[0].start;
      const agreementRow = this.dataSource.getRowData(rowIndex);
      const agreementType = agreementRow.agreement_type.toLowerCase();
      analyticsContext = {
        rowIndex,
        agreementType,
        agreementState: agreementRow.state
      };
      selectedAgreement = {
        agreementType,
        agreementId: agreementRow.agreement_id,
        userId: agreementRow.user_id
      };
      filterPanelOpen = false;
    } else {
      analyticsContext = { rowIndex: null };
    }

    this.sendAnalytics(analyticsFor.AGREEMENT_SELECTED, analyticsContext);

    this.setState({ selectedAgreement, openAction: undefined, filterPanelOpen }, () => {
      // Address layout issue in table body - body rows are not updated to
      // reflect the new table width when showing/hiding the context board.
      this.forceUpdate();
    });
  }

  /**
   */
  @bindAndCatchAndThrow
  handleSelectionChange(selectedIndexPaths, bulkUpdate) {
    if (!this.allowMultiSelect) {
      return this.handleSingleSelectionChange(selectedIndexPaths);
    }

    let analyticsContext = {};
    let selectedAgreement = null;
    let selectedAgreementList = [];
    const selection = selectedIndexPaths && selectedIndexPaths.sectionIndexSets.get(0);
    const pickAgreement = rowIndex => {
      const agreementRow = this.dataSource.getRowData(rowIndex);
      if (!agreementRow) return null;
      const agreementType = agreementRow.agreement_type.toLowerCase();
      const agreementState = agreementRow.state;
      analyticsContext = {
        rowIndex,
        agreementType,
        agreementState: agreementRow.state
      };

      return {
        agreementType,
        agreementState,
        agreementId: agreementRow.agreement_id,
        userId: agreementRow.user_id,
        name: agreementRow.name // is it needed?
      };
    };

    // no updates while bulk processing
    if (this.bulkProcessing) return;
    if (selection) {
      const ranges = getSelectionRanges(selectedIndexPaths);
      selectedAgreementList = ranges.map(pickAgreement).filter(agr => !!agr);
    }

    if (selectedAgreementList.length === 1) {
      selectedAgreement = selectedAgreementList[0];
      this.hasSelection = false;
      analyticsContext.multiMode = false;
      analyticsContext.rowIndex = selectedIndexPaths.sectionIndexSets.get(0).ranges[0].start;
    } else if (selectedAgreementList.length > 1) {
      analyticsContext.numSelected = selectedAgreementList.length;
      analyticsContext.rowIndex = getSelectionRanges(selectedIndexPaths, true);
      this.hasSelection = true;
      analyticsContext.multiMode = true;
    } else {
      this.hasSelection = false;
      analyticsContext = { rowIndex: null };
    }

    if (!bulkUpdate && analyticsContext.rowIndex !== null) {
      this.sendAnalytics(analyticsFor.AGREEMENT_SELECTED, analyticsContext);
    }
    this.setState({ selectedAgreement, selectedAgreementList, openAction: undefined, selectedPACategory: null }, () => {
      // Address layout issue in table body - body rows are not updated to
      // reflect the new table width when showing/hiding the context board.
      this.forceUpdate();
    });
  }

  @bindAndCatchAndThrow
  handleCellAction(column, clickedIndex) {
    if (column.key === 'action') {
      const item = this.dataSource.getRowData(clickedIndex);
      this.sendAnalytics(analyticsFor.AGREEMENT_ACTION, {
        rowIndex: clickedIndex,
        agreementType: item.agreement_type,
        agreementState: item.state
      });

      this.redirectToCompleteAction(this.getSigningActionUrl(item.agreement_id, item.state));
    } else if (column.key === 'show_details') {
      const selection = this.dataSource.collection.selectedIndexPaths;
      const rowSelected = getSelectionRanges(selection).includes(clickedIndex);
      this.clearSelection();
      // Select the row if it row wasn't selected, or if multiple items were selected
      if (!rowSelected || selection.length > 1) {
        this.dataSource.select(clickedIndex);
      }
    } else if (column.key === 'termination_dates_confirmed') {
      const item = this.dataSource.getRowData(clickedIndex);
      this.sendAnalytics(analyticsFor.REVIEW_END_DATE_ACTION, {
        context: this.state.agreementEndDate
      });
      // Unselect the current item and close the sign context board
      this.clearSelection();
      this.showReviewEndDateDialog(item.agreement_id, item.name, item.agreement_type);
    } else if (column.key === 'termination_dates_calendar') {
      const item = this.dataSource.getRowData(clickedIndex);
      this.sendAnalytics(analyticsFor.END_DATE_CALENDAR_ACTION, {
        endDateConfirmed: item.termination_dates_confirmed
      });
      // Unselect the current item and close the sign context board
      this.clearSelection();
      this.showEndDateCalendarIntegrationDialog(
        item.agreement_id,
        item.name,
        item.agreement_type,
        item.termination_dates[0],
        item.termination_dates_confirmed
      );
    } else if (column.key === 'show_child_agreements') {
      const item = this.dataSource.getRowData(clickedIndex);
      this.setState({ parentTitle: item.name }, () => {
        this.routingUtil.setNav('megasign', 'all', item.agreement_id);
      });
    }
  }

  @bindAndCatchAndThrow
  handleCellDoubleClick(column, clickedIndex) {
    const { agreement_type: type, state, agreement_id: id } = this.dataSource.getRowData(clickedIndex);
    this.sendAnalytics(analyticsFor.ROW_DOUBLE_CLICK, {
      rowIndex: clickedIndex,
      agreementType: type,
      agreementState: state
    });
    if (this.props.handleCellDoubleClick) {
      if (this.props.isDCWeb) {
        let url = `/public/agreements/view/${id}?app=dc&type=${type.toLowerCase()}`;
        RoutingUtil.navigateWithPath(url);
      }
    }
    // No double-click action for initial 10.3 release
    // if (type === 'MEGASIGN_PARENT') {
    //   this.setState({ parentTitle: name }, () => {
    //     this.routingUtil.setNav('megasign', undefined, id);
    //   });
    // } else {
    //   RoutingUtil.navigateWithPath(`/public/agreements/view/${id}?type=${type.toLowerCase()}`);
    // }
  }

  @bindAndCatchAndThrow
  handleHeaderClick() {
    if (this.state.useSearchOrFilter && this.state.searchTerm) {
      this.sendAnalytics(analyticsFor.HEADER_BACK);
      this.routingUtil.clearSearch();
      delete this.searchUtil.searchRequestTrackingId;
    } else {
      this.routingUtil.setNav(this.state.agreementType);
    }
  }

  @bindAndCatchAndThrow
  handleSearchInputSubmit(value) {
    this.sendAnalytics(analyticsFor.SEARCH_INPUT_SUBMIT, {
      search_term_length: value.length,
      search_term_word_count: value.length > 0 ? value.split(' ').length : 0,
      search_term_has_at_sign: value.indexOf('@') >= 0,
      search_term_has_quotes: value.indexOf('"') >= 0
    });
    this.routingUtil.setSearchTerm(value);
    delete this.searchUtil.searchRequestTrackingId;
  }

  @bindAndCatchAndThrow
  handleStatusTabChange(state, count) {
    this.sendAnalytics(analyticsFor.STATUS_TAB_LINK, {
      agreementState: state,
      targetCount: count
    });
    this.routingUtil.setAgreementState(state);
  }

  @bindAndCatchAndThrow
  handleEndDateTabChange(endDate, count) {
    this.sendAnalytics(analyticsFor.END_DATE_TAB_LINK, {
      endDate,
      targetCount: count
    });
    this.routingUtil.setEndDate(endDate);
  }

  @bindAndCatchAndThrow
  handleDrawerToggleClick() {
    this.sendAnalytics(analyticsFor.DRAWER_TOGGLE_BUTTON);
    this.setState(prevState => ({ openNav: !prevState.openNav }));
  }

  @bindAndCatchAndThrow
  handleCloseNav() {
    this.setState({ openNav: false });
  }

  @bindAndCatchAndThrow
  handleGetShares() {
    return stores.UserShares.fetchAnyRemainingShares()
      .catch(error => {
        log.warn('Unable to get remaining shares', { error });
      })
      .then(() => {
        return Object.values(
          // De-dup shares based on sharer id
          stores.UserShares.shares.reduce((acc, share) => {
            const sharerId = share.get('sharer').id;
            // Omit shares where user is sharer, e.g. they shared with own group
            if (sharerId !== stores.Env.user.seriouslySecureId) acc[sharerId] = accountShareToShareItem(share);
            return acc;
          }, {})
        );
      });
  }

  @bindAndCatchAndThrow
  handleAccountShareChange(item) {
    if (item.value === 'none') {
      this.routingUtil.clearShared();
    } else {
      this.routingUtil.setShared(
        item.type === 'USER' ? item.value : undefined,
        item.type === 'GROUP' ? item.value : undefined
      );
      if (item.value === 'all') {
        stores.Env.resetAccountShareCache();
      }
    }

    // When switching between account share views, clear the cached state for whether or not
    // to show the group column/filter. This is to address cases such as the logged-in user
    // being a member of a single group, but the sharer is a member of many.
    delete this.showGroupCache;
    // Also clear the hidden nav items cache, to be rebuilt based on updated facet data
    delete this.hiddenNavCache;
  }

  @bindAndCatchAndThrow
  handleContextAction(action, agreementId, agreementType, userId, openInNewTab) {
    const rowIndex = this.dataSource.findIndexForAgreement(agreementId);
    this.sendAnalytics(analyticsFor.INLINE_ACTION, { action, agreementType, openInNewTab, rowIndex });
    this.triggerOpenAction(action, agreementId, agreementType, userId, openInNewTab);
  }

  @bindAndCatchAndThrow
  handleSortChange(column, direction) {
    this.dataSource.setIsManualSort(true);
    this.setState({
      manualSort: true,
      sortColumn: column,
      sortDirection: direction
    });
  }

  getSigningActionUrl(id, status) {
    const agreement = new stores.Api.Agreements.Agreement({ id });
    const viewName = status === 'WAITING_FOR_PREFILL' ? 'PREFILL' : 'SIGNING';
    return agreement.views.save({ name: viewName }).then(() => agreement.views.list.first().get('url'));
  }

  getWebformCodeUrl(id) {
    const webform = new stores.Api.Widgets.Widget({ id });
    return webform.views.save({ name: 'POST_CREATE' }).then(() => {
      const view = webform.views.list.first();
      return view.get('url');
    });
  }

  renderCallToAction() {
    if (this.state.agreementEndDate) return this.renderEndDateCallToAction();

    const { intl, showCallToAction, isDCWeb } = this.props;
    const { agreementType, agreementState, queryFacets } = this.state;
    const getIntlKey = CallToActionUtil.getIntlKey;
    const hasOtherFacetCount = this.emptySearchResult && CallToActionUtil.hasFacetCount(queryFacets);
    const ctaHasAction = CallToActionUtil.hasAction(
      agreementType,
      agreementState,
      this.canUserSendForSignature,
      this.canUserCreateTemplate,
      this.state.userHasNoSignAccess,
      this.canUserCreateWebForm
    );
    const showAction = showCallToAction && ctaHasAction && !hasOtherFacetCount;
    const ctaSuffix = agreementType === 'webform' && hasOtherFacetCount ? 'has_form_in_other_state' : undefined; // only applies to webform

    return (
      <CallToAction
        heading={intl.formatMessage({ id: getIntlKey('title', agreementType, agreementState, ctaSuffix) })}
        description={intl.formatMessage({ id: getIntlKey('body', agreementType, agreementState, ctaSuffix) })}
        illustration={agreementType}
        action={
          showAction
            ? intl.formatMessage({ id: getIntlKey('button', agreementType, agreementState, ctaSuffix, isDCWeb) })
            : undefined
        }
        onClick={this.handleCallToAction}
      />
    );
  }

  renderEndDateCallToAction() {
    const { intl } = this.props;
    const { agreementEndDate, queryFacets } = this.state;
    const hasUnconfirmedAgreements = queryFacets.unconfirmed.count > 0;
    const showAction = agreementEndDate === 'confirmed' && hasUnconfirmedAgreements;

    return (
      <CallToAction
        heading={intl.formatMessage({ id: `call_to_action.end_date.${agreementEndDate}.title` })}
        description={intl.formatMessage({ id: `call_to_action.end_date.${agreementEndDate}.body` })}
        illustration={'agreement'}
        action={showAction ? intl.formatMessage({ id: 'call_to_action.end_date.confirmed.button' }) : undefined}
        onClick={this.handleEndDateAction}
      />
    );
  }

  get isCsvExportEnabled() {
    return stores.UserSettings.settings.get('enableManageCsvExport');
  }

  get isKeyDateExtractionEnabled() {
    return stores.UserSettings.settings.get('dateExtractionEnabled');
  }

  filterLabelCount() {
    const filters = [
      !!this.state.dateRangeType,
      !!this.state.payment_amount_min,
      !!this.state.payment_amount_max,
      !!this.state.payment_currency,
      !!this.state.payment_date_start,
      !!this.state.payment_company
    ];
    return filters.filter(x => x).length;
  }

  filterPanelButton() {
    // Filter label count
    const labelCount = this.filterLabelCount();
    const labelId = labelCount > 0 ? 'filter.label.count' : 'filter.label';
    return (
      <Button
        className="filter-panel-button"
        data-testid="filter-panel-button"
        label={this.props.intl.formatMessage({ id: labelId }, { count: labelCount })}
        icon={<FilterIcon className="spectrum-Icon spectrum-Icon--sizeS" role="img" />}
        variant="action"
        selected={this.state.filterPanelOpen}
        onClick={() => {
          if (this.state.filterPanelOpen) {
            this.sendAnalytics(analyticsFor.FILTERPANEL_CLOSE);
            this.setState({ filterPanelOpen: false }, () => {
              this.forceUpdate();
            });
          } else {
            this.sendAnalytics(analyticsFor.FILTERPANEL_OPEN);
            this.setState({ filterPanelOpen: true }, () => {
              if (this.dataSource.collection) {
                this.clearSelection();
              }
            });
          }
        }}
      />
    );
  }

  renderFilters() {
    const Container = this.props.filterButtonInTableHeader ? HeaderFiltersContainer : FiltersContainer;

    if (this.state.jpTagErrlEnabled) {
      return <Container>{this.filterPanelButton()}</Container>;
    } else {
      // otherwise, render filter group
      return (
        <Container>
          <FilterGroup
            setPopoverOpen={val => {
              this.setState({ isPopoverOpen: val });
            }}
            isPopoverOpen={this.state.isPopoverOpen}
            routingUtil={this.routingUtil}
            sendAnalytics={this.sendAnalytics}
            dateRangeType={this.state.dateRangeType}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            selectedGroup={this.selectedGroup}
            groups={this.userGroups}
            queryableField={this.state.queryableField}
            showFieldFilter={this.props.showFieldFilter}
            visibility={this.state.visibility}
            popoverPlacement={this.props.filterPopoverPlacement}
          />
        </Container>
      );
    }
  }

  get showHeaderCTAButtons() {
    const { showCallToAction, showCallToActionInHeader } = this.props;
    const { agreementType, agreementState } = this.state;
    const hasHeaderCTAButton = CallToActionUtil.hasHeaderCTAButton(
      agreementType,
      agreementState,
      this.canUserSendForSignature,
      this.canUserCreateTemplate,
      stores.isMobile,
      this.state.userHasNoSignAccess,
      this.canUserCreateWebForm,
      this.canUserSendInBulk
    );
    return (
      showCallToAction &&
      showCallToActionInHeader &&
      hasHeaderCTAButton &&
      !(this.emptySearchResult && this.useCallToAction)
    );
  }

  renderHeaderCTAButtons() {
    const { intl, isDCWeb } = this.props;
    const { agreementType, agreementState } = this.state;
    const getIntlKey = CallToActionUtil.getIntlKey;
    return (
      <CallToActionContainer filterButtonInTableHeader={this.props.filterButtonInTableHeader}>
        <Button
          className="call-to-action-header-button"
          onClick={() => this.handleCallToAction('header')}
          variant="primary"
          label={intl.formatMessage({ id: getIntlKey('button', agreementType, agreementState, undefined, isDCWeb) })}
        />
      </CallToActionContainer>
    );
  }

  renderTableHeader() {
    return (
      <TableHeader
        onClick={this.handleHeaderClick}
        searchTerm={this.state.searchTerm === '*' ? '' : this.state.searchTerm}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        context={this.searchContext}
        title={this.state.parentId ? this.state.parentTitle || '\u2014' : undefined}
      />
    );
  }

  renderTabList() {
    let data;
    let onChange;

    if (this.state.parentId) {
      data = NavUtil.getStateTabs(
        this.state.queryFacets || {},
        this.state.agreementState,
        this.isAccountSharingContext
      );
      onChange = this.handleStatusTabChange;
    } else if (this.state.agreementEndDate) {
      data = NavUtil.getEndDateTabs(
        this.state.queryFacets || {},
        this.state.agreementEndDate,
        this.state.queryEndedFacets || {}
      );
      onChange = this.handleEndDateTabChange;
    }

    return data && <StyledStatusTabList className="status-tabs" data={data} onChange={onChange} />;
  }

  refineColumns(columns) {
    // appending 'icon' column based on useIcon prop
    if (this.props.useIcon) {
      if (!columns.FULL.includes('icon')) {
        columns.FULL.unshift('icon');
      }
    }

    // append 'context_actions' columnn based on prop
    if (this.props.showContextActions) {
      if (!this.props.useInlineActions && !columns.FULL.includes('context_actions')) {
        columns.FULL.push('context_actions');
      }

      // the action column is replaced with state in FULL layout, and retained in MIN layout
      if (columns.FULL.includes('action') && !columns.FULL.includes('state')) {
        columns.FULL.splice(columns.FULL.indexOf('action'), 0, 'state');
      }
    }

    if (this.props.showDetailsButtonInTable) {
      if (!columns.FULL.includes('show_details')) {
        columns.FULL.unshift('show_details');
      }
      if (!columns.MIN.includes('show_details')) {
        columns.MIN.unshift('show_details');
      }
    }

    // add selection checkbox
    if (this.allowMultiSelect) {
      if (!columns.FULL.includes('checkbox')) {
        columns.FULL.unshift('checkbox');
      }
      if (!columns.MIN.includes('checkbox')) {
        columns.MIN.unshift('checkbox');
      }
    }
  }

  renderTable() {
    const showAgreementType = this.showConsolidatedView || this.showingAll;
    const showCompositeMin = !this.props.isDCWeb; // Showing composite minimum columns for small media device
    const columnsByTypeOrState = ColumnUtil.getColumnsByTypeOrState(
      this.state.agreementType,
      this.agreementStateOrDefault(),
      !!this.state.parentId,
      this.showGroup,
      showCompositeMin,
      !!this.state.agreementEndDate,
      // We don't want to show the calendar icon on the ended tab
      this.state.agreementEndDate !== 'ended'
    );

    // Conditional updates to columns based on props
    this.refineColumns(columnsByTypeOrState);

    // Spectrum Hack: should not be passing this config to Sign Table.
    // This is to prevent row focus issue in react spectrum by always render full columns
    // and using CSS to show minimum columns
    const columnsToDisplay = {
      useMin: this.useMinimumSignTableColumns,
      actionPermitted:
        // Cannot sign on behalf of account sharer
        !stores.Env.sharer &&
        !this.viewingSharedAgreements &&
        // Do not show Action column when inline actions enabled
        (!this.props.showContextActions || this.useMinimumSignTableColumns),
      sharedAgreementsView: this.viewingSharedAgreements,
      switchAccountView: !!stores.Env.sharer,
      drillDownView: !!this.state.parentId,
      user: stores.Env.user,
      userShare: this.state.userShare,
      columns: columnsByTypeOrState
    };
    const allowMultiSelect = this.allowMultiSelect;

    // The filter button, when positioned in the header, shouldn't be shown in the placeholder view.
    const showFilterButtonInHeader =
      this.props.filterButtonInTableHeader && !(this.emptySearchResult && this.useCallToAction);

    // Display composite row height
    const rowHeight = this.useMinimumSignTableColumns ? '72' : this.props.rowHeight;

    let { sortColumn, sortDirection, manualSort, searchTerm, agreementState } = this.state;

    // Default to score-based sorting when searching
    if (searchTerm !== '*' && !manualSort) sortColumn = 'undefined';

    const deletedFolderBanner = agreementState === 'deleted' ? <DeletedFolderBanner /> : null;

    return (
      <WrapperContainer>
        {deletedFolderBanner}
        <TableContainer>
          <HeaderContainer>
            {this.renderTableHeader()}
            {showFilterButtonInHeader && this.renderFilters()}
            {this.showHeaderCTAButtons && this.renderHeaderCTAButtons()}
          </HeaderContainer>
          {this.renderTabList()}
          {this.emptySearchResult ? (
            this.state.searchError ? (
              <SearchError
                error={this.state.searchError}
                query={this.state.searchErrorQuery}
                token={this.state.searchErrorToken}
                tokenPos={this.state.searchErrorTokenPos}
                message={this.state.searchErrorMessage}
              />
            ) : this.useCallToAction ? (
              this.renderCallToAction()
            ) : (
              <EmptySearchResults />
            )
          ) : (
            <StyledSignTable
              // Force re-mount of SignTable if multi-select param changes
              // so that overrides are cleared.
              key={`key-${allowMultiSelect}`}
              allowMultiSelect={allowMultiSelect}
              dataSource={this.dataSource}
              columnsToRender={columnsByTypeOrState.FULL}
              onCellAction={this.handleCellAction}
              onCellDoubleClick={this.handleCellDoubleClick}
              onSelectionChange={this.handleSelectionChange}
              columnsToDisplay={columnsToDisplay}
              showAgreementType={showAgreementType}
              rowHeight={rowHeight}
              useQuietTableVariant={this.props.useQuietTableVariant}
              isCsvExportEnabled={this.isCsvExportEnabled}
              scbAction={this.handleContextAction}
              sendAnalytics={this.sendAnalytics}
              navTabId={this.routingUtil.getTabID()}
              showConfirmedEndDates={this.isKeyDateExtractionEnabled && agreementState === 'completed'}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSortChange={this.handleSortChange}
              useInlineActions={this.props.useInlineActions}
              unresolvedBounceAvailable={this.state.unresolvedBounceAvailable}
            />
          )}
        </TableContainer>
      </WrapperContainer>
    );
  }

  // Helper method to see what's causing a re-render
  /* istanbul ignore next */
  __componentDidUpdate(prevProps, prevState) {
    console.log('multi-select', this.allowMultiSelect);
    Object.entries(this.props).forEach(
      ([key, val]) => prevProps[key] !== val && console.log(`Prop '${key}' changed`, val)
    );
    if (this.state) {
      Object.entries(this.state).forEach(
        ([key, val]) => prevState[key] !== val && console.log(`State '${key}' changed`, val)
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // When agreement type changes to/from "template" we have to re-mount the
    // SignTable because of multi-select overrides.  Prevent an update while
    // the search query is pending to prevent flashing of the table.
    let types = [nextState.agreementType, this.state.agreementType];
    if (nextState.facetQueryPending && types.includes('template') && types[0] !== types[1]) {
      return false;
    }
    return true;
  }

  // SCB event handler
  onBulkAction(data) {
    const isRemovable = data.action === 'showHide';
    switch (data.status) {
      case 'start':
        // prefetch next page if we're going to be removing a significant
        // number of rows
        if (isRemovable) {
          this.dataSource.softRefresh();
        }
        break;
      case 'progress':
        const rowIndex = this.dataSource.findIndexForAgreement(data.agreementId);
        const agreementRow = this.dataSource.getRowData(rowIndex);
        this.bulkProcessing = true;
        if (data.error) {
          // mark item for error --
          agreementRow.lastError = data.error.message || data.error;
          // update row
          this.dataSource.updateRowData(rowIndex);
        } else {
          agreementRow.lastError = null;
          if (isRemovable) {
            this.removeTableRow(rowIndex, /* isBulk */ true);
          } else {
            // unselect item from selection
            this.dataSource.deselect(rowIndex);
          }
        }
        break;
      case 'complete':
      case 'stopped':
        const { numErrors, numProcessed } = data;
        const onlyErrors = numErrors > 0 && numErrors === numProcessed;
        this.props.showToast({
          type: onlyErrors ? 'error' : 'success',
          message: data.message
        });

        // Allow table animations to catch up before a final update
        setTimeout(() => {
          this.bulkProcessing = false;
          // update selected agreements & the context board
          this.handleSelectionChange(this.dataSource.collection.selectedIndexPaths, true);
        }, 500);
        break;
      case 'cancelled':
      default:
        this.bulkProcessing = false;
        break;
    }
  }

  // SCB single action handler
  // For actions see: https://git.corp.adobe.com/dc/dc-sign-context-dropin/README.md
  onAction(data) {
    const removeRow = () => this.removeRowByAgreement(data.agreementId);
    switch (data.action) {
      case 'signed':
        if (this.showingAll) {
          const rowIndex = this.dataSource.findIndexForAgreement(data.agreementId);
          this.dataSource.updateRowData(rowIndex, {
            state: data.state || 'SiGNED'
          });
        } else {
          removeRow();
        }
        break;
      case 'cancel':
        // Only remove the row if we're not showing all
        if (!this.showingAll) {
          removeRow();
        }
        break;
      case 'delete':
      case 'restore':
      case 'showHide':
        removeRow();
        break;
      case 'ownerChanged':
        this.ownerChangedAction(data.agreementId, data.newOwner);
        break;
      case 'endDateModified':
        this.endDateModifyAction(data.agreementId, data.endDate);
        break;
      case 'remind':
        this.remindAction(data);
        break;
      case 'notes':
        this.notesAction(data.agreementId, data.note);
        break;
      case 'bouncedParticipationReplaced':
        this.updateRowOnBouncedParticipationReplacedEvent(data.agreementId, data.newParticipationEmail);
        break;
      default:
    }
  }

  /**
   * Update has_unresolved_bounce and active_participant for agreement where participant is replaced on context-dropin
   * @param {object} data Event data received from context-dropin (active_participant)
   */
  updateRowOnBouncedParticipationReplacedEvent = (agreementId, newParticipationEmail) => {
    const rowIndex = this.dataSource.findIndexForAgreement(agreementId);
    this.dataSource.updateRowData(rowIndex, {
      has_unresolved_bounce: false,
      active_participant: newParticipationEmail
    });
  };

  removeRowByAgreement(agreementId) {
    const rowIndex = this.dataSource.findIndexForAgreement(agreementId);
    this.removeTableRow(rowIndex);
  }

  removeTableRow(rowIndex, isBulk, updateFacetCount = true) {
    if (rowIndex < 0) return;
    const selectedIndexPaths = this.dataSource.collection.selectedIndexPaths;
    const selection = selectedIndexPaths && selectedIndexPaths.sectionIndexSets.get(0);
    this.dataSource.removeRow(rowIndex, isBulk);
    // update facet counts, if required
    if (updateFacetCount) {
      const currFacet = this.allFacets[this.agreementTypeOrState];
      if (currFacet && currFacet.count) {
        const decrementFacetCount = () => {
          --currFacet.count;
        };
        // In Acrobat Web the facets are an observable passed in the
        // facetCounts prop. In that case we need to use runInAction.
        if (isObservableObject(this.allFacets)) runInAction(decrementFacetCount);
        else decrementFacetCount();
      }
    }
    // if there was no selection within the table we must manually update our selection state
    if (!selection) this.clearSelection();
  }

  ownerChangedAction(agreementId, newOwner) {
    const rowIndex = this.dataSource.findIndexForAgreement(agreementId);
    if (this.isItemAtRowIndexShared(rowIndex)) {
      // update row data
      if (stores.User.fullName === newOwner) {
        this.dataSource.updateRowData(rowIndex, { sharer: null });
      } else {
        this.dataSource.updateRowData(rowIndex, { sharer: newOwner });
      }
    } else {
      // close context board and remove row in case of only me assets
      this.removeRowByAgreement(agreementId);
    }
  }

  endDateModifyAction(agreementId, date) {
    const rowIndex = this.dataSource.findIndexForAgreement(agreementId);
    const item = this.dataSource.getRowData(rowIndex);
    let updateTableRow = true;

    // If the change is done in the context of the "Ending soon"
    // category we need to update the counts in the query facets
    if (this.state.agreementEndDate) {
      const queryFacets = this.state.queryFacets;
      const queryEndedFacets = this.state.queryEndedFacets;
      const category = item.termination_dates_confirmed ? 'confirmed' : 'unconfirmed';

      // Agreement will be removed from the "Ending soon" listing if
      //  - there is no end date
      //  - the selected date is in the past
      // Agreement will be added to the "Ending soon > Ended" listing if
      //  - the dc-sign-manage-end-date-ended-enabled flag is true AND
      //  - the selected date is in the past
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const notEndingSoon = !date || new Date(date) < today;

      if (this.state.agreementEndDate === 'ended') {
        // Should remove row from the ended table if:
        //  - modified end date changes to a future date.
        //  - modified end date is removed / cleared
        if (!notEndingSoon || !date) {
          this.removeTableRow(rowIndex, false, false);
          updateTableRow = false;

          // update the counts
          if (date) {
            queryFacets.all.count += 1;
            queryFacets.confirmed.count += 1;
          }
          queryEndedFacets.all.count -= 1;
        }
      } else {
        // Should remove row from the table if:
        //  - agreement no longer "ending soon"
        //  - date is being confirmed in the context of the "Needs review" list
        if (notEndingSoon || this.state.agreementEndDate === 'unconfirmed') {
          this.removeTableRow(rowIndex, false, false);
          updateTableRow = false;
        }

        // update the counts
        if (notEndingSoon) {
          // Decrement the facet counts as the agreement is being removed
          queryFacets.all.count -= 1;
          queryFacets[category].count -= 1;
          if (date) {
            queryEndedFacets.all.count += 1;
          }
        } else if (category === 'unconfirmed') {
          // Update facet counts to reflect date being confirmed
          queryFacets.unconfirmed.count -= 1;
          queryFacets.confirmed.count += 1;
        }
      }

      // Ensure the updated counts are reflected in the status tabs
      this.setState({ queryFacets, queryEndedFacets });
    }

    if (updateTableRow) {
      this.dataSource.updateRowData(rowIndex, { termination_dates_confirmed: true, termination_dates: [date] });
    }
  }

  remindAction(data) {
    const rowIndex = this.dataSource.findIndexForAgreement(data.agreementId);
    const agreement = this.dataSource.getRowData(rowIndex);
    if (data.isCompleted) {
      this.dataSource.updateRowData(rowIndex, {
        completed_reminder_count: agreement.completed_reminder_count + 1
      });
    }
    if (data.isActive || data.isCanceled) {
      let updatedCount = data.isActive ? agreement.active_reminder_count + 1 : agreement.active_reminder_count - 1;

      this.dataSource.updateRowData(rowIndex, {
        active_reminder_count: updatedCount
      });
    }
  }

  notesAction(agreementId, modifiedNote) {
    const rowIndex = this.dataSource.findIndexForAgreement(agreementId);
    this.dataSource.updateRowData(rowIndex, {
      note: modifiedNote
    });
  }

  isItemAtRowIndexShared(rowIndex) {
    const item = this.dataSource.getRowData(rowIndex);
    if (item.agreement_type === AGREEMENT_TYPES.LIBRARY_TEMPLATE && item.shared_with_ids) {
      return true;
    }
    return !!item.sharer;
  }

  clearSelection() {
    this.setState(
      {
        selectedAgreement: null,
        selectedAgreementList: null
      },
      () => {
        // Address layout issue in table body - body rows are not updated to
        // reflect the new table width when showing/hiding the context board.
        this.forceUpdate();
      }
    );
    // deselect any selections
    this.dataSource.collection.clearSelection();
  }

  @bindAndCatchAndThrow
  scbObservableHandler(event = {}, proxy = {}) {
    this.scbDisposer = autorun(() => {
      log.info('SCB observable', event.type, event.data);
      switch (event.type) {
        case 'Action':
          this.onAction(event.data);
          break;
        case 'BulkAction':
          this.onBulkAction(event.data);
          break;
        case 'error':
        case 'toast':
        case 'didUpdate':
          if (!this.waitModalKey) break;
          if (
            event.type !== 'didUpdate' || this.state.openAction === 'remind'
              ? event.data.component === 'reminders'
              : event.data.component === 'ContextBoard'
          ) {
            setTimeout(() => this.hideWait(), 0);
          }
          break;
        case 'CloseContextBoard':
          this.clearSelection();
          break;
        default:
      }
    });
  }

  showWait() {
    if (!this.waitModalKey) {
      this.waitModalKey = ModalContainer.show(
        <ModalWaitContainer>
          <Wait size="L" />
        </ModalWaitContainer>,
        this
      );
    }
  }

  hideWait() {
    if (this.waitModalKey) {
      ModalContainer.hide(this.waitModalKey);
      this.waitModalKey = false;
    }
  }

  render() {
    const signPlugin = stores.Env.plugins.managejs;
    const { intl, isDCWeb, showSideNav, showTopBar, showThumbnailInContextBoard } = this.props;
    const goUrl = signPlugin?.useNewQueryLanguageFeatures ? 'sgql' : 'adobesign-search-users-agreements';

    if (this.state.error) {
      return <ErrorState />;
    }
    if (!this.renderable) {
      return null;
    }
    const SignContextBoard = this.state.signContextBoardDropin;

    if (this.emptySearchResult) {
      if (this.useCallToAction) {
        this.sendAnalytics(analyticsFor.CALL_TO_ACTION_SHOWN);
      } else {
        this.sendAnalytics(analyticsFor.EMPTY_STATE_SHOWN);
      }
    }

    let selectionProps = null;
    let multiMode = this.allowMultiSelect && this.hasSelection && !this.state.openAction;
    if (this.state.selectedPACategory) {
      selectionProps = {
        powerAutomateMode: this.state.selectedPACategory,
        isPowerAutomateOrgProvisioningCompleted: this.state.isPowerAutomateOrgProvisioningCompleted
      };
    } else if (this.state.selectedAgreement && !multiMode) {
      selectionProps = {
        agreementId: this.state.selectedAgreement.agreementId,
        agreementType: this.state.selectedAgreement.agreementType,
        multiMode,
        openInNewTab: this.state.openInNewTab,
        isPowerAutomateOrgProvisioningCompleted: this.state.isPowerAutomateOrgProvisioningCompleted
      };
    } else if (this.state.selectedAgreementList && multiMode) {
      selectionProps = {
        agreementList: this.state.selectedAgreementList,
        maxSelectionCount: getMaxSelectionCount(),
        multiMode,
        openInNewTab: this.state.openInNewTab,
        isPowerAutomateOrgProvisioningCompleted: this.state.isPowerAutomateOrgProvisioningCompleted
      };
    }

    return (
      <MainView>
        {!selectionProps && this.state.filterPanelOpen && (
          <FilterPanel
            intl={this.props.intl}
            routingUtil={this.routingUtil}
            agreementState={this.state.agreementState}
            rangeType={this.state.dateRangeType}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            visibility={this.state.visibility}
            paymentAmountMin={this.state.payment_amount_min}
            paymentAmountMax={this.state.payment_amount_max}
            paymentCurrency={this.state.payment_currency}
            paymentDateRangeType={this.state.payment_date_range_type}
            paymentStartDate={this.state.payment_start_date}
            paymentEndDate={this.state.payment_end_date}
            paymentCompany={this.state.payment_company}
            allFacets={this.allFacets}
            sendAnalytics={this.sendAnalytics}
          />
        )}
        <MainContainer>
          {showTopBar && (
            <TopBar>
              <AccountStatusContainer>
                {isMedia(media.phone) && <DrawerToggleButton onClick={this.handleDrawerToggleClick} />}
                <AccountStatus
                  accountSharingActive={this.state.accountSharingActive}
                  activeShare={this.activeShare}
                  getShares={this.handleGetShares}
                  onChange={this.handleAccountShareChange}
                  sendAnalytics={this.sendAnalytics}
                />
              </AccountStatusContainer>
              <SearchContainer>
                <SearchFilterContainer>
                  {this.renderFilters()}
                  <SearchInput
                    placeholder={intl.formatMessage({ id: 'search.placeholder' })}
                    value={this.state.searchInputValue}
                    onChange={(value, e, details) => {
                      this.setState({ searchInputValue: value }, () => {
                        // DCES-4334705 - (x) button should clear the input as well as the search result
                        if (details && details.from === 'clearButton') {
                          this.routingUtil.setSearchTerm();
                        }
                      });
                    }}
                    onSubmit={this.handleSearchInputSubmit}
                  />
                  {this.props.useInlineActions && (
                    <OverlayTrigger placement="bottom" trigger="hover">
                      <Button
                        variant="tool"
                        icon={<InfoOutline />}
                        aria-label={intl.formatMessage({ id: 'search_documentation.aria_label' })}
                        element="a"
                        href={getLocalizedGoUrl(goUrl)}
                        target="_blank"
                      />
                      <Tooltip>{intl.formatMessage({ id: 'search_documentation.tooltip' })}</Tooltip>
                    </OverlayTrigger>
                  )}
                </SearchFilterContainer>
              </SearchContainer>
            </TopBar>
          )}
          <ResultsContainer>
            {showSideNav && this.allFacets && (
              <ResponsiveNav
                value={this.agreementTypeOrStateById}
                data={NavUtil.getNavItems(
                  this.allFacets,
                  this.getHiddenNavItems(),
                  this.state.useSearchOrFilter,
                  (agreementType, agreementState, count, countShown) => {
                    this.handleCloseNav();
                    this.sendAnalytics(analyticsFor.NAV_LINK, {
                      agreementType,
                      agreementState,
                      targetCount: count,
                      targetCountShown: countShown
                    });
                    this.routingUtil.setNav(agreementType, agreementState);
                  },
                  this.isAccountSharingContext,
                  selectedPACategory => {
                    this.setState(
                      {
                        selectedPACategory: selectedPACategory
                      },
                      () => {
                        // Address layout issue in table body - body rows are not updated to
                        // reflect the new table width when showing/hiding the context board.
                        this.forceUpdate();
                      }
                    );
                  },
                  this.state.isPowerAutomateOrgProvisioningCompleted
                )}
                show={this.state.openNav}
                close={this.handleCloseNav}
              />
            )}
            {!SignContextBoard || (this.isUMGEnabled && !this.state.groupsAvailable) ? (
              <WaitContainer className="wait-container">
                <Wait />
              </WaitContainer>
            ) : (
              this.renderTable()
            )}
          </ResultsContainer>
        </MainContainer>
        {selectionProps && (
          <SignContextBoardContainer id="sign-context" isHidden={this.state.openAction} isDCWeb={this.props.isDCWeb}>
            <SignContextBoard
              {...selectionProps}
              isVisibilityHidden={this.state.visibility === 'SHOW_HIDDEN'}
              isDCWeb={isDCWeb}
              isHostEnvSign={!isDCWeb}
              isManageV4={true} // is this the list view?
              sharerUserId={this.agreementSharerUserId}
              showThumbnail={showThumbnailInContextBoard}
              startupAction={this.state.openAction}
              nonce={this.state.scbNonce}
              onObservable={this.scbObservableHandler}
              startupActionDelay={0}
            />
          </SignContextBoardContainer>
        )}
      </MainView>
    );
  }
}

SignManageContainerInternal.propTypes = {
  history: PropTypes.object.isRequired,
  isDCWeb: PropTypes.bool, // determines if the sign manage dropin is used inside DC Web app (default: false)
  showTopBar: PropTypes.bool, // show topBar based on this flag
  useIcon: PropTypes.bool, // show icon column in table (default: false)
  useSkeleton: PropTypes.bool, // use skeleton data on data reload (default: false)
  facetCounts: PropTypes.object, // object speciyfing facet counts for all agreement statuses (default: null)
  rowHeight: PropTypes.number, // determines row height of table (default: undefined)
  showSideNav: PropTypes.bool, // determines whether to show Side Navigation,
  handleCellDoubleClick: PropTypes.bool, // handle table cell double click
  useQuietTableVariant: PropTypes.bool, // Whether to use the spectrum quiet variant of Table View
  showThumbnailInContextBoard: PropTypes.bool, // Whether to show the thumbnail in the context board
  showContextActions: PropTypes.bool, // Whether to show actions in table rows,
  filterButtonInTableHeader: PropTypes.bool, // Whether to show filter button in table header
  filterPopoverPlacement: PropTypes.string, // Placement of filter popover
  allowMultiSelect: PropTypes.bool, // allow multiple selection in table
  showCallToActionInHeader: PropTypes.bool, // Whether to show the calltoaction buttons in the table header
  showCallToAction: PropTypes.bool, // Whether to show any of the call to action buttons
  showFieldFilter: PropTypes.bool, // Whether to show the queryable field filter dropdown
  useInlineActions: PropTypes.bool, // Whether to show inline or context actions
  showDetailsButtonInTable: PropTypes.bool, // Whether to show "Show details" button in the table
  showDraftAgreements: PropTypes.bool // Whether to show draft agreements in the agreement table
};

SignManageContainerInternal.defaultProps = {
  isDCWeb: false,
  showTopBar: true,
  useIcon: false,
  useSkeleton: false,
  facetCounts: null,
  rowHeight: undefined,
  showSideNav: true,
  handleCellDoubleClick: false,
  useQuietTableVariant: true,
  allowMultiSelect: false,
  showThumbnailInContextBoard: false,
  showContextActions: false,
  filterButtonInTableHeader: false,
  filterPopoverPlacement: 'bottom left',
  showCallToActionInHeader: false,
  showCallToAction: true,
  showFieldFilter: false,
  useInlineActions: false,
  showDetailsButtonInTable: false,
  showDraftAgreements: true
};

const SignManageContainer = withRouter(
  withErrorBoundary(WithToastMessage(withWindowDimensions(SignManageContainerInternal))) // eslint-disable-line new-cap
);

export { SignManageContainerInternal };

// export the component with the localizations injected
export default SignManageContainer;
