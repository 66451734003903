import React from 'react';
import styled from 'styled-components';

const DrawerNavContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  display: block;
`;

const Menu = styled.div`
  position: absolute;
  background: #fbfbfb;
  bottom: 0;
  top: 0;
`;

const UnderlayButton = styled.button`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border: none;
  width: 100%;
  height: 100%;
  min-height: 378px;
`;

class DrawerNav extends React.Component {
  render() {
    const { close, children } = this.props;
    return (
      <DrawerNavContainer>
        <UnderlayButton tabIndex={-1} onClick={close} />
        <Menu>{children}</Menu>
      </DrawerNavContainer>
    );
  }
}

export default DrawerNav;
