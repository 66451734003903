/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import EmptySearchIcon from 'dc-icons/organizer/emptystateillustrations/S_IlluNoSearchResults_180x160.svg';
import MaintenanceIcon from 'dc-icons/organizer/emptystateillustrations/S_IlluError_136x136.svg';

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 84px;
  text-align: center;
`;

const Title = styled.p`
  font-size: 30px;
  font-weight: lighter;
  margin: 0;
`;

const Body = styled.p`
  margin: 0;
`;

const ErrorState = ({ intl, showMaintenanceInfo }) => {
  const title = showMaintenanceInfo
    ? intl.formatMessage({ id: 'search.maintenance.title' })
    : intl.formatMessage({ id: 'error_result_for_search.text' });
  const body = showMaintenanceInfo
    ? intl.formatMessage({ id: 'search.maintenance.error' })
    : intl.formatMessage({ id: 'search.communication.error' });

  return (
    <MainContainer>
      {showMaintenanceInfo ? <MaintenanceIcon /> : <EmptySearchIcon />}
      <Title>{title}</Title>
      <Body>{body}</Body>
    </MainContainer>
  );
};

ErrorState.propTypes = {
  showMaintenanceInfo: PropTypes.bool
};

export default injectIntl(ErrorState);
