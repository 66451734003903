/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import stores from '../stores';
import { providers, auth2 as auth } from 'dc-core';
import log from './logger';
const Env = stores.Env;

/**
 * Wrap the isJpTagErrlEnabled function in a promise to avoid multiple calls, cache the result and return the promise to the caller
 */
let _isJpTagErrlEnabledPromise;
// Is this DC Web, are they Acrobat Pro or Acrobat Standard, is the user country code JP and the 'dc-web-jp-tag' feature flag enabled
export const isJpTagErrlEnabled = (force = false) => {
  if (!_isJpTagErrlEnabledPromise || force) {
    _isJpTagErrlEnabledPromise = new Promise((resolve, reject) =>
      _isJpTagErrlEnabled()
        .then(resolve)
        .catch(reject)
    );
  }
  return _isJpTagErrlEnabledPromise;
};

const _isJpTagErrlEnabled = async () => {
  // Ensure we are even able to try and check for the user
  if (!Env.isDCWeb) {
    return false;
  }
  // And the flag is enabled
  if (!stores.Floodgate.hasJpTagEnabled()) {
    return false;
  }

  // Get acrobat plus or acrobat std limits following the patterns in dc-core UserAPI.js
  const userProvider = await providers.user();
  const acrobatLimits = await userProvider.getLimitsAcrobat();
  const isAcrobatPlusOrStd = acrobatLimits.acrobat_pro === true || acrobatLimits.acrobat_std === true;
  if (!isAcrobatPlusOrStd) {
    return false;
  }

  // And the  user is in Japan
  try {
    const userProfile = auth.getUserProfile();
    return userProfile.countryCode === 'JP';
  } catch (err) {
    log.info('isJpTagErrlEnabled:auth.getUserProfile() exception', err);
  }
  // Or has a japan locale in sign profile
  const userLocale = stores?.User?.user?.get('locale');
  return userLocale === 'ja_JP';
};

export const AMOUNT_MAX = 999999999999.999;
export const AMOUNT_MIN = 0;
export const COMPANY_MAX_LENGTH = 254;

export const validAmount = (min, max, value) => {
  let valid = true;
  max = Number(max) || AMOUNT_MAX;
  min = Number(min) || AMOUNT_MIN;
  if (value && isNaN(value)) {
    return false;
  }
  if (!isNaN(min)) {
    valid &&= min >= AMOUNT_MIN;
  }
  if (!isNaN(max)) {
    valid &&= max <= AMOUNT_MAX;
  }
  if (!isNaN(min) && !isNaN(max)) {
    valid &&= min <= max;
  }
  if (!isNaN(value)) {
    valid &&= value >= min && value <= max;
  }
  return valid;
};

const validDatePattern = /^\d{4}-\d{2}-\d{2}$/;

export const validDate = (start, end) => {
  let startDate;
  let endDate;
  if (start) {
    if (!validDatePattern.test(start.split('T')[0])) {
      return false;
    }
    startDate = new Date(start);
    if (isNaN(startDate)) {
      return false;
    }
  }
  if (end) {
    if (!validDatePattern.test(end.split('T')[0])) {
      return false;
    }
    endDate = new Date(end);
    if (isNaN(endDate)) {
      return false;
    }
  }
  if (startDate && endDate) {
    return startDate < endDate;
  }
  return true;
};

export const validCurrency = currency => {
  if (currency) {
    return currency.length === 3;
  }
  return true;
};

export const validCompany = company => {
  if (company) {
    return company.length <= COMPANY_MAX_LENGTH;
  }
  return true;
};

// Mapping supported locales to currency selection
// https://wiki.corp.adobe.com/display/DEX/Acrobat+Web+Localization
// https://wiki.corp.adobe.com/display/ES/JP+Tagging+UI+for+Documents#JPTaggingUIforDocuments-SupportedCurrencies:
export const currencyOptionsArray = [
  'BRL',
  'CNY',
  'DKK',
  'EUR',
  'GBP',
  'HUF',
  'IDR',
  'ISK',
  'JPY',
  'KRW',
  'MYR',
  'NOK',
  'PLN',
  'RON',
  'RUB',
  'SEK',
  'THB',
  'TRY',
  'TWD',
  'UAH',
  'USD',
  'VND'
];
export const defaultLocaleMap = {
  'ca-ES': 'EUR',
  'cs-CZ': 'EUR',
  'da-DK': 'DKK',
  'de-DE': 'EUR',
  'en-GB': 'GBP',
  'en-US': 'USD',
  'es-ES': 'EUR',
  'eu-ES': 'EUR',
  'fi-FI': 'EUR',
  'fr-FR': 'EUR',
  'hr-HR': 'EUR',
  'hu-HU': 'HUF',
  'id-ID': 'IDR',
  'in-ID': 'IDR',
  'is-IS': 'ISK',
  'it-IT': 'EUR',
  'ja-JP': 'JPY',
  'ko-KR': 'KRW',
  'ms-MY': 'MYR',
  'nb-NO': 'NOK',
  'nl-NL': 'EUR',
  'nn-NO': 'NOK',
  'no-NO': 'NOK',
  'pl-PL': 'PLN',
  'pt-BR': 'BRL',
  'pt-PT': 'EUR',
  'ro-RO': 'RON',
  'ru-RU': 'RUB',
  'sk-SK': 'EUR',
  'sl-SI': 'EUR',
  'sv-SE': 'SEK',
  'th-TH': 'THB',
  'tr-TR': 'TRY',
  'uk-UA': 'UAH',
  'vi-VN': 'VND',
  'zh-CN': 'CNY',
  'zh-TW': 'TWD'
};
