/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';
import Button from '@react/react-spectrum/Button';
import Alert from '@react/react-spectrum/Icon/Alert';
import styled from 'styled-components';
import ProtectedDoc from 'dc-icons/Sign-DesignAssets/manage/SDC_ProtectwithPassword_18_N.svg';

import TextWithInfoCell from './TextWithInfoCell';
import FilterHelper from '../../../utils/FilterHelper';
import { isFutureDate } from '../../../utils/DateUtil';
import { sanitizeDate } from '../../../utils/StringUtil';
import InlineActions from '../../Button/InlineActions';

export const AlertIcon = styled(Alert)`
  &&& {
    color: rgb(227, 72, 80); /*  --spectrum-semantic-negative-color-icon */
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  .hover-button {
    display: none;
  }
  .pinned-button + .hover-button {
    display: flex;
    visibility: hidden;
  }
  .title-info {
    flex-grow: 1;
  }
`;

export const InlineActionsContainer = styled.div`
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
`;

export const ProtectedDocContainer = styled.span`
  margin-top: 2px;
  padding-left: 3px;
  padding-right: 3px;
`;

const TitleTableCell = ({ title, error, type, sharedBy, endDate, actions, isProtected, intl }) => {
  const typeInfo = type ? intl.formatMessage({ id: `tablecell.${FilterHelper.findGroupNameBy(type)}.name` }) : null;
  const sharedByInfo = sharedBy ? intl.formatMessage({ id: 'tablecell.sharedBy' }, { name: sharedBy }) : null;
  const beta = intl.formatMessage({ id: 'end_date.beta' });
  const endDateInfo =
    endDate &&
    (isFutureDate(endDate)
      ? intl.formatMessage(
          { id: 'tablecell.endDate.future' },
          { date: sanitizeDate(intl.formatDate(endDate)), beta: beta }
        )
      : intl.formatMessage(
          { id: 'tablecell.endDate.past' },
          { date: sanitizeDate(intl.formatDate(endDate)), beta: beta }
        ));
  const info =
    typeInfo && sharedByInfo
      ? intl.formatMessage({ id: 'tablecell.type.sharedBy' }, { type: typeInfo, name: sharedBy })
      : null;

  return (
    <TitleContainer>
      <TextWithInfoCell
        className="title-table-cell"
        text={title}
        info={info || typeInfo || sharedByInfo || endDateInfo}
        placement="middle"
      >
        {error ? (
          <OverlayTrigger placement="top" trigger="hover">
            <Button variant="tool" icon={<AlertIcon size="S" />} aria-label={error} />
            <Tooltip variant="error">{error}</Tooltip>
          </OverlayTrigger>
        ) : null}
        {!!actions.length && isProtected && (
          <OverlayTrigger placement="top" trigger="hover">
            <ProtectedDocContainer aria-label={intl.formatMessage({ id: 'title.protected_document' })}>
              <ProtectedDoc />
            </ProtectedDocContainer>
            <Tooltip>{intl.formatMessage({ id: 'title.protected_document' })}</Tooltip>
          </OverlayTrigger>
        )}
      </TextWithInfoCell>
      {!!actions.length && (
        <InlineActionsContainer>
          <InlineActions actions={actions} className="inline-actions" />
        </InlineActionsContainer>
      )}
    </TitleContainer>
  );
};

TitleTableCell.propTypes = {
  title: PropTypes.string.isRequired,
  error: PropTypes.string,
  type: PropTypes.string,
  sharedBy: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.string),
  intl: PropTypes.shape.isRequired
};

TitleTableCell.defaultProps = {
  actions: []
};

// export the component with the localizations injected
export default injectIntl(TitleTableCell);
