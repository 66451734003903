const FETCH_SETTINGS = ['unresolvedBounceIndicatorFeatureAvailable'];

/**
 * Get UnresolvedBounceIndicatorFeature setting value for the group
 */
export default class UnresolvedBounceIndicatorFeatureForGroup {
  constructor(Api) {
    this.cachedSettingValues = {};
    // API Group object for fetching setting value
    this.Group = Api.Users.Group;
  }

  /**
   * Get unresolvedBounceIndicatorFeatureAvailable setting for the group
   * @param {string} userId String representation of the User ID
   * @param {string} groupId String representation of the Group ID
   * @returns {boolean|undefined} The unresolvedBounceIndicatorFeatureAvailable setting value
   *  or undefined for setting value is not available on endpoint response
   */
  async get(userId, groupId) {
    return await this.getSettingByGroup(groupId, userId);
  }

  /**
   * Get the unresolvedBounceIndicatorFeatureAvailable setting value for shared group from cache or fetch setting value
   * @param {string} groupId String representation of the Group ID
   * @param {string} userId String representation of the User ID
   * @returns {boolean|undefined} The unresolvedBounceIndicatorFeatureAvailable setting value or undefined othervise
   */
  async getSettingByGroup(groupId, userId) {
    if (this.cachedSettingValues.hasOwnProperty(groupId)) {
      return this.cachedSettingValues[groupId];
    }
    let settingValue = await this.fetchByGroup(groupId, userId);
    settingValue = typeof settingValue === 'boolean' ? settingValue : undefined;
    this.cachedSettingValues[groupId] = settingValue;
    return settingValue;
  }

  /**
   * Fetch the unresolvedBounceIndicatorFeatureAvailable setting value for shared group
   * @param {string} groupId String representation of the Group ID
   * @param {string} userId String representation of the User ID
   * @returns {boolean|undefined} The unresolvedBounceIndicatorFeatureAvailable setting value or undefined othervise
   */
  async fetchByGroup(groupId, userId) {
    const onBehalfOfUser = { 'x-on-behalf-of-user': `userid:${userId}` };
    const response = await new this.Group({ id: groupId }, { userId: 'me' }).search.save(
      { settings: FETCH_SETTINGS },
      { headers: onBehalfOfUser }
    );
    const foundSetting = response.settings.find(x => x.name === 'unresolvedBounceIndicatorFeatureAvailable');
    return foundSetting ? foundSetting.value : undefined;
  }
}
