/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { injectIntl } from 'react-intl';
import Select from '@react/react-spectrum/Select';
import { media } from '../../utils/mediaQuery';
import { analyticsFor } from '../../utils/analytics';
import bindAndCatchAndThrow from '../../utils/bindAndCatchAndThrow';

const MainContainer = styled.div`
  & .spectrum-FieldButton {
    background-color: white;
  }
`;

const StyledSelect = styled(Select)`
  width: 100% !important;
`;

const MenuStyle = createGlobalStyle`
  @media ${media.zoom400Percent} {
    .field-filter-menu { max-height: 31px; }
  }
`;

class QueryableField extends React.Component {
  @bindAndCatchAndThrow
  selectOnChange(newValue) {
    const { onChange, sendAnalytics } = this.props;
    sendAnalytics(analyticsFor.QUERYABLE_FIELD_SELECTED, { queryableField: newValue });
    onChange(newValue);
  }

  render() {
    const { intl } = this.props;

    const selectOptions = [
      { label: intl.formatMessage({ id: 'filter.field.all' }), value: 'all' },
      { label: intl.formatMessage({ id: 'filter.field.title' }), value: 'title' },
      { label: intl.formatMessage({ id: 'filter.field.note' }), value: 'note' },
      { label: intl.formatMessage({ id: 'filter.field.message' }), value: 'message' },
      { label: intl.formatMessage({ id: 'filter.field.filename' }), value: 'originalFileName' },
      { label: intl.formatMessage({ id: 'filter.field.email' }), value: 'email' },
      { label: intl.formatMessage({ id: 'filter.field.fullName' }), value: 'fullName' },
      { label: intl.formatMessage({ id: 'filter.field.jobTitle' }), value: 'jobTitle' },
      { label: intl.formatMessage({ id: 'filter.field.companyName' }), value: 'companyName' },
      { label: intl.formatMessage({ id: 'filter.field.groupName' }), value: 'recipientGroupName' },
      { label: intl.formatMessage({ id: 'filter.field.text' }), value: 'textFieldContent' }
    ];

    return (
      <MainContainer>
        <MenuStyle />
        <StyledSelect
          className="field-filter"
          menuClassName="field-filter-menu"
          onChange={this.selectOnChange}
          options={selectOptions}
          value={this.props.value}
        />
      </MainContainer>
    );
  }
}

QueryableField.defaultProps = {
  value: 'all'
};

export default injectIntl(QueryableField);
