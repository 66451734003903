import React, { useState, useEffect } from 'react';
import { providers } from 'dc-core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dialog from '@react/react-spectrum/Dialog';
import Button from '@react/react-spectrum/Button';
import GoogleCalendarIcon from 'dc-icons/Sign/calendar_google_icon.svg';
import AppleCalendarIcon from 'dc-icons/Sign/calendar_apple_icon.svg';
import OutlookCalendarIcon from 'dc-icons/Sign/calendar_outlook_icon.svg';
import { localizeComponent } from '../../utils/DropinComponentLocalizer';
import { analyticsFor } from '../../utils/analytics';
import moment from 'moment';
import RoutingUtil from '../../utils/RoutingUtil';
import { sanitizeDate } from '../../utils/StringUtil';

const CalendarDialog = styled(Dialog)`
  .spectrum-Dialog-content {
    flex-direction: column;
    display: flex;
  }
`;

const CalendarButton = styled(Button)`
  margin-top: 16px;
  margin-left: 0 !important;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 14px;
  svg {
    height: 20px;
  }
`;

const ButtonContainer = styled.div`
  width: 60%;
  align-self: center;
  margin-top: 16px;
`;

const ButtonLabel = styled.div`
  padding-left: 10px;
`;

const analytics = analyticsFor(analyticsFor.END_DATE_CALENDAR_ACTION);
const DISMISSED = 'dismissed';
const GOOGLE = 'google';
const OUTLOOK = 'outlook';
const APPLE = 'apple';

const AGREEMENT = 'AGREEMENT';
const ESIGN = 'ESIGN';

const EndDateCalendarIntegrationBase = props => {
  const {
    intl,
    onClose,
    agreementId,
    agreementName,
    agreementType,
    agreementEndDateConfirmed,
    agreementEndDate
  } = props;

  const [appleCalendarShow, setAppleCalendarShow] = useState(true);

  const formatStartTime = date => moment(date).format('YYYYMMDD');

  const formatOutlookStartTime = date => moment(date).format('YYYY-MM-DD');

  const formatEndTime = date =>
    moment(date)
      .add(1, 'days')
      .format('YYYYMMDD');

  const getAgreementUrl = () => {
    const agrType = agreementType === AGREEMENT ? ESIGN : agreementType;
    const type = agrType ? agrType.toLowerCase() : 'esign';
    const url = `/public/agreements/view/${agreementId}?app=dc&type=${type}`;
    return `${window.location.origin}${RoutingUtil.transformPathForDCWeb(url)}`;
  };

  const title = intl.formatMessage(
    { id: 'call_to_action.end_date.calendar.subject' },
    { name: agreementName, enddate: sanitizeDate(intl.formatDate(agreementEndDate)) }
  );

  const body = intl.formatMessage(
    { id: 'call_to_action.end_date.calendar.message' },
    {
      br: '<br>',
      name: agreementName,
      enddate: sanitizeDate(intl.formatDate(agreementEndDate)),
      a: chunks => `<a href="${getAgreementUrl()}">${chunks}</a>`
    }
  );

  const icsBody = intl.formatMessage(
    { id: 'call_to_action.end_date.calendar.message.ics' },
    {
      br: '<br>',
      name: agreementName,
      url: getAgreementUrl(),
      enddate: sanitizeDate(intl.formatDate(agreementEndDate))
    }
  );

  const onCloseDialog = () => {
    analytics.setContext({ action: DISMISSED, endDateConfirmed: agreementEndDateConfirmed });
    analytics.send();
    onClose();
  };

  const onClickGoogleEvent = () => {
    analytics.setContext({ action: GOOGLE, endDateConfirmed: agreementEndDateConfirmed });
    analytics.send();

    let endDateReminder = 'https://calendar.google.com/calendar/render?action=TEMPLATE';
    endDateReminder += `&dates=${formatStartTime(agreementEndDate)}/${formatEndTime(agreementEndDate)}`;
    endDateReminder += `&text=${encodeURIComponent(title)}`;
    endDateReminder += `&details=${encodeURIComponent(body)}`;
    window.open(endDateReminder, '_blank');

    onClose();
  };

  const onClickOutlookEvent = () => {
    analytics.setContext({ action: OUTLOOK, endDateConfirmed: agreementEndDateConfirmed });
    analytics.send();

    let endDateReminder = 'https://outlook.office.com/?&path=/calendar/action/compose&allday=true';
    endDateReminder += `&startdt=${formatOutlookStartTime(agreementEndDate)}`;
    endDateReminder += `&subject=${encodeURIComponent(title)}`;
    endDateReminder += `&body=${encodeURIComponent(body)}`;
    window.open(endDateReminder, '_blank');

    onClose();
  };

  const onClickAppleEvent = () => {
    analytics.setContext({ action: APPLE, endDateConfirmed: agreementEndDateConfirmed });
    analytics.send();

    const endDateReminder = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `DTSTART:${formatStartTime(agreementEndDate)}`,
      `DTEND:${formatEndTime(agreementEndDate)}`,
      `SUMMARY:${title}`,
      `DESCRIPTION:${encodeURIComponent(icsBody.replace(/<br>/g, '\\n'))}`,
      'END:VEVENT',
      'END:VCALENDAR'
    ].join('\n');

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + endDateReminder);
    element.setAttribute('download', agreementName + '.ics');
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    onClose();
  };

  useEffect(() => {
    const fetchOS = async () => {
      const deviceApi = await providers.device();
      const os = deviceApi.userAgent.getOS();
      setAppleCalendarShow(['Mac OS', 'iOS'].includes(os?.name));
    };
    // ignore errors
    fetchOS()
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <CalendarDialog
      backdropClickable={true}
      container={window.document.body}
      onClose={onCloseDialog}
      isDismissible
      title={intl.formatMessage({ id: 'call_to_action.end_date.calendar.title' })}
    >
      {intl.formatMessage({ id: 'call_to_action.end_date.calendar.body' })}
      <ButtonContainer>
        <CalendarButton
          variant="action"
          aria-label={intl.formatMessage({ id: 'call_to_action.end_date.calendar.google' })}
          onClick={onClickGoogleEvent}
        >
          <GoogleCalendarIcon />
          <ButtonLabel>{intl.formatMessage({ id: 'call_to_action.end_date.calendar.google' })}</ButtonLabel>
        </CalendarButton>
        {appleCalendarShow && (
          <CalendarButton
            variant="action"
            aria-label={intl.formatMessage({ id: 'all_to_action.end_date.calendar.apple' })}
            onClick={onClickAppleEvent}
          >
            <AppleCalendarIcon />
            <ButtonLabel>{intl.formatMessage({ id: 'call_to_action.end_date.calendar.apple' })}</ButtonLabel>
          </CalendarButton>
        )}
        <CalendarButton
          variant="action"
          aria-label={intl.formatMessage({ id: 'call_to_action.end_date.calendar.microsoft' })}
          onClick={onClickOutlookEvent}
        >
          <OutlookCalendarIcon />
          <ButtonLabel>{intl.formatMessage({ id: 'call_to_action.end_date.calendar.microsoft' })}</ButtonLabel>
        </CalendarButton>
      </ButtonContainer>
    </CalendarDialog>
  );
};

EndDateCalendarIntegrationBase.propTypes = {
  intl: PropTypes.shape.isRequired,
  agreementId: PropTypes.string.isRequired,
  agreementName: PropTypes.string.isRequired,
  agreementType: PropTypes.string,
  agreementEndDateConfirmed: PropTypes.string.isRequired,
  agreementEndDate: PropTypes.string.isRequired,
  Api: PropTypes.objectOf(PropTypes.any).isRequired
};

export { EndDateCalendarIntegrationBase };

const EndDateCalendarIntegrationDropin = localizeComponent(EndDateCalendarIntegrationBase);
export default EndDateCalendarIntegrationDropin;
