/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import { IntlProvider, injectIntl } from 'react-intl';
import { locale2 } from 'dc-core';
import styled from 'styled-components';
import { media } from '../../utils/mediaQuery';
import Calendar from '@react/react-spectrum/Calendar';
import Dialog from '@react/react-spectrum/Dialog';
import bindAndCatchAndThrow from '../../utils/bindAndCatchAndThrow';
import { getMessages } from '../../utils/MessageUtils';

/**
 * https://jira.corp.adobe.com/browse/DCES-4255423
 * Work-around for dialog layout issue in Safari, related to 'width: fit-content' style.
 */
const StyledDialog = styled(Dialog)`
  width: initial;
    @media ${media.zoom400Percent} {
    &.spectrum-Dialog {
      padding: 15px;
      .spectrum-Dialog-header {
        padding-bottom: 19px;
      }
      .spectrum-Dialog-footer {
      padding-top: 5px;
      }
    }
`;

@injectIntl
class CalendarDialog extends React.Component {
  constructor(props, context) {
    let { startDate, endDate } = props;
    super(props, context);

    if (startDate && endDate) {
      startDate = startDate instanceof Date ? startDate : new Date(startDate);
      endDate = endDate instanceof Date ? endDate : new Date(endDate);
      this.state = { value: [startDate, endDate] };
    } else {
      this.state = {};
    }
  }

  get confirmDisabled() {
    return (
      !this.state.value || this.state.value.length !== 2 || !this.state.value[0].format || !this.state.value[1].format
    );
  }

  @bindAndCatchAndThrow
  handleConfirm(action) {
    if (this.state.value.length === 2 && this.state.value[0].format) {
      this.state.value[1].add(1, 'days').subtract('1', 'second');
      this.props.onChange({
        startDate: `${this.state.value[0].toISOString()}`,
        endDate: `${this.state.value[1].toISOString()}`
      });
    }
  }

  @bindAndCatchAndThrow
  handleDateRangeChange({ start, end }) {
    this.setState({ value: [start, end] });
  }

  render() {
    const { intl, onClose } = this.props;
    const { value } = this.state;

    return (
      <StyledDialog
        title={intl.formatMessage({ id: 'daterange.custom' })}
        confirmLabel={intl.formatMessage({ id: 'dialog.apply' })}
        cancelLabel={intl.formatMessage({ id: 'dialog.cancel' })}
        onConfirm={this.handleConfirm}
        onClose={onClose}
        confirmDisabled={this.confirmDisabled}
        role={'document'}
      >
        <Calendar onChange={this.handleDateRangeChange} selectionType="range" value={value} max={new Date()} />
      </StyledDialog>
    );
  }
}

const LocalizableCalendarDialog = props => (
  <IntlProvider messages={props.messages} locale={props.locale} defaultLocale="en">
    <CalendarDialog {...props} />
  </IntlProvider>
);

export { CalendarDialog as TestableCalendarDialog };

export default locale2.withTranslations(LocalizableCalendarDialog, getMessages);
