import logger from '../utils/logger';

/**
 * Group Information: /groups
 */
export default class Groups {
  constructor(Api) {
    this.Api = Api;
    this.groupList = new Api.Groups.GroupsList();
    this.promise = null;
  }

  doFetch(cursor) {
    const data = {};
    if (cursor) {
      data.cursor = cursor;
    }
    return this.groupList.fetch({
      headers: {
        // The /groups endpoint does not accept the x-on-behalf-of-user header.
        // Current usage of this store is to fetch the groups in the signed-in user's
        // account so we just make sure the header doesn't include the sharer's id.
        'x-on-behalf-of-user': ''
      },
      data,
      remove: false
    });
  }

  async fetchGroups() {
    do {
      await this.doFetch(this.groupList.page.nextCursor).catch(e => {
        logger.warn('Unable to get group list', { error: e });
        // break the loop if we are unable to fetch the current page
        this.groupList.page.nextCursor = null;
      });
    } while (!!this.groupList.page.nextCursor);
  }

  fetch() {
    this.promise = this.fetchGroups().then(() => {
      this.groupNames = this.groupList.reduce((groups, group) => {
        const groupId = group.get('groupId');
        groups[groupId] = group.get('groupName');
        return groups;
      }, {});
    });
  }

  /**
   * Get the group name by id.
   * @returns Group name, undefined if the group name is not found.
   */
  getGroupName(byId) {
    return this.groupNames && this.groupNames[byId];
  }

  ready() {
    return this.promise;
  }
}
