import FilterHelper from './FilterHelper';
import { analyticsFor } from './analytics';
import stores from '../stores';
import AgreementSearchAPI from '../service/AgreementSearchAPI';
import { SEARCH_ERROR_TYPES } from '../component/EmptyState/SearchError';

class SearchUtil {
  constructor(manageContainer) {
    this.manageContainer = manageContainer;
    this.agreementSearchAPI = new AgreementSearchAPI();
    this.dataSource.setLoadFunction(this.onDataSourceLoad.bind(this));
    this.searchResponseIndex = 1; // running count of the number of search results the user has received
  }

  get state() {
    return this.manageContainer.state;
  }

  get dataSource() {
    return this.manageContainer.dataSource;
  }

  get facetCounts() {
    return this.manageContainer.props.facetCounts;
  }

  get useSkeleton() {
    return this.manageContainer.props.useSkeleton;
  }

  get useSearchOrFilter() {
    const useSearchTerm = this.state.searchTerm !== '*';
    const useDateFilter = !!this.state.startDate && !!this.state.endDate;
    const useGroupFilter = !!this.state.groupId;
    const useVisibilityFilter = this.state.visibility && this.state.visibility !== 'SHOW_VISIBLE';
    return useSearchTerm || useDateFilter || useGroupFilter || useVisibilityFilter;
  }

  onDataSourceLoad(page, pageLimit, sortBy, sortDirection) {
    if (this.useSkeleton && this.activeContentLoadPromise) {
      const promise = this.activeContentLoadPromise;
      this.activeContentLoadPromise = null;
      return promise;
    } else {
      // Track the latest active content load promise.
      this.activeContentLoadPromise = this.doContentSearch(page, pageLimit, sortBy, sortDirection);
      return this.activeContentLoadPromise.then(
        result => {
          if (result.isActiveQuery) {
            if (this.state.parentId && !this.state.parentTitle && result.items.length > 0) {
              this.setParentAgreementState(result.items[0]);
            }
            if (this.useSkeleton && page === 0 && result.items && result.items.length > 0) {
              this.dataSource.setShowSkeleton(false);
              this.dataSource.reloadData();
            } else {
              this.activeContentLoadPromise = null;
            }

            this.manageContainer.sendAnalytics(analyticsFor.AGREEMENT_SEARCH_RESULT, {
              page,
              total_hits: result.metrics.total_hits,
              duration: result.duration,
              searchResponseIndex: result.searchResponseIndex
            });
          }
          return result;
        },
        error => {
          this.activeContentLoadPromise = null;
          throw error;
        }
      );
    }
  }

  setState(state, then) {
    this.manageContainer.setState(state, then);
  }

  setParentAgreementState(agreement) {
    this.setState({
      parentTitle: agreement.name,
      parentOwnerId: agreement.user_id
    });
  }

  getModifyDateFilter() {
    const filterField = this.state.agreementType === 'template' ? 'modify_date' : 'last_transaction_date';
    return this.state.startDate && this.state.endDate
      ? { [filterField]: { range: { gt: this.state.startDate, lt: this.state.endDate } } }
      : {};
  }

  getQueryableFieldsFilter() {
    return this.state.queryableField && this.state.queryableField !== 'all'
      ? { queryable_fields: [this.state.queryableField] }
      : null;
  }

  getDraftAgreementFilter() {
    return !this.manageContainer.props.showDraftAgreements && this.state.agreementState !== 'legacy_draft'
      ? { exclude_agreements_waiting_for_authoring: true }
      : null;
  }

  getDeletedAgreementFilter(state) {
    return this.state.canUserSeeDeletedFolder && state === 'deleted'
      ? { is_soft_deleted: true, roles: ['ORIGINATOR'] }
      : null;
  }

  getGroupFilter() {
    return this.state.groupId ? { group_id_set: this.state.groupId } : {};
  }

  getAccountShareFilter() {
    const setFilterSharerId = (shareId, filterProp) => {
      const sharerId = stores.UserShares.getSharerId(shareId);
      if (sharerId) filters[filterProp] = sharerId;
    };

    // Application state references the share identifier. For the agreement
    // search query we need to convert that into the sharer group/user.
    let filters = {};
    if (this.state.groupShare) {
      setFilterSharerId(this.state.groupShare, 'group_id_set');
    } else if (this.state.userShare) {
      setFilterSharerId(this.state.userShare, 'user_id');
    }
    return filters;
  }

  getFetchFields() {
    const fetchFields = [
      'is_password_protected',
      'active_reminder_count',
      'completed_reminder_count',
      'note',
      'last_transaction_date',
      'is_soft_deleted',
      'has_unresolved_bounce'
    ];

    // share_with_ids is required to determine group info for template.
    // Adding shared_with_ids to fetchFields during filtering for template of all types
    if (['all', 'template'].includes(this.state.agreementType)) fetchFields.push('shared_with_ids');
    return fetchFields;
  }

  getCommonQueryFilters() {
    return Object.assign(
      { visibility: this.state.visibility },
      this.getModifyDateFilter(),
      this.getGroupFilter(),
      this.getAccountShareFilter(),
      this.getQueryableFieldsFilter(),
      this.getDraftAgreementFilter()
    );
  }

  logSearchError(response) {
    this.manageContainer.sendAnalytics(analyticsFor.ERROR, {
      errorMessage: response.error,
      requestId: response.request_id
    });
  }

  captureSearchError(error, query, token, tokenPos, message) {
    const emptyResult = {
      result_sets: [
        {
          facets: [
            { display_name: 'Types', facet_field: 'agreement_type', facet_values: [] },
            { display_name: 'States', facet_field: 'state', facet_values: [] },
            { display_name: 'Groups', facet_field: 'group_id_set', facet_values: [] }
          ]
        }
      ]
    };
    const flattenedFacets = this.flattenedFacets(emptyResult, emptyResult, emptyResult);
    this.updateState(flattenedFacets, { error, query, token, tokenPos, message });
  }

  getMetadataSearch() {
    if (
      this.state.jpTagErrlEnabled &&
      (this.state.payment_amount_min ||
        this.state.payment_amount_max ||
        this.state.payment_currency ||
        this.state.payment_date_range_type ||
        this.state.payment_start_date ||
        this.state.payment_end_date ||
        this.state.payment_company)
    ) {
      return {
        payment_amount_min: this.state.payment_amount_min,
        payment_amount_max: this.state.payment_amount_max,
        payment_currency: this.state.payment_currency,
        payment_date_range_type: this.state.payment_date_range_type,
        payment_start_date: this.state.payment_start_date,
        payment_end_date: this.state.payment_end_date,
        payment_company: this.state.payment_company
      };
    } else {
      return undefined;
    }
  }

  search(reloadDataSource) {
    // Used only for JP ERRL currently
    const metadataSearch = this.getMetadataSearch();

    this.dataSource.clear(false); // clear existing search results so we don't see the call to action flash on the screen.

    // In a drill-down view, there's no guarantee we have access to the resource name
    // via the search results. As a backup, search using a filter on the parent agreement.
    if (this.state.parentId && !this.state.parentTitle) {
      this.fetchParentAgreementForTitle();
    }

    // Perform facet queries in parallel.
    const theFilters = this.getCommonQueryFilters();

    const facetPromise = !this.facetCounts
      ? Promise.all([
          this.agreementSearchAPI.search(
            this.state.searchTerm,
            undefined,
            undefined,
            0,
            0,
            FilterHelper.transformToFilter(theFilters),
            this.state.sharedAgreements,
            undefined,
            undefined,
            metadataSearch
          ),
          this.agreementSearchAPI.search(
            this.state.searchTerm,
            undefined,
            undefined,
            0,
            0,
            FilterHelper.transformToFilter(
              Object.assign({ agreement_type: FilterHelper.filterGroups('agreement_type').agreement }, theFilters)
            ),
            this.state.sharedAgreements,
            undefined,
            undefined,
            metadataSearch
          ),
          ...(this.state.canUserSeeDeletedFolder
            ? [
                this.agreementSearchAPI.search(
                  this.state.searchTerm,
                  undefined,
                  undefined,
                  0,
                  0,
                  FilterHelper.transformToFilter(
                    Object.assign(
                      { agreement_type: FilterHelper.filterGroups('agreement_type').agreement },
                      theFilters,
                      {
                        is_soft_deleted: true,
                        roles: ['ORIGINATOR']
                      }
                    )
                  ),
                  this.state.sharedAgreements,
                  undefined,
                  undefined,
                  metadataSearch
                )
              ]
            : [Promise.resolve()])
        ])
      : Promise.resolve();
    facetPromise.then(results => {
      let flattenedFacets;
      if (!this.facetCounts) {
        const [allFacetsResponse, typeFacetsResponse, typedSoftDeletedFacedResponse] = results;

        let errorResponse = results.find(response => response && response.error);
        if (errorResponse) {
          this.logSearchError(errorResponse);
          if (errorResponse.errorCode === 'INVALID_QUERY_SYNTAX') {
            this.captureSearchError(
              SEARCH_ERROR_TYPES.INVALID_QUERY,
              this.state.searchTerm,
              errorResponse.errorToken,
              errorResponse.errorTokenPos,
              errorResponse.errorMessage
            );
          } else if (errorResponse.errorCode === 'TIMED_OUT') {
            this.captureSearchError(SEARCH_ERROR_TYPES.TIMED_OUT);
          } else {
            this.setState({ error: true, searchError: undefined });
          }
          return;
        }

        if (this.state.searchTerm !== '*' && !this.searchRequestTrackingId) {
          this.searchRequestTrackingId = allFacetsResponse.request_id;
        }

        flattenedFacets = this.flattenedFacets(typeFacetsResponse, allFacetsResponse, typedSoftDeletedFacedResponse);
      } else {
        flattenedFacets = this.facetCounts;
      }

      this.updateState(flattenedFacets, undefined, () => {
        // TODO - understand why the useSkeleton check is required here
        if (reloadDataSource || this.useSkeleton) {
          // Clear activeContentLoadPromise prior to triggering the reload. When
          // displaying skeleton data we don't want to return the current search
          // query promise when the load function is called.
          this.activeContentLoadPromise = null;
          this.dataSource.reloadData();
        }
      });
    });

    // Clear the focus :-(
    if (this.dataSource.collection) {
      this.dataSource.collection._focusedIndexPath = undefined;
    }
  }

  updateState(flattenedFacets, errorState, then) {
    this.setState(
      {
        useSearchOrFilter: this.useSearchOrFilter,
        agreementState: this.agreementState(flattenedFacets),
        allFacets: flattenedFacets,
        facetQueryPending: false,
        searchError: errorState?.error,
        searchErrorQuery: errorState?.query,
        searchErrorToken: errorState?.token,
        searchErrorTokenPos: errorState?.tokenPos,
        searchErrorMessage: errorState?.message
      },
      then
    );
  }

  flattenedFacets(typeFacetsResponse, allFacetsResponse, typeFacetsSoftDeletedResponse) {
    let flattenedFacets = Object.assign(
      SearchUtil.extractFacetValues(typeFacetsResponse, 'state'),
      SearchUtil.extractFacetValues(allFacetsResponse, 'agreement_type', this.useSearchOrFilter),
      SearchUtil.extractFacetValues(allFacetsResponse, 'group_id_set')
    );

    // Update facets with soft deleted agreements
    if (typeFacetsSoftDeletedResponse) {
      const typedSoftDeletedFacets = SearchUtil.extractFacetValues(typeFacetsSoftDeletedResponse, 'state');
      if (typedSoftDeletedFacets && typedSoftDeletedFacets.deleted) {
        flattenedFacets.deleted = typedSoftDeletedFacets.deleted;
      }
    } else {
      flattenedFacets.deleted.count = 0;
    }

    return flattenedFacets;
  }

  agreementState(facets) {
    if (this.state.useInferredAgreementState) {
      // Drill down show all agreement case
      if (this.state.parentId) return 'all';

      if (facets.waiting_for_you.count > 0) {
        return 'waiting_for_you';
      } else {
        return 'waiting_for_others';
      }
    }
    return this.state.agreementState;
  }

  fetchParentAgreementForTitle() {
    this.agreementSearchAPI
      .search(
        '*',
        undefined,
        undefined,
        0,
        1,
        FilterHelper.transformToFilter({ agreement_id: this.state.parentId }),
        this.state.sharedAgreements
      )
      .then(response => {
        if (!response.error && response.result_sets[0].items.length > 0) {
          this.setParentAgreementState(response.result_sets[0].items[0]);
        }
      });
  }

  doContentSearch(page, pageLimit, sortBy, sortDirection) {
    const searchStartTime = Date.now();
    const promise = new Promise((resolve, reject) => {
      const { sharedAgreements, agreementType, agreementState, searchTerm, parentId, agreementEndDate } = this.state;
      const typeGroup = parentId
        ? 'agreement_child_type'
        : FilterHelper.shouldUseConsolidatedGroup(this.useSearchOrFilter, agreementType)
        ? 'agreement_consolidated_type'
        : 'agreement_type';
      const theFilters = this.getCommonQueryFilters();
      const theEndedFilters = this.getCommonQueryFilters();
      const fetchFields = this.getFetchFields();
      // Used only for JP ERRL currently
      const metadataSearch = this.getMetadataSearch();

      if (agreementType !== 'all') {
        theFilters.agreement_type = FilterHelper.filterGroups(typeGroup)[agreementType];
        if (agreementState && agreementState !== 'all') {
          theFilters.state = FilterHelper.filterGroups('state')[agreementState];
        }
      }

      // For library templates we want to continue to sort on modify_date.
      if (sortBy === 'last_transaction_date' && agreementType === 'template') {
        sortBy = 'modify_date';
      }

      if (parentId) {
        // In a drill-down view we must filter based on the parent ID. Also, we
        // ignore the current state filter when computing facet counts to allow
        // all agreement state counts to be displayed.
        theFilters.agreement_parent_id = parentId;
        theFilters.ignore_for_facets = ['state'];
      }

      let sortMode;
      let endedSearchPromise;
      if (agreementEndDate) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Filter for termination dates on or after today's date
        theFilters.termination_dates = {
          range: { gt: today.toISOString() }
        };

        // Filter termination dates before today's date
        theEndedFilters.termination_dates = {
          range: { lt: today.toISOString() }
        };

        // Filter for agreements where user is the originator
        theFilters.roles = ['ORIGINATOR'];

        // When sorting on the multi-value termination_dates field we specify the
        // sort mode to get consistent results for ascending and descending sorts.
        if (sortBy === 'termination_dates') sortMode = 'MIN';

        if (agreementEndDate !== 'all' && agreementEndDate !== 'ended') {
          theFilters.ignore_for_facets = ['termination_dates_confirmed'];
          theFilters.termination_dates_confirmed = agreementEndDate === 'confirmed';
        }

        // We need to make a separate search query for the "ended" tab in Ending Soon section
        endedSearchPromise = this.agreementSearchAPI.search(
          searchTerm,
          sortBy,
          sortDirection,
          pageLimit * page,
          agreementEndDate === 'ended' ? pageLimit : 0,
          FilterHelper.transformToFilter(theEndedFilters),
          sharedAgreements,
          fetchFields,
          sortMode
        );
      } else {
        endedSearchPromise = Promise.resolve();
      }

      const searchPromise = this.agreementSearchAPI.search(
        searchTerm,
        sortBy,
        sortDirection,
        pageLimit * page,
        pageLimit,
        FilterHelper.transformToFilter(
          Object.assign(theFilters, this.getDeletedAgreementFilter(this.state.agreementState))
        ),
        sharedAgreements,
        fetchFields,
        sortMode,
        metadataSearch
      );

      Promise.all([searchPromise, endedSearchPromise]).then(([response, endedResponse]) => {
        // In the typical case of this search API request being the active one, we can
        // resolve the promise with the content. In the event that the user initiated
        // another search before this one completed, we resolve our promise with the
        // most recent one.
        if (response.error) {
          if (promise === this.activeContentLoadPromise) {
            this.logSearchError(response);
            this.setState({ error: true });
          }
          reject();
        } else if (endedResponse && endedResponse.error) {
          if (promise === this.activeContentLoadPromise) {
            this.logSearchError(endedResponse);
            this.setState({ error: true });
          }
          reject();
        } else {
          // For drill-down and ending soon views we want to capture the facets
          // for the current query to populate the counts in the tab labels
          if ((parentId || agreementEndDate) && page === 0) {
            const facet = parentId ? 'state' : 'termination_dates_confirmed';
            const queryFacets = SearchUtil.extractFacetValues(response, facet);
            this.setState({ queryFacets });
            if (agreementEndDate && endedResponse) {
              const queryEndedFacets = SearchUtil.extractFacetValues(endedResponse, facet);
              this.setState({ queryEndedFacets });

              // replace the response with the ended response when the ended tab is selected
              if (agreementEndDate === 'ended') {
                response = endedResponse;
              }
            }
          }

          // Include flag indicating whether or not this is the active query
          resolve({
            isActiveQuery: promise === this.activeContentLoadPromise,
            items: SearchUtil.processSearchResults(response.result_sets[0].items, sharedAgreements),
            metrics: response.metrics,
            duration: Date.now() - searchStartTime,
            searchResponseIndex: this.searchResponseIndex++
          });
        }
      });
    });
    return promise;
  }

  static getFacets(response) {
    return response.result_sets[0].facets;
  }

  static getFacetCountByGroups(facet, groups) {
    const facetValues = facet.facet_values.reduce((memo, r) => {
      memo[r.display_name] = r.count || 0;
      return memo;
    }, {});

    // If no facet grouping specified, just return facet values
    const grouping = Object.keys(groups);
    return grouping.length > 0
      ? grouping.reduce((result, name) => {
          const count = groups[name].reduce((tally, group) => {
            return tally + (facetValues[group] || 0);
          }, 0);
          result[name] = { count };
          return result;
        }, {})
      : {
          [facet.facet_field]: facetValues
        };
  }

  // consolidate is a flag to show parent and child count for webform and megasign
  static extractFacetValues(response, facet, consolidate) {
    const getFacetbyField = (facets, field) => facets.find(f => f.facet_field === field);
    const facets = getFacetbyField(this.getFacets(response), facet);

    // if facet isn't present, e.g. group_id_set, return
    if (!facets) return;

    const groups = consolidate
      ? Object.assign({}, FilterHelper.filterGroups(facet), FilterHelper.filterGroups('agreement_consolidated_type'))
      : FilterHelper.filterGroups(facet);

    return this.getFacetCountByGroups(facets, groups);
  }

  static processSearchResults(results, viewingSharedAgreements) {
    // Env.sharer is present when the logged-in user has switched to another account view.
    const user = stores.Env.sharer || stores.Env.user;
    return results.map(item => {
      // The sharer property is defined for ad hoc shares. Set that same property
      // for resources shared through other mechanisms, e.g. shared templates.
      if (
        !item.sharer &&
        item.agreement_type === 'LIBRARY_TEMPLATE' &&
        !viewingSharedAgreements &&
        user.seriouslySecureId !== item.user_id
      ) {
        item.sharer = item.user_name;
        item.sharer_organization = item.user_organization;
      }
      // The search API returns agreement_type: 'WIDGET', state: 'RECALLED' for
      // disabled web forms. Within the table, we want to use the DISABLED state.
      if (item.agreement_type === 'WIDGET' && item.state === 'RECALLED') {
        item.state = 'DISABLED';
      }

      // To simplify rendering, sort termination dates in ascending order
      if (item.termination_dates) {
        item.termination_dates.sort((d1, d2) => new Date(d1) - new Date(d2));
      }

      return item;
    });
  }
}

export default SearchUtil;
