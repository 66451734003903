const size = {
  small: '550px',
  medium: '768px',
  zoom: '300px'
};

export const isMedia = mediaQuery => window.matchMedia(mediaQuery).matches;

// Usage in CSS: @media ${media.phone} { ... }
export const media = {
  phone: `(max-width: ${size.small})`,
  tablet: `(max-width: ${size.medium})`,
  zoom400Percent: `(max-height: ${size.zoom})`
};
