import React from 'react';

const throttleLength = 1000 / 15; // 15 fps

const withWindowDimensions = WrappedComponent =>
  class WindowDimensions extends React.Component {
    state = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensionsWithThrottle);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensionsWithThrottle);
    }

    updateDimensionsWithThrottle = () => {
      if (this.resizeThrottle) {
        return;
      }

      this.resizeThrottle = setTimeout(() => {
        this.resizeThrottle = null;
      }, throttleLength);

      this.updateWindowDimensions();
    };

    updateWindowDimensions() {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    render() {
      return <WrappedComponent windowDimensions={this.state} {...this.props} />;
    }
  };

export default withWindowDimensions;
