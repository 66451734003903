/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import FilterHelper from './FilterHelper';

const DOWNLOAD_PDF = 'download_pdf';
const DOWNLOAD_FORM_DATA = 'export_form_data';
const EDIT = 'edit';
const REMIND = 'remind';
const PREFILL = 'prefill';
const SIGN = 'sign';

const ORIGINATOR_ACTIONS = {
  agreement: {
    waiting_for_others: REMIND,
    completed: DOWNLOAD_PDF,
    cancelled: DOWNLOAD_PDF,
    expired: DOWNLOAD_PDF,
    archive: DOWNLOAD_PDF,
    draft: EDIT
  },
  template: {
    draft: EDIT,
    active: EDIT
  },
  webform: {
    draft: EDIT,
    active: EDIT,
    disabled: DOWNLOAD_PDF
  },
  megasign: {
    waiting_for_others: DOWNLOAD_FORM_DATA,
    completed: DOWNLOAD_FORM_DATA,
    cancelled: DOWNLOAD_PDF,
    expired: DOWNLOAD_FORM_DATA,
    draft: EDIT
  }
};

function getAgreementState(agreementType, state) {
  if (agreementType === 'template') {
    if (state === 'FORM') {
      return 'active';
    }
  } else if (agreementType === 'webform') {
    if (state === 'WIDGET') {
      return 'active';
    } else if (state === 'DISABLED') {
      return 'disabled';
    }
  }

  return FilterHelper.findGrouping('state', state);
}

class ContextActionUtil {
  static getPrimaryAction(data, isAccountSharedAgreement, isCsvExportEnabled) {
    const agreementType = FilterHelper.findGrouping('agreement_type', data.agreement_type);
    const agreementState = getAgreementState(agreementType, data.state);
    const isAdHocOrLibraryTemplateShare = !!data.sharer;
    const isOriginator = data.roles.includes('ORIGINATOR');

    if (isAccountSharedAgreement || isAdHocOrLibraryTemplateShare) {
      return agreementType === 'agreement' &&
        agreementState === 'waiting_for_others' &&
        isAccountSharedAgreement &&
        isOriginator
        ? REMIND
        : DOWNLOAD_PDF;
    }

    if (agreementState === 'waiting_for_you') {
      return data.state === 'WAITING_FOR_PREFILL' ? PREFILL : SIGN;
    }

    // When the agreement is in WAITING_FOR_NOTARIZATION, show the "Download PDF" button instead of "Reminde" (for senders)
    if (isOriginator && agreementState === 'waiting_for_others' && data.state === 'WAITING_FOR_NOTARIZATION') {
      return DOWNLOAD_PDF;
    }

    let action = isOriginator ? ORIGINATOR_ACTIONS[agreementType][agreementState] : DOWNLOAD_PDF;

    if (action === DOWNLOAD_FORM_DATA && !isCsvExportEnabled) {
      action = agreementState === 'waiting_for_others' ? REMIND : DOWNLOAD_PDF;
    }

    return action;
  }

  static getActionLabelId(action, state) {
    let actionId;
    switch (action) {
      case 'prefill':
      case 'sign':
        actionId = state.toLowerCase();
        break;
      default:
        actionId = action;
    }

    return `action.${actionId}`;
  }

  static canAddNote(data, user) {
    const myId = user.seriouslySecureId;
    const isShared = data.user_id !== myId;
    return data.is_soft_deleted !== true && !(data.agreement_type === 'LIBRARY_TEMPLATE' && isShared);
  }
}

export default ContextActionUtil;
