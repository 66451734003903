/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe
 **************************************************************************/

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { injectIntl } from 'react-intl';
import memoize from 'memoize-one';
import Select from '@react/react-spectrum/Select';
import { media } from '../../utils/mediaQuery';
import { analyticsFor } from '../../utils/analytics';
import bindAndCatchAndThrow from '../../utils/bindAndCatchAndThrow';

const MainContainer = styled.div`
  & .spectrum-FieldButton {
    background-color: white;
  }
`;

const StyledSelect = styled(Select)`
  width: 100% !important;
`;

// DCES-4296956 - Limit the height of the group list to 6 items
// until we can address the issue with larger lists causing the
// manage view to scroll, resulting in the popup being closed.
const MenuStyle = createGlobalStyle`
  .group-filter-menu { max-height: 192px; }
  @media ${media.zoom400Percent} {
    .group-filter-menu { max-height: 31px; }
  }
`;

class GroupFilter extends React.Component {
  get value() {
    return !!this.props.selectedGroup ? this.props.selectedGroup : 'all';
  }

  @bindAndCatchAndThrow
  selectOnChange(value) {
    const { onChange, sendAnalytics } = this.props;
    sendAnalytics(analyticsFor.GROUP_FILTER_SELECTED);
    onChange(value !== 'all' ? value : undefined);
  }

  // Memoize options to avoid creating new array on each render
  getOptions = memoize((groups, intl) => {
    const collator = new Intl.Collator(intl.locale);
    const sortByLabel = (a, b) => collator.compare(a.label, b.label);
    return [{ label: intl.formatMessage({ id: 'filter.group.all' }), value: 'all' }].concat(
      groups.slice().sort(sortByLabel)
    );
  });

  render() {
    const { groups, intl } = this.props;
    return (
      <MainContainer>
        <MenuStyle />
        <StyledSelect
          className="group-filter"
          menuClassName="group-filter-menu"
          onChange={this.selectOnChange}
          options={this.getOptions(groups, intl)}
          value={this.value}
        />
      </MainContainer>
    );
  }
}

export default injectIntl(GroupFilter);
