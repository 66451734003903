import stores from '../stores';

class GroupsUtil {
  static getGroupName(groupIdSet, isSharedAgreement) {
    //  Handles data prior and post UMG feature enablement
    if (!groupIdSet) return undefined;

    // Check Sender Signer scenario
    const isSenderSigner = groupIdSet.length > 1; // Sender Sign case contains two group_id_set (originator and primary signing group)
    // find primary group id
    const foundSigningGroup = groupIdSet.find(id => id === stores.UserGroups.getPrimaryGroupId());
    // Skip filter for shared agreement (legacy sharing case) since we can't determine primary group information
    const shouldFilterBySigningGroup = !isSharedAgreement && isSenderSigner && foundSigningGroup;

    const result = groupIdSet
      .filter(id => (shouldFilterBySigningGroup ? id !== foundSigningGroup : true))
      .map(id => stores.Groups.getGroupName(id))
      .filter(Boolean); // removes falsy from array for external legacy sharing
    return result.length ? result.join() : undefined;
  }

  /**
   * Function to determine which group a template is shared with.
   * @param shareWithId - undefined when its my own template,
   *                      account_id when it is shared with my organization
   *                      group_id when it is shared with a group
   * @returns {Group} - group name when it is shared with a group, undefined otherwise
   */
  static getTemplateGroupName(shareWithId) {
    return stores.Groups.getGroupName(shareWithId);
  }
}

export default GroupsUtil;
