import { router } from 'dc-core';
import { calcDateRangeFromRangeType } from './DateUtil';
import Env from '../stores/env';

class RoutingUtil {
  constructor({ location, history, allowPersistentAllCategory }) {
    this.hasModifiedRoute = false;
    this.history = history;
    this.location = location;
    this.allowPersistentAllCategory = allowPersistentAllCategory;
    this.params = this.getHashParams();
  }

  setLocation(location) {
    this.location = location;
    this.params = this.getHashParams();
  }

  getHashParams() {
    return router.getQueryParams(this.location.hash);
  }

  setParam(key, value) {
    this.params[key] = value;
  }

  get agreementType() {
    if (this.params.agreement_type !== 'all' || this.isFilterOrSearch || this.allowPersistentAllCategory) {
      return this.params.agreement_type;
    }
    return undefined; // reset type all when used without search or filter
  }

  // Applies only to agreement_type all fragment.
  get isFilterOrSearch() {
    const useSearchTerm = !!this.params.q;
    const useDateFilter = !!this.params.date_range_type || !!this.params.start_date || !!this.params.end_date;
    const useGroupFilter = !!this.params.group_id;
    const useVisibilityFilter = !!this.params.visibility;
    const usePaymentFilter =
      !!this.params.payment_start_date ||
      !!this.params.payment_end_date ||
      !!this.params.payment_date_range_type ||
      !!this.params.payment_amount_min ||
      !!this.params.payment_amount_max ||
      !!this.params.payment_currency ||
      !!this.params.payment_company;
    return useSearchTerm || useDateFilter || useGroupFilter || useVisibilityFilter || usePaymentFilter;
  }

  /**
   * If a valid filter panel section is provided in the filter_panel, return the panel name. Otherwise return undefined.
   * For now only paymentinfo is a valid filter panel section. This is expected to be extended in the future.
   * Example of a valid deep link: acrobat.adobe.com/link/documents/agreements/?filter_panel=paymentinfo
   * @returns {string|undefined}
   */
  getFilterPanelDeepLink() {
    return router.queryParams?.filter_panel === 'paymentinfo' ? 'paymentinfo' : undefined;
  }

  push() {
    let hashString = '';
    const params = {
      q: this.params.q && this.params.q !== '*' ? encodeURIComponent(this.params.q) : undefined,
      agreement_type: this.agreementType,
      agreement_state: this.params.agreement_state,
      visibility: this.params.visibility,
      parent_id: this.params.parent_id,
      date_range_type: this.params.date_range_type,
      start_date: this.params.start_date,
      end_date: this.params.end_date,
      shared: this.params.shared,
      field: this.params.field,
      user_share: this.params.user_share,
      group_share: this.params.group_share,
      group_id: this.params.group_id,
      agreement_end_date: this.params.agreement_end_date,
      payment_start_date: this.params.payment_start_date,
      payment_end_date: this.params.payment_end_date,
      payment_date_range_type: this.params.payment_date_range_type,
      payment_amount_min: this.params.payment_amount_min,
      payment_amount_max: this.params.payment_amount_max,
      payment_currency: this.params.payment_currency,
      payment_company: this.params.payment_company
    };

    hashString += '#';
    Object.keys(params).forEach(paramKey => {
      if (params[paramKey] !== undefined) {
        hashString += `${paramKey}=${params[paramKey]}&`;
      }
    });

    hashString = hashString.slice(0, -1);

    if (this.location.hash !== hashString) {
      this.hasModifiedRoute = true;
      // retaining query params
      this.history.push(`${this.location.pathname}${this.location.search}${hashString}`);
    }
  }

  setSearchParam(searchTerm) {
    this.setParam('q', searchTerm ? searchTerm : undefined);
  }

  setVisibilityParam(visibility) {
    let paramValue;
    if (visibility === 'SHOW_HIDDEN') paramValue = 'hidden';
    if (visibility === 'SHOW_ALL') paramValue = 'all';
    this.setParam('visibility', paramValue);
  }

  setVisibility(visibility) {
    this.setVisibilityParam(visibility);
    this.push();
  }

  setDateRangeParams(rangeType, startDate, endDate) {
    this.setParam('date_range_type', rangeType === 'custom' ? undefined : rangeType);
    this.setParam('start_date', rangeType !== 'custom' ? undefined : startDate);
    this.setParam('end_date', rangeType !== 'custom' ? undefined : endDate);
  }

  setSearchTerm(searchTerm) {
    this.setSearchParam(searchTerm);
    this.push();
  }

  setQueryableFieldParam(field) {
    let paramValue;
    if (field !== 'all') paramValue = field;
    this.setParam('field', paramValue);
  }

  setQueryableField(field) {
    this.setQueryableFieldParam(field);
    this.push();
  }

  setNav(agreementType, agreementState, parentId) {
    this.setParam('agreement_state', agreementState);
    this.setParam('agreement_type', agreementType);
    this.setParam('parent_id', parentId);
    this.push();
  }

  setPaymentParams(rangeType, startDate, endDate, amountMin, amountMax, currency, company) {
    this.setParam('payment_date_range_type', rangeType);
    this.setParam('payment_start_date', startDate);
    this.setParam('payment_end_date', endDate);
    this.setParam('payment_amount_min', amountMin);
    this.setParam('payment_amount_max', amountMax);
    this.setParam('payment_currency', currency);
    this.setParam('payment_company', company);
    this.push();
  }

  // Get a unique identifier for current tab
  getTabID() {
    return `${this.params.agreement_type}-${this.params.agreement_state}`;
  }

  setDateRange(rangeType, startDate, endDate) {
    this.setDateRangeParams(rangeType, startDate, endDate);
    this.push();
  }

  setGroupParam(groupId) {
    this.setParam('group_id', groupId);
  }

  setGroup(groupId) {
    this.setGroupParam(groupId);
    this.push();
  }

  setAgreementState(agreementState) {
    this.setParam('agreement_state', agreementState);
    this.push();
  }

  setEndDate(endDate) {
    this.setParam('agreement_end_date', endDate);
    this.push();
  }

  clearSearch() {
    this.setSearchParam();
    this.push();
  }

  setShared(userShareId, groupShareId) {
    this.setParam('shared', true);
    this.setParam('user_share', userShareId);
    this.setParam('group_share', groupShareId);
    this.setParam('group_id', undefined);
    // Current selected category might not be visible
    // in the new view so we reset to the default state.
    this.setNav(); // clear nav state and push
  }

  clearShared() {
    this.setParam('shared', undefined);
    this.setParam('user_share', undefined);
    this.setParam('group_share', undefined);
    this.setParam('group_id', undefined);
    // Current selected category might not be visible
    // in the new view so we reset to the default state.
    this.setNav(); // clear nav state and push
  }

  getStateFromSearchParams(state = {}) {
    const calculatedAgreementType = RoutingUtil.calcAgrType(this.params.agreement_type);
    const calculatedParentId = RoutingUtil.calcParentId(calculatedAgreementType, this.params.parent_id);
    const calculatedDateType =
      this.params.date_range_type || (!!this.params.start_date && !!this.params.end_date ? 'custom' : undefined);

    const updatedState = {
      selectedAgreement: null,
      selectedAgreementList: null,
      searchInputValue: this.params.q || '',
      searchTerm: RoutingUtil.calcSearchTerm(this.params.q),
      agreementType: calculatedAgreementType,
      agreementState: RoutingUtil.calcAgrState(
        calculatedAgreementType,
        this.params.agreement_state,
        calculatedParentId
      ),
      useInferredAgreementState:
        (calculatedAgreementType === 'agreement' || !!calculatedParentId) &&
        !this.params.agreement_state &&
        !this.params.agreement_end_date,
      dateRangeType: calculatedDateType,
      startDate:
        calculatedDateType !== 'custom'
          ? calcDateRangeFromRangeType(this.params.date_range_type).startDate
          : this.params.start_date,
      endDate:
        calculatedDateType !== 'custom'
          ? calcDateRangeFromRangeType(this.params.date_range_type).endDate
          : this.params.end_date,
      visibility: RoutingUtil.calcVisibility(this.params.visibility),
      queryableField: RoutingUtil.calcQueryableField(this.params.field),
      parentId: calculatedParentId,
      parentTitle: calculatedParentId && state.parentTitle,
      parentOwnerId: calculatedParentId && state.parentOwnerId,
      sharedAgreements: this.params.shared === 'true',
      userShare: this.params.user_share,
      groupShare: this.params.group_share,
      groupId: this.params.group_id,
      agreementEndDate: this.params.agreement_end_date,
      payment_amount_min: this.params.payment_amount_min,
      payment_amount_max: this.params.payment_amount_max,
      payment_currency: this.params.payment_currency,
      payment_company: this.params.payment_company,
      payment_date_range_type: this.params.payment_date_range_type,
      payment_start_date: this.params.payment_start_date,
      payment_end_date: this.params.payment_end_date
    };

    // Configure correct sort state based on existing/updated state
    RoutingUtil.calcSortState(updatedState, state);

    return RoutingUtil.updateQueryFacets(updatedState, state);
  }

  static calcVisibility(input) {
    if (input === 'hidden') return 'SHOW_HIDDEN';
    if (input === 'all') return 'SHOW_ALL';
    return 'SHOW_VISIBLE';
  }

  static calcSearchTerm(searchTerm) {
    return typeof searchTerm === 'undefined' || searchTerm === null ? '*' : searchTerm;
  }

  static calcAgrType(agreement_type) {
    return typeof agreement_type === 'undefined' || agreement_type === null
      ? 'agreement'
      : agreement_type.toLowerCase();
  }

  static calcAgrState(agreement_type, agreement_state, parentId) {
    return (agreement_type !== 'agreement' && !parentId) ||
      typeof agreement_state === 'undefined' ||
      agreement_state === null
      ? null
      : agreement_state.toLowerCase();
  }

  static calcQueryableField(fieldType) {
    return typeof fieldType === 'undefined' || fieldType === null ? 'all' : fieldType;
  }

  static calcParentId(agreementType, parentId) {
    return ['megasign', 'webform'].includes(agreementType) ? parentId : null;
  }

  static getDefaultSort(state) {
    let sortColumn = 'modify_date';
    let sortDirection = 'desc';

    if (state.agreementEndDate) {
      sortColumn = 'termination_dates';
      sortDirection = state.agreementEndDate === 'ended' ? 'desc' : 'asc';
    }

    return { sortColumn, sortDirection };
  }

  static calcSortState(updatedState, state) {
    const stateChanged = prop => updatedState[prop] !== state[prop];
    let { manualSort, sortColumn, sortDirection } = state;

    // A change to any of these properties indicates a new data view is
    // being presented to the user. In that case we reset the sort state.
    if (['agreementType', 'agreementState', 'agreementEndDate'].some(stateChanged)) {
      manualSort = false;
      ({ sortColumn, sortDirection } = RoutingUtil.getDefaultSort(updatedState));
    }

    Object.assign(updatedState, { manualSort, sortColumn, sortDirection });
  }

  static updateQueryFacets(updatedState, state) {
    const stateChanged = prop => updatedState[prop] !== state[prop];
    const shouldPreserveQueryFacets = () => {
      return (
        (updatedState.parentId || updatedState.agreementEndDate) &&
        // detect change to query and date filter type
        !['searchTerm', 'dateRangeType'].some(stateChanged) &&
        // detect change to custom date range
        (updatedState.dateRangeType !== 'custom' || !['startDate', 'endDate'].some(stateChanged)) &&
        // detect change to payment filter
        ![
          'payment_amount_min',
          'payment_amount_max',
          'payment_currency',
          'payment_company',
          'payment_date_range_type',
          'payment_start_date',
          'payment_end_date'
        ].some(stateChanged)
      );
    };

    // Preserve queryFacets from current state when a drill-down view is
    // active and there has been no state change that requires an update
    if (shouldPreserveQueryFacets()) {
      updatedState.queryFacets = state.queryFacets;
      updatedState.queryEndedFacets = state.queryEndedFacets;
    } else {
      updatedState.queryFacets = null;
      updatedState.queryEndedFacets = null;
    }
    return updatedState;
  }

  static navigateWithPath(path) {
    if (Env.isDCWeb) {
      router.history.push(this.transformPathForDCWeb(path));
    } else {
      window.location.assign(`${window.location.origin}${path}`);
    }
  }

  /**
   * For DC Web, we need to transform the path for display within the Sign iFrame.
   * Given the Sign application path:
   *   /account/reusableDocument?aid=CBJCH...cPsi&locale=en&client_id=p47M9L6G5N4P5A
   * We need to generate the following:
   *   /link/signatures/?signUri=%2Faccount%2FreusableDocument%3Faid%3DCBJCH...cPsi%26locale%3Den%26client_id%3Dp47M9L6G5N4P5A
   *
   * @param {String} path - a path within the Sign application.
   * @returns {String} A path in the format required for loading in the Sign iFrame.
   */
  static transformPathForDCWeb(path) {
    const encodedPath = encodeURIComponent(path);
    const currentURL = window.location.href,
      signPath = '/link/signatures/';
    if (currentURL.indexOf('?') === -1) {
      return signPath + '?signUri=' + encodedPath;
    } else {
      if (currentURL.indexOf('signUri') === -1) {
        return signPath + window.location.search + '&signUri=' + encodedPath;
      } else {
        const hostURL = new URL(currentURL),
          queryParams = hostURL.searchParams;

        queryParams.set('signUri', path);
        return signPath + hostURL.search;
      }
    }
  }
}

export default RoutingUtil;
