/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import styled, { css } from 'styled-components';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Button from '@react/react-spectrum/Button';
import Heading from '@react/react-spectrum/Heading';
import ChevronDown from '@react/react-spectrum/Icon/ChevronDown';
import { List, ListItem } from '@react/react-spectrum/List';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Popover from '@react/react-spectrum/Popover';
import Search from '@react/react-spectrum/Search';
import Wait from '@react/react-spectrum/Wait';

import { analyticsFor } from '../../utils/analytics';
import bindAndCatchAndThrow from '../../utils/bindAndCatchAndThrow';

const AccountStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubText = styled.div`
  font-size: 14px;
`;

const DropdownButton = styled(Button)`
  ${props =>
    props.compact &&
    css`
      margin-top: -6px;
    `}

  justify-content: space-between;
  max-width: 392px;

  & .spectrum-Icon {
    margin-left: 8px;
    margin-top: 2px;
    width: 20px;
  }

  & .button-label {
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledPopover = styled(Popover)`
  &.share-popover {
    min-width: 312px;
    max-width: 392px;
    min-height: 112px;
    max-height: 70% !important;
  }
`;

const ListHeading = styled.li`
  background: rgb(245, 245, 245);
  color: grey;
  font-size: 10px;
  padding: 4px 12px;

  &:nth-child(3) {
    border-top: 1px solid lightgray;
  }
`;

const LoadingStatus = styled.div`
  border-top: 1px solid lightgray;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0 12px;
  padding: 9px 0;

  & .spectrum-CircleLoader {
    margin-left: 12px;
  }
`;

const StyledSearch = styled(Search)`
  & input[type='search'] {
    font-size: 13px;
    padding-left: 32px;
    padding-top: 5px;
    width: 100%;
  }

  & svg.spectrum-Icon.spectrum-Search-icon {
    left: 8px;
  }
`;

const PageTitle = styled(Heading)`
  &.page-title {
    color: #4b4b4b;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
  }
`;

class AccountStatus extends React.Component {
  state = {
    filterValue: '',
    groupShares: [],
    itemsLoaded: false,
    loading: false,
    userShares: []
  };

  groupListItems(items) {
    const groupedItems = items.reduce(
      (memo, item) => {
        (item.type === 'USER' ? memo.userShares : memo.groupShares).push(item);
        return memo;
      },
      {
        userShares: [],
        groupShares: []
      }
    );

    // todo: verify availability of Intl.Collator
    const collator = new Intl.Collator(this.props.intl.locale);
    const sortByLabel = (a, b) => collator.compare(a.label, b.label);
    groupedItems.userShares.sort(sortByLabel);
    groupedItems.groupShares.sort(sortByLabel);
    return groupedItems;
  }

  loadListItems() {
    this.setState({ loading: true });
    this.props.getShares().then(items => {
      const groupedShares = this.groupListItems(items);
      this.props.sendAnalytics(analyticsFor.ACCOUNT_SHARE_LOAD, {
        groupShareCount: groupedShares.groupShares.length,
        userShareCount: groupedShares.userShares.length
      });
      this.setState(
        Object.assign(groupedShares, {
          itemsLoaded: true,
          loading: false
        })
      );
    });
  }

  @bindAndCatchAndThrow
  onListChange(item) {
    const shareType = (item.type || item.value).toLowerCase();
    this.props.sendAnalytics(analyticsFor.ACCOUNT_SHARE_SELECT, { shareType });
    this.overlayTrigger.hide();
    this.props.onChange(item);
  }

  get shareCategory() {
    const { activeShare, intl } = this.props;
    return activeShare.type === 'USER'
      ? intl.formatMessage({ id: 'accountshare.user_share' })
      : intl.formatMessage({ id: 'accountshare.account_share' });
  }

  get shareDescription() {
    const { activeShare, intl } = this.props;
    switch (activeShare.type) {
      case 'USER':
        return intl.formatMessage({ id: 'accountshare.user_shared_agreements' }, { userName: activeShare.label });
      case 'GROUP':
        return intl.formatMessage({ id: 'accountshare.account_shared_agreements' }, { groupName: activeShare.label });
      default:
        return activeShare.value === 'all'
          ? intl.formatMessage({ id: 'accountshare.all_shared_agreements' })
          : intl.formatMessage({ id: 'accountshare.my_agreements' });
    }
  }

  get listItems() {
    const labelFilter = item => item.label.startsWith(this.state.filterValue);
    const getHeading = key => ({
      heading: true,
      label: this.props.intl.formatMessage({ id: `accountshare.section.${key}` }),
      value: key
    });

    let items = [
      { label: this.props.intl.formatMessage({ id: 'accountshare.my_agreements' }), value: 'none' },
      { label: this.props.intl.formatMessage({ id: 'accountshare.all_shared_agreements' }), value: 'all' }
    ];

    const users = this.state.userShares.filter(labelFilter);
    if (users.length > 0) {
      items.push(getHeading('user_shares'));
      items = items.concat(users);
    }

    const groups = this.state.groupShares.filter(labelFilter);
    if (groups.length > 0) {
      items.push(getHeading('group_shares'));
      items = items.concat(groups);
    }

    return items;
  }

  renderListItems() {
    return this.listItems.map(item =>
      item.heading ? (
        <ListHeading className="list-heading" key={item.value} role="presentation">
          <span role="heading">{item.label}</span>
        </ListHeading>
      ) : (
        <ListItem
          key={item.value}
          label={item.label}
          onSelect={() => this.onListChange(item)}
          selected={item.value === this.props.activeShare.value}
        />
      )
    );
  }

  render() {
    const { accountSharingActive, activeShare } = this.props;
    const showCategory = accountSharingActive && activeShare.value !== 'none';

    if (!accountSharingActive) {
      return (
        <PageTitle variant="pageTitle" className="page-title">
          {this.props.intl.formatMessage({ id: 'accountshare.my_agreements' })}
        </PageTitle>
      );
    }

    return (
      <AccountStatusContainer className="account-status">
        {showCategory && <SubText className="share-category">{this.shareCategory}</SubText>}
        <OverlayTrigger
          trigger="click"
          placement="bottom left"
          closeOnSelect={true}
          ref={t => (this.overlayTrigger = t)}
          onShow={() => {
            this.props.sendAnalytics(analyticsFor.ACCOUNT_SHARE_OPEN);
            this.setState({ filterValue: '' });
            if (!this.state.itemsLoaded) {
              this.loadListItems();
            }
          }}
        >
          <DropdownButton
            type="button"
            variant="field"
            aria-haspopup="listbox"
            quiet={true}
            compact={showCategory}
            title={this.shareDescription}
          >
            <span className="button-label">{this.shareDescription}</span>
            <ChevronDown />
          </DropdownButton>
          <StyledPopover className="share-popover" isDialog={false}>
            <StyledSearch
              placeholder={this.props.intl.formatMessage({ id: 'accountshare.search.placeholder' })}
              quiet
              onChange={value => {
                if (this.state.filterValue.length === 0) this.props.sendAnalytics(analyticsFor.ACCOUNT_SHARE_FILTER);
                this.setState({ filterValue: value });
              }}
              value={this.state.filterValue}
              title={this.props.intl.formatMessage({ id: 'accountshare.search.placeholder' })}
            />
            <List autoFocus>{this.renderListItems()}</List>
            {this.state.loading && (
              <LoadingStatus>
                <span>{this.props.intl.formatMessage({ id: 'accountshare.loading' })}</span>
                <Wait size="S" />
              </LoadingStatus>
            )}
          </StyledPopover>
        </OverlayTrigger>
      </AccountStatusContainer>
    );
  }
}

AccountStatus.propTypes = {
  accountSharingActive: PropTypes.bool,
  activeShare: PropTypes.object,
  getShares: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  sendAnalytics: PropTypes.func.isRequired,
  intl: PropTypes.shape.isRequired
};

AccountStatus.defaultProps = {
  accountSharingActive: false
};

export default injectIntl(AccountStatus);
