/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Datepicker from '@react/react-spectrum/Datepicker';
import Textfield from '@react/react-spectrum/Textfield';
import Searchfield from '@react/react-spectrum/Search';
import Select from '@react/react-spectrum/Select';
import Switch from '@react/react-spectrum/Switch';
import { media } from '../../utils/mediaQuery';
import { analyticsFor } from '../../utils/analytics';

import {
  AMOUNT_MAX,
  AMOUNT_MIN,
  COMPANY_MAX_LENGTH,
  currencyOptionsArray,
  validAmount,
  validCurrency,
  validDate,
  validCompany,
  defaultLocaleMap
} from '../../utils/paymentFilterUtil';

const StyledSelect = styled(Select)`
  width: 100% !important;
`;

const StyledAmountField = styled(Textfield)`
  width: 100% !important;
  display: block;
`;

const StyledSearch = styled(Searchfield)`
  width: 100% !important;
`;

const MenuStyle = createGlobalStyle`
  @media ${media.zoom400Percent} {
    .date-filter-menu { max-height: 31px; }
  }
`;

const amtStyleRange = {
  width: '43%',
  display: 'inline-block'
};
const amtStyleRangeLabel = {
  paddingTop: '0'
};

const amtStyleDash = {
  width: '14%',
  display: 'inline-block',
  textAlign: 'center',
  fontSize: '1.5em',
  verticalAlign: 'top'
};

const amtStyleFixed = {
  width: '100%',
  display: 'inline-block'
};

// Convert any two-byte numbers to one byte equivalents (e.g. ０ -> 0)
const twoByteNumberHelper = val =>
  (val || '').replace(/[０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
// Normalize full width characters for processing
const twoByteNormalize = val => val?.normalize('NFKC');

const DEBOUNCE_TIME = 1000;
const DEFAULT_CURRENCY = 'JPY';

// Select option object for the currency dropdown
const generateCurrencyOption = (intl, option, id) => ({
  label: intl.formatMessage({
    id: id || `currency.${option.toLowerCase()}`
  }),
  value: option
});

// Generate an array of currency options, with the user's locale at the top, localized to the user's language
const buildCurrencyArray = intl => {
  // Find the currency for the users locale and remove it from the list
  const defaultCurrency = defaultLocaleMap[intl.locale] || DEFAULT_CURRENCY;
  const defaultIndex = currencyOptionsArray.indexOf(defaultCurrency);
  if (defaultIndex > -1) {
    currencyOptionsArray.splice(defaultIndex, 1);
  }

  // Create an array of all of the remaining currencies, sorted alphabetically
  const currencyArray = currencyOptionsArray.map(option => generateCurrencyOption(intl, option));
  currencyArray.sort((a, b) => a.label.localeCompare(b.label));

  // Add the placeholder option followed by user's locale to the top of the list
  currencyArray.unshift(
    generateCurrencyOption(intl, '', 'payment_info.currency_placeholder'),
    generateCurrencyOption(intl, defaultCurrency)
  );
  return currencyArray;
};

// Returns a string with one character for each defined field submitted to the filter
const analyticsFieldsShortform = state => {
  const mapping = {
    startDate: 'S',
    endDate: 'E',
    paymentAmountMin: 'A',
    paymentAmountMax: 'B',
    paymentCurrency: 'C',
    paymentCompany: 'Q'
  };
  // Filter mapping fields and join with colon
  return Object.keys(mapping)
    .filter(key => state[key] !== undefined)
    .map(key => mapping[key])
    .join('');
};

export class PaymentFilter extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      dateRangeType: props.paymentDateRangeType,
      // If the date range wasn't specified the start and end dates are probably invalid
      startDate: props.paymentDateRangeType ? props.paymentStartDate : undefined,
      endDate: props.paymentDateRangeType ? props.paymentEndDate : undefined,
      paymentAmountMin: props.paymentAmountMin,
      paymentAmountMax: props.paymentAmountMax,
      paymentCurrency: props.paymentCurrency,
      paymentCompany: props.paymentCompany,
      amountRange: props.paymentAmountMin !== props.paymentAmountMax,
      // If the date range type is range, or if only end date is specified
      dateRangeSwitch: props.paymentDateRangeType === 'range' || (!props.paymentStartDate && !!props.paymentEndDate),
      amountMinInvalid: false,
      amountMaxInvalid: false
    };
    this.filterTimeout = null;
    // Generate a sorted array of localized currencies, with an empty option at the top, followed by the user locale, and the rest
    this.currencyArray = buildCurrencyArray(this.props.intl);
    this.props.registerResetCallback(this.reset.bind(this));

    // Allow us to trigger events on the date pickers to help handle two byte characters
    this.startDateRef = React.createRef();
    this.endDateRef = React.createRef();
  }

  // After the user has finished entering the amount, normalize the amount value and set validation
  normalizeAmount = () => {
    const newValMin = twoByteNumberHelper(this.state.paymentAmountMin);
    const newValMax = twoByteNumberHelper(this.state.paymentAmountMax);
    return this.setState({
      paymentAmountMin: newValMin,
      paymentAmountMax: newValMax
    });
  };

  reset() {
    this.setState({
      dateRangeType: undefined,
      startDate: undefined,
      endDate: undefined,
      paymentAmountMin: undefined,
      paymentAmountMax: undefined,
      paymentCurrency: undefined,
      paymentCompany: undefined,
      amountRange: false,
      dateRangeSwitch: false
    });
  }

  trimAmount = value => ((typeof value === 'string' && !value.trim()) || isNaN(value) ? undefined : Number(value));

  // TODO - filter callback arrow function vs function?
  filterCallback = () => {
    let {
      dateRangeType,
      startDate,
      endDate,
      paymentAmountMin,
      paymentAmountMax,
      paymentCurrency,
      paymentCompany,
      amountRange
    } = this.state;

    // Min and Max should match if the user specifies a single amount
    if (!amountRange) {
      paymentAmountMax = paymentAmountMin;
    }

    // if search is valid proceed, otherwise wait for the user to fix it
    if (
      !validAmount(paymentAmountMin, paymentAmountMax) ||
      !validDate(startDate, endDate) ||
      !validCurrency(paymentCurrency) ||
      !validCompany(paymentCompany)
    ) {
      clearTimeout(this.filterTimeout);
      return;
    }

    // Debounce onChange to reduce search spam
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }

    paymentAmountMin = this.trimAmount(paymentAmountMin);
    paymentAmountMax = this.trimAmount(paymentAmountMax);

    this.filterTimeout = setTimeout(() => {
      this.props.sendAnalytics(analyticsFor.FILTERPANEL_SEARCH, { fields: analyticsFieldsShortform(this.state) });
      this.props.onChange(
        dateRangeType,
        startDate,
        endDate,
        paymentAmountMin,
        paymentAmountMax,
        paymentCurrency,
        paymentCompany
      );
    }, DEBOUNCE_TIME);
  };

  AmountField = ({ id, testId, min, max, value, onChange }) => {
    let invalid = false;
    if (!validAmount(min, max, value) && !this.state.composition) {
      invalid = true;
    }

    return (
      <StyledAmountField
        id={id}
        data-testid={testId}
        value={value}
        validationState={invalid && 'invalid'}
        onChange={onChange}
        onBlur={() => {
          this.setState({ composition: false });
          this.normalizeAmount();
          this.filterCallback();
        }}
        onCompositionStart={e => {
          this.setState({ composition: true });
        }}
        onCompositionEnd={e => {
          this.setState({ composition: false });
          this.normalizeAmount();
          this.filterCallback();
        }}
      />
    );
  };

  AmountRangeComponent = () => {
    const amountBlock = {
      width: '100%'
    };
    const AmountField = this.AmountField;

    return (
      <>
        <label
          className="spectrum-FieldLabel spectrum-FieldLabel--left"
          htmlfor={this.state.amountRange && 'payment-amount'}
        >
          {this.props.intl.formatMessage({ id: 'payment_info.amount' })}
        </label>
        <div style={amountBlock}>
          {this.state.amountRange ? (
            <>
              <div style={amtStyleRange}>
                <AmountField
                  id="payment-amount-min"
                  testId="payment-amount-min"
                  min={AMOUNT_MIN}
                  value={this.state.paymentAmountMin}
                  invalid={this.state.amountMinInvalid}
                  onChange={val =>
                    this.setState(
                      {
                        paymentAmountMin: val,
                        amountMinInvalid: this.state.composition
                          ? this.state.amountMinInvalid
                          : !validAmount(AMOUNT_MIN, AMOUNT_MAX, val)
                      },
                      this.state.composition ? null : this.filterCallback.bind(this)
                    )
                  }
                />
                <label
                  style={amtStyleRangeLabel}
                  className="spectrum-FieldLabel spectrum-FieldLabel--left"
                  htmlFor="payment-amount-min"
                >
                  {this.props.intl.formatMessage({ id: 'payment_info.amount_min' })}
                </label>
              </div>
              <div style={amtStyleDash}>-</div>
              <div style={amtStyleRange}>
                <AmountField
                  testId="payment-amount-max"
                  min={this.state.paymentAmountMin || AMOUNT_MIN}
                  value={this.state.paymentAmountMax}
                  invalid={this.state.amountMinInvalid}
                  onChange={val =>
                    this.setState(
                      {
                        paymentAmountMax: val,
                        amountMaxInvalid: this.state.composition
                          ? this.state.amountMaxInvalid
                          : !validAmount(AMOUNT_MIN, AMOUNT_MAX, val)
                      },
                      this.state.composition ? null : this.filterCallback.bind(this)
                    )
                  }
                />
                <label
                  style={amtStyleRangeLabel}
                  className="spectrum-FieldLabel spectrum-FieldLabel--left"
                  htmlFor="payment-amount-max"
                >
                  {this.props.intl.formatMessage({ id: 'payment_info.amount_max' })}
                </label>
              </div>
            </>
          ) : (
            <div style={amtStyleFixed}>
              <AmountField
                testId="payment-amount-value"
                min={AMOUNT_MIN}
                max={AMOUNT_MAX}
                value={this.state.paymentAmountMin === undefined ? '' : this.state.paymentAmountMin}
                invalid={this.state.amountMinInvalid}
                onChange={val =>
                  this.setState(
                    {
                      paymentAmountMin: val,
                      paymentAmountMax: val,
                      amountMinInvalid: this.state.composition
                        ? this.state.amountMinInvalid
                        : !validAmount(AMOUNT_MIN, AMOUNT_MAX, val)
                    },
                    this.state.composition ? null : this.filterCallback.bind(this)
                  )
                }
              />
            </div>
          )}
          <Switch
            data-testid="payment-amount-range-switch"
            label={this.props.intl.formatMessage({ id: 'payment_info.amount_range' })}
            checked={this.state.amountRange}
            invalid={true}
            onChange={bool => {
              this.setState({ amountRange: bool }, this.filterCallback);
            }}
          />
        </div>
      </>
    );
  };

  CurrencyComponent = () => {
    const { intl } = this.props;
    const paymentSelect =
      this.state.paymentCurrency && this.state.paymentCurrency.length === 3 ? this.state.paymentCurrency : undefined;

    return (
      <>
        <label className="spectrum-FieldLabel spectrum-FieldLabel--left" htmlFor="payment-currency">
          {intl.formatMessage({ id: 'payment_info.currency' })}
        </label>
        <StyledSelect
          data-testid="payment-currency"
          menuClassName="date-filter-menu"
          placeholder={intl.formatMessage({ id: 'payment_info.currency_placeholder' })}
          value={paymentSelect}
          onChange={val => {
            this.setState({ paymentCurrency: val }, this.filterCallback.bind(this));
          }}
          options={this.currencyArray}
        />
      </>
    );
  };

  // If the date is valid, set the state and call the filter callback
  // If the date is invalid just set the state but don't adjust the filter or end date
  handleStartDateChange = date => {
    date = twoByteNormalize(date);
    // Is the date valid?
    if (date && validDate(date)) {
      const startDate = new Date(date);
      const start = startDate.toISOString();
      const newState = { startDate: start };
      if (this.state.dateRangeType === 'single') {
        // For a single day range, we need to set the end date to the next day
        // to cover a full 24 hour period
        const endDate = new Date(date);
        endDate.setDate(endDate.getDate() + 1);
        newState.endDate = endDate.toISOString();
      }
      this.setState(newState, () => {
        // A hack to clear the invalid internal state on the datepicker when handling two byte characters
        // TODO - remove this when the datepicker is fixed or mirgrated to a new component
        this.startDateRef?.current?.setState({ invalid: false });
        this.filterCallback();
      });
    } else if (!date) {
      this.setState({ startDate: undefined }, this.filterCallback.bind(this));
    }
  };
  handleEndDateChange = date => {
    date = twoByteNormalize(date);
    if (date && validDate(date)) {
      const endDate = new Date(date).toISOString();
      this.setState({ endDate }, () => {
        // A hack to clear the invalid internal state on the datepicker when handling two byte characters
        // TODO - remove this when the datepicker is fixed or mirgrated to a new component
        this.endDateRef?.current?.setState({ invalid: false });
        this.filterCallback();
      });
    } else if (!date) {
      this.setState({ endDate: undefined }, this.filterCallback.bind(this));
    }
  };

  handleDateRangeSwitch = bool => {
    if (bool) {
      this.setState({ dateRangeType: 'range', dateRangeSwitch: bool });
    } else {
      this.setState({ dateRangeType: 'single', dateRangeSwitch: bool, endDate: undefined }, () =>
        this.handleStartDateChange(this.state.startDate)
      );
    }
  };

  DateRangeComponent = () => {
    const { intl } = this.props;
    // Setup validation state
    let validRange = true;
    if (this.state.dateRangeSwitch) {
      validRange = validDate(this.state.startDate, this.state.endDate);
    }
    let validStart = validDate(this.state.startDate) && validRange;
    let validEnd = validDate(this.state.endDate) && validRange;

    // Prepare the date strings for the datepicker
    const dateLabelId = this.state.dateRangeSwitch ? 'payment_info.start_date' : 'payment_info.date';
    const startDate = this.state.startDate?.split('T')[0];
    const endDate = this.state.endDate?.split('T')[0];

    return (
      <>
        <label className="spectrum-FieldLabel spectrum-FieldLabel--left" htmlFor="payment-date-filter">
          {intl.formatMessage({ id: dateLabelId })}
        </label>
        <Datepicker
          ref={this.startDateRef}
          id="payment-date-filter"
          data-testid="date-filter"
          value={startDate}
          displayFormat="YYYY-MM-DD"
          selectionType="single"
          valueFormat="YYYY-MM-DD"
          validationState={!validStart && 'invalid'}
          onChange={this.handleStartDateChange.bind(this)}
        />
        {this.state.dateRangeSwitch && (
          <>
            <label className="spectrum-FieldLabel spectrum-FieldLabel--left" htmlFor="payment-date-filter">
              {intl.formatMessage({ id: 'payment_info.end_date' })}
            </label>
            <Datepicker
              ref={this.endDateRef}
              id="payment-date-end-filter"
              data-testid="date-end-filter"
              value={endDate}
              displayFormat="YYYY-MM-DD"
              selectionType="single"
              valueFormat="YYYY-MM-DD"
              validationState={!validEnd && 'invalid'}
              onChange={this.handleEndDateChange.bind(this)}
            />
          </>
        )}
        <Switch
          data-testid="date-filter-switch"
          checked={this.state.dateRangeSwitch}
          label={intl.formatMessage({ id: 'payment_info.date_range_switch' })}
          onChange={this.handleDateRangeSwitch.bind(this)}
        />
      </>
    );
  };

  CompanySearchComponent = () => {
    const { intl } = this.props;
    return (
      <>
        <label className="spectrum-FieldLabel spectrum-FieldLabel--left" htmlFor="payment-company">
          {intl.formatMessage({ id: 'payment_info.company' })}
        </label>
        <StyledSearch
          id="payment-company"
          style={{ width: '100%' }}
          data-testid="payment-company"
          className="payment-search"
          value={this.state.paymentCompany || ''}
          maxLength={COMPANY_MAX_LENGTH}
          onChange={val => {
            this.setState({ paymentCompany: val }, this.state.composition ? null : this.filterCallback.bind(this));
          }}
          onBlur={() => {
            this.setState({ composition: false });
            this.filterCallback();
          }}
          onCompositionStart={e => {
            this.setState({ composition: true });
          }}
          onCompositionEnd={e => {
            this.setState({ composition: false });
            this.filterCallback();
          }}
        />
      </>
    );
  };

  render() {
    const AmountRangeComponent = this.AmountRangeComponent;
    const CurrencyComponent = this.CurrencyComponent;
    const DateRangeComponent = this.DateRangeComponent;
    const CompanySearchComponent = this.CompanySearchComponent;

    return (
      <>
        <MenuStyle />
        <AmountRangeComponent />
        <CurrencyComponent />
        <DateRangeComponent />
        <CompanySearchComponent />
      </>
    );
  }
}
