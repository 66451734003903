/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import Back from '@react/react-spectrum/Icon/ChevronLeft';
import Link from '@react/react-spectrum/Link';

const HEADING_VARIANT = 'subtitle1';
const STYLED_CLASS = 'styled';

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 10px 0 2px 0;
  margin-left: ${props => (props.compactLeftMargin ? '4px' : '16px')};

  & .spectrum-ActionButton {
    margin-top: 2px;
  }

  & .styled {
    color: #707070;

    a {
      color: inherit;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: #2c2c2c;
      }

      &.focus-ring {
        color: #378ef0;
        border-bottom: 2px solid #378ef0;
      }
    }

    b {
      color: #323232;
    }
  }
`;

const StyledHeading = styled(Heading)`
  font-size: 24px;
  font-weight: normal;
  white-space: nowrap;
`;

const SearchHeader = ({ onClick, title, searchTerm, startDate, endDate, intl }) => {
  let id = searchTerm ? 'tabletitle.search' : 'tabletitle';
  if (title) {
    id += '.drilldown';
  }
  return (
    <React.Fragment>
      <Button className="clear-search" quiet variant="action" icon={<Back />} onClick={onClick} />
      <StyledHeading variant={HEADING_VARIANT} className={`${STYLED_CLASS} search-header`}>
        <FormattedMessage
          id={id}
          values={{
            term: <b>{searchTerm}</b>,
            title: title
          }}
        />
      </StyledHeading>
    </React.Fragment>
  );
};

const DrillDownHeader = ({ onClick, context, title }) => (
  <StyledHeading variant={HEADING_VARIANT} className={`${STYLED_CLASS} drilldown-header`}>
    <FormattedMessage
      id="tabletitle.drilldown"
      values={{
        context: (
          <Link onClick={onClick} tabIndex="0">
            {context}
          </Link>
        ),
        title: <b>{title}</b>
      }}
    />
  </StyledHeading>
);

const Header = ({ title }) => <StyledHeading variant={HEADING_VARIANT}>{title}</StyledHeading>;

const TableHeader = props => {
  const isSearch = props.searchTerm;
  const isDrillDown = !isSearch && !!props.title;
  return (
    <MainContainer compactLeftMargin={isSearch}>
      {isSearch && <SearchHeader {...props} />}
      {isDrillDown && <DrillDownHeader {...props} />}
      {!(isSearch || isDrillDown) && <Header title={props.context} />}
    </MainContainer>
  );
};

TableHeader.propTypes = {
  onClick: PropTypes.func,
  context: PropTypes.string.isRequired,
  title: PropTypes.string,
  searchTerm: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  intl: PropTypes.shape.isRequired
};

// export the component with the localizations injected
export default injectIntl(TableHeader);
