/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this*/
/* eslint-disable arrow-parens */
import uuid from 'uuid';
import stores from '../stores';

const AdobeDC = require('adobe-dcapi-web');

const ALL_MATCHING = '*';
const AGREEMENT_SEARCH_PATH = 'search';

export default class AgreementSearchAPI {
  /**
   * Generate headers for search request
   *
   * @param shared - true when searching shared content, false when searching the
   *                 signed-in user's agreements
   */
  getPostOptions(shared) {
    // Restore the following when ASGW supports userid:{user_id} format
    // const headers = Object.assign(
    //   {
    //     'Content-Type': 'application/json'
    //   },
    //   stores.Api.getHeaders()
    // );
    const { accessToken, sharer } = stores.Api.instance.options;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'x-request-id': uuid.v4()
    };

    if (sharer) {
      headers['x-on-behalf-of-user'] = `email:${sharer.realEmail}`;
    }
    if (shared) {
      headers['x-ownership-scope'] = 'SHARED';
    }

    return { headers };
  }

  /**
   * Do an explicit search within the specified scopes (or folders)
   *
   * @param searchTerm - the string that the user is looking for
   * @param sortBy - the field to order the results by
   * @param sortOrder - either "asc" or "desc"
   * @param start - the first index to return
   * @param limit - the result count that is expected to be returned from uss
   * @param filterFacets - filters to include in the query
   * @param shared - pass true to search shared agreements, false to search user's agreements
   * @param fetchFields - the string or array of additional fields to be included in response in addition to default fields
   * @param sortMode - the sort mode for multi-valued fields such as termination_dates
   * @param metadataSearch - Search agreement payment metadata for JP ERRL. May include other metadata in the future.
   */
  search(
    searchTerm,
    sortBy,
    sortOrder,
    startIndex,
    pageSize,
    filterFacets,
    shared,
    fetchFields,
    sortMode,
    metadataSearch
  ) {
    const { baseUri } = stores.Api;
    const agreementSearchUri = stores.Api.getMicroserviceUri(AGREEMENT_SEARCH_PATH);

    const payload = {
      scope: ['agreements'],
      fetch_facets: true
    };

    if (sortBy) {
      payload.ordering = {
        mode: sortMode,
        orderby: sortBy,
        order: sortOrder
      };
    }

    payload.start_index = startIndex;
    payload.limit = pageSize;
    if (filterFacets) {
      filterFacets.forEach(filter => {
        const name = Object.keys(filter)[0];
        const filterValues = filter[name];
        if (!Array.isArray(filterValues) || filterValues.length > 0) {
          Object.assign(payload, filter);
        }
      });
    }
    if (searchTerm !== ALL_MATCHING) {
      payload.q = searchTerm;
    }

    if (stores.Env.locale) {
      payload.locale = stores.Env.locale.replace('-', '_'); // Search API needs underscores in locale
    }

    if (fetchFields) {
      payload.fetch_fields = { includes: fetchFields };
    }
    // https://wiki.corp.adobe.com/display/ES/Agreementsearch%3A+Support+ERRL+requirement+for+Japanese+market
    if (metadataSearch) {
      // Handle company name
      if (metadataSearch.payment_company) {
        payload.q = metadataSearch.payment_company;
        payload.queryable_fields = 'paymentCompanyName';
        payload.query_mode = 'BASIC';
      }
      // Handle currency
      if (metadataSearch.payment_currency?.length === 3) {
        payload.payment_currency = metadataSearch.payment_currency;
      }
      // Handle amount, supporting min, max, and range
      if (metadataSearch.payment_amount_min || metadataSearch.payment_amount_max) {
        const range = {};
        if (metadataSearch.payment_amount_min) {
          range.min = parseFloat(metadataSearch.payment_amount_min);
        }
        if (metadataSearch.payment_amount_max) {
          range.max = parseFloat(metadataSearch.payment_amount_max);
        }
        payload.payment_amount = { range };
      }
      // Handle date range
      const dateRange = {};
      if (metadataSearch.payment_start_date || metadataSearch.payment_end_date) {
        if (metadataSearch.payment_start_date) {
          dateRange.min = metadataSearch.payment_start_date;
        }
        if (metadataSearch.payment_end_date) {
          dateRange.max = metadataSearch.payment_end_date;
        }
        payload.payment_date = {
          range: dateRange
        };
      }
      payload.fetch_fields = { includes: ['payment'] };
    }

    const options = this.getPostOptions(shared);
    return (
      AdobeDC.http
        .post(agreementSearchUri, options, JSON.stringify(payload))
        .then(response => JSON.parse(response.content))
        .catch(err => {
          let errorCode, errorToken, errorTokenPos, errorMessage;
          try {
            const parsedErr = JSON.parse(err.details.content);
            errorCode = parsedErr.code;
            errorToken = parsedErr.details.errorToken;
            errorTokenPos = parsedErr.details.position;
            errorMessage = parsedErr.reasons[0];
          } catch (ignore) {} // eslint-disable-line no-empty
          return {
            error: `Error when calling Sign search: ${err.toString()}`,
            errorCode,
            errorToken,
            errorTokenPos,
            errorMessage
          };
        })
        // Include request id in the result, naming convention consistent with search response.
        .then(result => Object.assign(result, { request_id: options.headers['x-request-id'] }))
    );
  }
}
