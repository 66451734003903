import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { action, observable, computed } from 'mobx';
import styled from 'styled-components';
import Wait from '@react/react-spectrum/Wait';
import Heading from '@react/react-spectrum/Heading';
import { analyticsFor } from '../../utils/analytics';

const IMAGE_SIZE = 'FIXED_WIDTH_675px'; // Image size param for the /ImageUrls Rest API
const IMAGE_WIDTH = 675; // Fixed Image width
const VIEW_HEIGHT = 470; // Height of the div where the image is rendered

// Default page width and height
// This is in case /pageInfo does not return any page height/width
const PAGE_WIDTH = 612;
const PAGE_HEIGHT = 792;

const StyledWait = styled(Wait)`
  &&& {
    position: relative;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: relative;
  background-color: rgba(236, 130, 300, 1);
`;

const Overlays = styled.div`
  -webkit-filter: drop-shadow(0px 5px 7px #000000);
  filter: drop-shadow(0px 5px 5px #000000);
  opacity: 0.3;
`;

const NotFound = styled.div`
  margin-top: 30%;
`;

const TryAgain = styled.div`
  font-style: oblique;
`;

const StyledHeading = styled(Heading)`
  flex: 1 1 auto;
  margin-bottom: 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  outline: none;
  text-align: center;
  text-decoration: underline;
`;

const Link = styled.a`
  color: #2c2c2c !important;
`;

const PreviewSection = styled.div`
  padding: 40px;

  &.not-found {
    text-align: center;
    width: 675px;
  }
`;

const fetchImages = imageUrls => {
  return imageUrls.fetchUntil();
};

const fetchPagesInfo = pagesInfo => {
  return pagesInfo.fetch();
};

const analytics = analyticsFor(analyticsFor.REVIEW_END_DATE_ACTION);
const VIEW_AGREEMENT_NAME = 'view_agreement_name';
const EMPTY_LOCATIONS = 'EMPTY_LOCATIONS';

@observer
class Preview extends Component {
  @observable
  loading = true;

  @observable
  imageUrl;

  @observable
  datePositions;

  @observable
  pageDimensions;

  @computed
  get locations() {
    if (this.props.dateLocations) {
      return this.props.dateLocations === EMPTY_LOCATIONS ? '' : this.props.dateLocations.locations;
    }
    return '';
  }

  @computed
  get pageIndex() {
    const locations = this.locations;
    if (locations && locations.length !== 0) {
      return locations[0].page;
    }
    // By default return pageIndex - 0
    return 0;
  }

  @computed
  get offsetValues() {
    const locations = this.locations;
    let topFixed = 0;
    if (locations && locations.length > 0) {
      const locationsArray = [];
      locations.forEach((location, index) => {
        let backgroundPositionY = 0;
        const pageHeight = this.pageHeight,
          pageWidth = this.pageWidth;
        // Need to calculate image height as only the image width is fixed
        const imageHeight = (IMAGE_WIDTH / pageWidth) * pageHeight;
        const left = (location.left / pageWidth) * IMAGE_WIDTH;
        // Y coordinates go in the other direction in PDF user space, hence subtracting from page height
        const bottom = ((pageHeight - location.bottom) / pageHeight) * imageHeight;
        const right = (location.right / pageWidth) * IMAGE_WIDTH;
        let top = ((pageHeight - location.top) / pageHeight) * imageHeight;
        const width = right - left;
        const height = bottom - top;
        // Shift the background position Y coordinate of the view container up to show the end dates which are present
        // after top > 400, as the image height is 870px which we cannot show in the container at one time.
        if (top > 750) {
          backgroundPositionY = 680;
        } else if (top > 400) {
          backgroundPositionY = 350;
        }
        // If the backgroundPositionY has shifted up, the top value will also change w.r.t page
        top = top - backgroundPositionY;
        // If there are multiple lines, then all the next top positions for locations with index greater than 0 will have same top
        if (index === 0) {
          topFixed = top - 4;
        }
        // Add some padding to the coordinates for showing a nice rectangle!
        locationsArray.push({
          left: left - 4,
          top: topFixed,
          width: width + 8,
          height: index > 0 ? height : height + 8,
          backgroundPositionY: backgroundPositionY
        });
      });
      return locationsArray;
    }
    // Default when no locations are present
    return [];
  }

  @action
  setLoading(val) {
    this.loading = val;
  }

  @action
  updateImageUrl() {
    this.imageUrl = this.getImageUrl(this.pageIndex);
  }

  @action
  updatePageInfo() {
    this.pageDimensions = this.getPageInfo(this.pageIndex);
  }

  @action
  updateDatePositions() {
    this.datePositions = this.offsetValues;
  }

  constructor(props) {
    super(props);
    this.images = new props.Api.Agreements.Documents.ImageUrls(
      { id: this.props.agreementId },
      { imageSizes: [IMAGE_SIZE] }
    );

    this.pagesInfo = new props.Api.Agreements.CombinedDocument.PagesInfo({
      id: this.props.agreementId
    });
  }

  componentDidMount() {
    Promise.all([fetchImages(this.images), fetchPagesInfo(this.pagesInfo)])
      .then(() => {
        this.generateImageUrlArray();
        this.generatePagesInfoArray();
        this.updateImageUrl();
        this.updatePageInfo();
        this.updateDatePositions();
        this.setLoading(false);
      })
      .catch(error => {
        this.setLoading(false);
        this.props.showToast({
          type: 'error',
          message: error.message
        });
      });
  }

  // Update the view everytime the locations array changes in response to selecting other date through radio buttons
  componentDidUpdate(prevProps, prevState) {
    // this.props.dateLocations is empty for radio options - "no-end-date" and "custom-end-date"
    // this.props.dateLocations is equal to EMPTY_LOCATIONS when api returns no locations
    if (this.props.dateLocations && this.props.dateLocations !== prevProps.dateLocations) {
      this.updateImageUrl();
      this.updatePageInfo();
      this.updateDatePositions();
    }
  }

  get pageWidth() {
    return this.pageDimensions ? this.pageDimensions.get('width') : PAGE_WIDTH;
  }

  get pageHeight() {
    return this.pageDimensions ? this.pageDimensions.get('height') : PAGE_HEIGHT;
  }

  getImageUrl(pageIndex) {
    return this.imageUrlArray ? this.imageUrlArray[pageIndex] : '';
  }

  getPageInfo(pageIndex) {
    return this.pagesInfoArray ? this.pagesInfoArray[pageIndex] : '';
  }

  /**
   * Generate imageUrlArray Array
   * index of array: pageIndex
   * value of array: imageUrl of page
   */
  generateImageUrlArray() {
    this.imageUrlArray = [];
    const originalDocuments = this.images.get('original');
    if (!originalDocuments.length) {
      return;
    }
    originalDocuments.each(document => {
      const imageList = document.getList();
      const item = imageList.findWhere({ imageSize: IMAGE_SIZE });
      const urls = item.get('imageURLs');
      urls.each(model => this.imageUrlArray.push(model.get('url')));
    });
  }

  /**
   * Generate pagesInfoArray Array
   * index of array: pageIndex
   * value of array: {
   *   height:
   *   index:
   *   rotation:
   *   width:
   * }
   */
  generatePagesInfoArray() {
    this.pagesInfoArray = [];
    const documentsInfo = this.pagesInfo.documentsPagesInfo;
    if (!documentsInfo.length) {
      return;
    }
    documentsInfo.each(model => this.pagesInfoArray.push(model));
  }

  viewFullAgreement() {
    if (!this.props.agreementUrl) {
      return;
    }

    analytics.setContext({ action: VIEW_AGREEMENT_NAME });
    analytics.send();
    window.open(this.props.agreementUrl, '_blank');
  }

  renderNotFound() {
    const { intl } = this.props;
    if (this.el && this.el.className) {
      this.el.className += ' not-found';
    }
    return (
      <NotFound>
        <Heading variant="pageTitle">{intl.formatMessage({ id: 'end_date_dialog.preview_not_found' })}</Heading>
        <TryAgain>{intl.formatMessage({ id: 'end_date_dialog.try_again_later' })}</TryAgain>
      </NotFound>
    );
  }

  getOverlays(values) {
    const overlays = [];
    values.forEach(value => {
      overlays.push(
        <Overlay
          style={{
            left: value.left,
            top: value.top,
            width: value.width,
            height: value.height
          }}
        />
      );
    });
    return overlays.length > 0 ? overlays : null;
  }

  renderImage() {
    const values = this.datePositions;
    const showPreview = this.imageUrl && values.length > 0;
    const showOverlays = showPreview && !!this.props.dateLocations;
    const backgroundPositionY = values.length > 0 ? values[0].backgroundPositionY : 0;
    return showPreview ? (
      <Image
        style={{
          width: `${IMAGE_WIDTH}px`,
          height: `${VIEW_HEIGHT}px`,
          backgroundImage: `url(${this.imageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: `0px -${backgroundPositionY}px`
        }}
      >
        <Overlays>{showOverlays && this.getOverlays(values)}</Overlays>
      </Image>
    ) : (
      this.renderNotFound()
    );
  }

  render() {
    const { agreementName } = this.props;
    return (
      <PreviewSection ref={el => (this.el = el)}>
        <StyledHeading variant="subtitle1">
          <Link href="#" onClick={() => this.viewFullAgreement()}>
            {agreementName}
          </Link>
        </StyledHeading>
        {this.loading ? <StyledWait centered /> : this.renderImage()}
      </PreviewSection>
    );
  }
}

Preview.propTypes = {
  intl: PropTypes.shape.isRequired,
  agreementId: PropTypes.string.isRequired,
  agreementName: PropTypes.string.isRequired,
  agreementUrl: PropTypes.string.isRequired,
  showToast: PropTypes.func.isRequired,
  Api: PropTypes.objectOf(PropTypes.any).isRequired,
  dateLocations: PropTypes.objectOf(PropTypes.any).isRequired
};

export default injectIntl(Preview);
