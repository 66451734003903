/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TabList, Tab } from '@react/react-spectrum/TabList';

const StyledTabList = styled(TabList)`
  b {
    font-size: 18px;
  }
`;

const StatusTabList = ({ className, data, onChange }) => {
  // Map TabList onChange index to data value
  // we can't use @bindAndCatchAndLog here
  const onSelectionChange = index => {
    if (index !== -1) {
      // with the updated react spectrum, this is called after a search, when it wasn't before
      // if the value is -1 we don't change the selected tab (previous behavior)
      // however if you are in a non permanent tab your tab may disapear after the search and you'll
      // see the empty results component with no tab visibly selected and results in one of the other tabs
      // this is consistent with how it behaved before the dc-core upgrade.
      // a change to this should be a new story / task / bug
      onChange(data[index].value, data[index].count);
    }
  };

  // Find the tab that should be selected by default
  const isSelected = tab => tab.selected;

  return (
    <StyledTabList
      selectedIndex={data.findIndex(isSelected)}
      variant="compact"
      className={className}
      onChange={onSelectionChange}
      keyboardActivation="manual"
    >
      {data.map(tab => (
        <Tab key={tab.value}>
          <FormattedMessage id={tab.labelKey} values={{ count: <b>{tab.count}</b> }} />
        </Tab>
      ))}
    </StyledTabList>
  );
};

StatusTabList.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func
};

// export the component with the localizations injected
export default injectIntl(StatusTabList);
