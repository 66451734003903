import { sanitizeDate } from './StringUtil';

function calcDateRangeFromRangeType(rangeType) {
  const today = new Date();
  switch (rangeType) {
    case 'day':
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return { startDate: yesterday.toISOString(), endDate: today.toISOString() };
    case 'week':
      const lastWeek = new Date();
      lastWeek.setDate(lastWeek.getDate() - 7);
      return { startDate: lastWeek.toISOString(), endDate: today.toISOString() };
    case 'month':
      const lastMonth = new Date();
      lastMonth.setDate(lastMonth.getDate() - 30);
      return { startDate: lastMonth.toISOString(), endDate: today.toISOString() };
    case 'year':
      const lastYear = new Date();
      lastYear.setFullYear(lastYear.getFullYear() - 1);
      return { startDate: lastYear.toISOString(), endDate: today.toISOString() };
    default:
      return { startDate: undefined, endDate: undefined };
  }
}

function millisSinceEpochDateOnly(date) {
  return new Date(date).setHours(0, 0, 0, 0);
}

function isSameDate(startDate, endDate) {
  return millisSinceEpochDateOnly(startDate) === millisSinceEpochDateOnly(endDate);
}

function formatDateForDisplay(date, intl) {
  return sanitizeDate(intl.formatDate(date, { day: 'numeric', month: 'short', year: 'numeric' }));
}

function daysUntilDate(date) {
  const today = millisSinceEpochDateOnly(new Date());
  const target = millisSinceEpochDateOnly(date);
  const DAY_IN_MILLIS = 1000 * 60 * 60 * 24;
  return Math.ceil((target - today) / DAY_IN_MILLIS);
}

function isFutureDate(date) {
  return millisSinceEpochDateOnly(date) >= millisSinceEpochDateOnly(new Date());
}

export { calcDateRangeFromRangeType, isSameDate, formatDateForDisplay, daysUntilDate, isFutureDate };
