/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Link from '@react/react-spectrum/Link';
import TruncatedText from 'dc-shared-ui-components/TruncatedText';
import styled from 'styled-components';

const FIXED_HEIGHT = 18;

const OverflowContainer = styled.div`
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  height: ${props => props.height + 'px'};
`;

const CompositeActionCell = ({ id, name, status, hasFocus, onClick, intl }) => {
  const tabIndex = hasFocus ? 0 : -1;
  return (
    <OverflowContainer className="composite-action">
      <Row height={FIXED_HEIGHT}>
        <TruncatedText className="name-text" title="name text" text={name} placement="middle" height={FIXED_HEIGHT} />
      </Row>
      <Row height="21">
        <Link className="action" tabIndex={tabIndex} onClick={() => onClick(id)}>
          {intl.formatMessage({ id: `action.${status.toLowerCase()}` })}
        </Link>
      </Row>
    </OverflowContainer>
  );
};

CompositeActionCell.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  hasFocus: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  intl: PropTypes.shape.isRequired
};

// export the component with the localizations injected
export default injectIntl(CompositeActionCell);
