/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { media } from '../../utils/mediaQuery';

const BannerInfo = styled.div`
  background: #747474;
  color: #fff;
  padding: 16px 0;
  text-align: center;

  @media ${media.phone} {
    font-size: 0.9em;
  }
`;

@injectIntl
class DeletedFolderBanner extends React.Component {
  render() {
    return (
      <BannerInfo className="deleted-folder-banner">
        {this.props.intl.formatMessage({ id: 'deleted_folder.banner' })}
      </BannerInfo>
    );
  }
}

export default DeletedFolderBanner;
