/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Link from '@react/react-spectrum/Link';

const ActionTableCell = ({ id, status, hasFocus, onClick, intl }) => {
  const tabIndex = hasFocus ? 0 : -1;
  return (
    <Link className="action-table-cell" tabIndex={tabIndex} onClick={() => onClick(id)}>
      {intl.formatMessage({ id: `action.${status.toLowerCase()}` })}
    </Link>
  );
};

ActionTableCell.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  hasFocus: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  intl: PropTypes.shape.isRequired
};

// export the component with the localizations injected
export default injectIntl(ActionTableCell);
