/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2018 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

const agreement = ['ESIGN', 'WIDGET_INSTANCE', 'ARCHIVE', 'FILL_SIGN', 'MEGASIGN_CHILD'];
const template = ['LIBRARY_TEMPLATE'];
const webform = ['WIDGET'];
const megasign = ['MEGASIGN_PARENT'];

const allStates = [
  'OUT_FOR_SIGNATURE',
  'OUT_FOR_APPROVAL',
  'OUT_FOR_DELIVERY',
  'OUT_FOR_ACCEPTANCE',
  'OUT_FOR_FORM_FILLING',
  'OUT_FOR_WITNESSING',
  'WAITING_FOR_MY_SIGNATURE',
  'WAITING_FOR_MY_APPROVAL',
  'WAITING_FOR_MY_DELEGATION',
  'WAITING_FOR_MY_ACKNOWLEDGEMENT',
  'WAITING_FOR_MY_ACCEPTANCE',
  'WAITING_FOR_MY_FORM_FILLING',
  'WAITING_FOR_PREFILL',
  'SIGNED',
  'APPROVED',
  'DELIVERED',
  'ACCEPTED',
  'FORM_FILLED',
  'RECALLED',
  'EXPIRED',
  'ARCHIVED',
  'DRAFT',
  'WAITING_FOR_AUTHORING',
  'WAITING_FOR_NOTARIZATION'
];

const filterGroupingData = {
  agreement_type: {
    groups: {
      all: [].concat(agreement, template, webform, megasign),
      agreement,
      template,
      webform,
      megasign
    }
  },
  agreement_consolidated_type: {
    groups: {
      webform: ['WIDGET', 'WIDGET_INSTANCE'],
      megasign: ['MEGASIGN_PARENT', 'MEGASIGN_CHILD']
    }
  },
  agreement_child_type: {
    groups: {
      webform: ['WIDGET_INSTANCE'],
      megasign: ['MEGASIGN_CHILD']
    }
  },
  state: {
    groups: {
      all: allStates,
      waiting_for_others: [
        'OUT_FOR_SIGNATURE',
        'OUT_FOR_APPROVAL',
        'OUT_FOR_DELIVERY',
        'OUT_FOR_ACCEPTANCE',
        'OUT_FOR_FORM_FILLING', // this this is wrong? it could be waiting for you, dig sig fill sign returning user
        'OUT_FOR_WITNESSING',
        'WAITING_FOR_NOTARIZATION'
      ],
      waiting_for_you: [
        'WAITING_FOR_MY_SIGNATURE',
        'WAITING_FOR_MY_APPROVAL',
        'WAITING_FOR_MY_DELEGATION',
        'WAITING_FOR_MY_ACKNOWLEDGEMENT',
        'WAITING_FOR_MY_ACCEPTANCE',
        'WAITING_FOR_MY_FORM_FILLING',
        'WAITING_FOR_PREFILL'
      ],
      completed: ['SIGNED', 'APPROVED', 'DELIVERED', 'ACCEPTED', 'FORM_FILLED'],
      cancelled: [
        'RECALLED' // not listed in XD
      ],
      expired: ['EXPIRED'],
      archive: ['ARCHIVED'],
      draft: ['DRAFT', 'WAITING_FOR_AUTHORING'],
      legacy_draft: ['DRAFT', 'WAITING_FOR_AUTHORING'],
      deleted: allStates
    }
  },
  termination_dates_confirmed: {
    groups: {
      all: ['true', 'false'],
      confirmed: ['true'],
      unconfirmed: ['false']
    }
  }
};

class FilterHelper {
  static filterGroups(fieldId) {
    return filterGroupingData[fieldId] ? filterGroupingData[fieldId].groups : {};
  }

  // return group only with prefix for all type, agreement_type.agreement
  static filterGroupsWithPrefix(fieldId) {
    const filterGroup = FilterHelper.filterGroups(fieldId);
    const filterGroupPrefix = Object.keys(filterGroup).map(key => {
      const prefixedKey = `${fieldId}.${key}`;
      return { [prefixedKey]: filterGroup[key] };
    });
    return Object.assign({}, ...filterGroupPrefix);
  }

  // return the format that the filter manager wants
  static transformToFilter(filter) {
    if (!filter) {
      return [];
    }
    const values = Object.values(filter);
    return Array.from(Object.keys(filter), (filterKey, i) => ({ [filterKey]: values[i] }));
  }

  static findGroupNameBy(agreementType) {
    const excludeAll = group => group !== 'all';
    const groups = filterGroupingData.agreement_type.groups;
    return Object.keys(groups)
      .filter(excludeAll)
      .reduce((memo, key) => {
        if (groups[key].includes(agreementType)) {
          memo = key;
        }
        return memo;
      });
  }

  static findGrouping(grouping, value) {
    const excludeAll = group => group !== 'all';
    const groups = filterGroupingData[grouping].groups;
    return Object.keys(groups)
      .filter(excludeAll)
      .find(key => groups[key].includes(value));
  }

  static shouldUseConsolidatedGroup(useSearchOrFilter, agreementType) {
    return useSearchOrFilter && ['webform', 'megasign'].includes(agreementType);
  }
}

export default FilterHelper;
