/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import BouncedIcon from '../../BouncedIcon/BouncedIcon.js';
import stores from '../../../stores';

const TruncatedText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledIconEnclosingDiv = styled.div`
  height: 18px;
  width: 18px;
  margin-right: 4px;
`;

const StatusTableCell = ({ messageId, intl, isUnresolvedBounceAvailable, data, isSharedAgreement }) => {
  const status = intl.formatMessage({ id: messageId }),
    bouncedIconTooltipAndAriaLabelText = intl.formatMessage({
      id: 'notification_bounced_back_message'
    });

  let initialShowBouncedIcon;
  if (isSharedAgreement) {
    initialShowBouncedIcon = false;
  } else {
    initialShowBouncedIcon = isUnresolvedBounceAvailable && data.has_unresolved_bounce;
  }

  const [showBouncedIcon, setShowBouncedIcon] = useState(initialShowBouncedIcon);

  /**
   * Code to run on component mount and invoked after render
   */
  useEffect(() => {
    const fetchData = async () => {
      // Get UnresolvedBounceIndicatorFeature setting value from group level (for shared agreements)
      const sharedSettingValue = await stores.UnresolvedBounceIndicatorFeatureForGroup.get(
        data.user_id,
        data.group_id_set[0]
      );
      setShowBouncedIcon(sharedSettingValue);
    };
    // If the agreement is shared, get the setting value from the group level of shared user
    if (data.has_unresolved_bounce === true && isSharedAgreement && stores.UnresolvedBounceIndicatorFeatureForGroup) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Code to run on component mount and invoked after has_unresolved_bounce (depedency) is changed
   */
  useEffect(() => {
    if (showBouncedIcon && !data.has_unresolved_bounce) {
      setShowBouncedIcon(data.has_unresolved_bounce);
    }
  }, [showBouncedIcon, data.has_unresolved_bounce]);

  return (
    <TruncatedText
      className="status-table-cell"
      title={status}
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {showBouncedIcon && (
        <StyledIconEnclosingDiv
          role="alert"
          data-automation-id="bounced-icon-md"
          title={bouncedIconTooltipAndAriaLabelText}
          aria-label={bouncedIconTooltipAndAriaLabelText}
        >
          <BouncedIcon />
        </StyledIconEnclosingDiv>
      )}
      {status}
    </TruncatedText>
  );
};

StatusTableCell.propTypes = {
  messageId: PropTypes.string.isRequired,
  intl: PropTypes.shape.isRequired,
  isUnresolvedBounceAvailable: PropTypes.bool.isRequired,
  isSharedAgreement: PropTypes.bool.isRequired,
  data: PropTypes.shape.isRequired
};

// export the component with the localizations injected
export default injectIntl(StatusTableCell);
