/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

const defaultColumns = {
  FULL: ['active_participant', 'name', 'user_name', 'modify_date'],
  MIN: ['name', 'modify_date'],
  COMPOSITE: ['composite_participant', 'composite_name']
};

// Columns to show when viewing agreements of a particular type/state
const Columns = {
  all: {
    FULL: [
      'active_participant',
      'sender',
      'name',
      'state',
      'user_name',
      'modify_date',
      'composite_participant',
      'composite_resource_status_date'
    ],
    MIN: ['name', 'state'],
    COMPOSITE_MIN: ['composite_participant', 'composite_resource_status_date']
  },
  agreement: {
    waiting_for_others: {
      FULL: ['active_participant', 'name', 'state', 'user_name', 'modify_date', 'composite_name_status_date'],
      MIN: ['active_participant', 'state'],
      COMPOSITE_MIN: ['active_participant', 'composite_name_status_date']
    },
    waiting_for_you: {
      FULL: ['sender', 'name', 'action', 'user_name', 'modify_date', 'composite_action'],
      MIN: ['sender', 'action'],
      COMPOSITE_MIN: ['sender', 'composite_action']
    },
    completed: {
      FULL: ['active_participant', 'sender', 'name', 'user_name', 'modify_date', ...defaultColumns.COMPOSITE],
      MIN: defaultColumns.MIN,
      COMPOSITE_MIN: defaultColumns.COMPOSITE
    },
    cancelled: {
      FULL: ['active_participant', 'sender', 'name', 'user_name', 'modify_date', ...defaultColumns.COMPOSITE],
      MIN: defaultColumns.MIN,
      COMPOSITE_MIN: defaultColumns.COMPOSITE
    },
    expired: {
      FULL: ['active_participant', 'sender', 'name', 'user_name', 'modify_date', ...defaultColumns.COMPOSITE],
      MIN: defaultColumns.MIN,
      COMPOSITE_MIN: defaultColumns.COMPOSITE
    },
    archive: {
      FULL: ['active_participant', 'name', 'user_name', 'modify_date', 'composite_name'],
      MIN: ['name', 'modify_date'],
      COMPOSITE_MIN: ['active_participant', 'composite_name']
    },
    draft: {
      FULL: ['active_participant', 'name', 'user_name', 'modify_date', 'composite_name'],
      MIN: ['name', 'modify_date'],
      COMPOSITE_MIN: ['active_participant', 'composite_name']
    },
    legacy_draft: {
      FULL: ['active_participant', 'name', 'user_name', 'modify_date', 'composite_name'],
      MIN: ['name', 'modify_date'],
      COMPOSITE_MIN: ['active_participant', 'composite_name']
    },
    deleted: {
      FULL: ['active_participant', 'name', 'state', 'modify_date'],
      MIN: ['name', 'modify_date'],
      COMPOSITE_MIN: ['active_participant', 'composite_name']
    }
  },
  template: {
    FULL: ['name', 'composite_name', 'state', 'user_name', 'modify_date'],
    MIN: ['name', 'state'],
    COMPOSITE_MIN: ['composite_name', 'state']
  },
  webform: {
    FULL: ['name', 'composite_name', 'state', 'user_name', 'modify_date'],
    MIN: ['name', 'state'],
    COMPOSITE_MIN: ['composite_name', 'state']
  },
  megasign: {
    FULL: ['name', 'composite_name', 'state', 'user_name', 'modify_date'],
    MIN: ['name', 'state'],
    COMPOSITE_MIN: ['composite_name', 'state']
  }
};

// Columns showing Group Information (UMG Enabled view)
const ColumnsWithGroup = {
  all: {
    FULL: [
      'active_participant',
      'sender',
      'name',
      'state',
      'group_id_set',
      'user_name',
      'modify_date',
      'composite_participant',
      'composite_resource_status_date'
    ],
    MIN: Columns.all.MIN,
    COMPOSITE_MIN: Columns.all.COMPOSITE_MIN
  },
  agreement: {
    waiting_for_others: {
      FULL: [
        'active_participant',
        'name',
        'state',
        'group_id_set',
        'user_name',
        'modify_date',
        'composite_participant',
        'composite_name_status_date'
      ],
      MIN: Columns.agreement.waiting_for_others.MIN,
      COMPOSITE_MIN: Columns.agreement.waiting_for_others.COMPOSITE_MIN
    },
    waiting_for_you: Columns.agreement.waiting_for_you,
    completed: {
      FULL: [
        'active_participant',
        'sender',
        'name',
        'group_id_set',
        'user_name',
        'modify_date',
        ...defaultColumns.COMPOSITE
      ],
      MIN: defaultColumns.MIN,
      COMPOSITE_MIN: defaultColumns.COMPOSITE
    },
    cancelled: {
      FULL: [
        'active_participant',
        'sender',
        'name',
        'group_id_set',
        'user_name',
        'modify_date',
        ...defaultColumns.COMPOSITE
      ],
      MIN: defaultColumns.MIN,
      COMPOSITE_MIN: defaultColumns.COMPOSITE
    },
    expired: {
      FULL: [
        'active_participant',
        'sender',
        'name',
        'group_id_set',
        'user_name',
        'modify_date',
        ...defaultColumns.COMPOSITE
      ],
      MIN: defaultColumns.MIN,
      COMPOSITE_MIN: defaultColumns.COMPOSITE
    },
    archive: {
      FULL: ['active_participant', 'name', 'user_name', 'modify_date', 'composite_name'],
      MIN: defaultColumns.MIN,
      COMPOSITE_MIN: ['active_participant', 'composite_name']
    },
    draft: {
      FULL: ['active_participant', 'name', 'group_id_set', 'user_name', 'modify_date', 'composite_name'],
      MIN: defaultColumns.MIN,
      COMPOSITE_MIN: ['active_participant', 'composite_name']
    },
    deleted: {
      FULL: ['active_participant', 'name', 'group_id_set', 'user_name', 'modify_date', 'composite_name'],
      MIN: defaultColumns.MIN,
      COMPOSITE_MIN: ['active_participant', 'composite_name']
    }
  },
  template: {
    FULL: ['name', 'composite_name', 'state', 'group_id_set', 'user_name', 'modify_date'],
    MIN: Columns.template.MIN,
    COMPOSITE_MIN: Columns.template.COMPOSITE_MIN
  },
  webform: {
    FULL: ['name', 'composite_name', 'state', 'group_id_set', 'user_name', 'modify_date'],
    MIN: Columns.webform.MIN,
    COMPOSITE_MIN: Columns.webform.COMPOSITE_MIN
  },
  megasign: {
    FULL: ['name', 'composite_name', 'state', 'group_id_set', 'user_name', 'modify_date'],
    MIN: Columns.megasign.MIN,
    COMPOSITE_MIN: Columns.megasign.COMPOSITE_MIN
  }
};

// Columns to show when viewing the child agreements of a particular type (14.1+ release)
const ChildColumns = {
  webform: {
    waiting_for_you: {
      FULL: ['active_participant', 'action', 'user_name', 'modify_date', 'composite_name_status_date'],
      MIN: ['active_participant', 'action'],
      COMPOSITE_MIN: ['active_participant', 'action']
    },
    FULL: ['active_participant', 'state', 'user_name', 'modify_date'],
    MIN: ['active_participant', 'state'],
    COMPOSITE_MIN: ['active_participant', 'state']
  },
  megasign: {
    waiting_for_you: {
      FULL: ['active_participant', 'name', 'action', 'user_name', 'modify_date'],
      MIN: ['active_participant', 'action'],
      COMPOSITE_MIN: ['active_participant', 'action']
    },
    FULL: ['active_participant', 'name', 'state', 'user_name', 'modify_date'],
    MIN: ['active_participant', 'state'],
    COMPOSITE_MIN: ['active_participant', 'state']
  }
};

// Columns to show when viewing agreements in the "Ending soon" category
const EndDateColumns = {
  agreement: {
    FULL: [
      'active_participant',
      'name',
      'modify_date',
      'termination_dates',
      'termination_dates_confirmed',
      ...defaultColumns.COMPOSITE
    ],
    MIN: ['name', 'termination_dates'],
    COMPOSITE_MIN: defaultColumns.COMPOSITE
  }
};

// Columns to show when viewing agreements in the "Ending soon" category with calendar icon
const EndDateColumnsWithCalendar = {
  agreement: {
    FULL: [
      'active_participant',
      'name',
      'modify_date',
      'termination_dates',
      'termination_dates_calendar',
      'termination_dates_confirmed',
      ...defaultColumns.COMPOSITE
    ],
    MIN: ['name', 'termination_dates'],
    COMPOSITE_MIN: defaultColumns.COMPOSITE
  }
};

class ColumnUtil {
  static getColumns(isChild, showGroup, isEndDate, showCalendar) {
    if (isChild) {
      return ChildColumns;
    }
    if (isEndDate) {
      return showCalendar ? EndDateColumnsWithCalendar : EndDateColumns;
    }
    return showGroup ? ColumnsWithGroup : Columns;
  }

  // Get Column by type or state
  static getColumnsByTypeOrState(
    agreementType,
    agreementState,
    isChild,
    showGroup,
    showComposite,
    isEndDate,
    showCalendar
  ) {
    const columns = ColumnUtil.getColumns(isChild, showGroup, isEndDate, showCalendar);
    const descriptor = columns[agreementType][agreementState] || columns[agreementType];
    return { FULL: descriptor.FULL, MIN: showComposite ? descriptor.COMPOSITE_MIN : descriptor.MIN };
  }
}

export default ColumnUtil;
