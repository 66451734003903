/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/* eslint no-unused-vars:0, camelcase:0 */

import { locale2 } from 'dc-core';

const getMessage = key =>
  new Promise(resolve => {
    const loc = locale2.getLocale();
    import(`../translations/${loc}.json` /* webpackChunkName: "translations-[request]" */)
      .then(translations => translations.default)
      .then(translations => {
        const translation = translations[key];
        if (translation) {
          resolve(translation);
        } else {
          throw new Error(`No translation for ${key}`);
        }
      });
  });

const getMessages = loc => {
  return import(`../translations/${loc}.json` /* webpackChunkName: "translations-[request]" */).then(
    translations => translations.default
  );
};

export { getMessage, getMessages };
