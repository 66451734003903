import logger from '../utils/logger';

/**
 * User Information: /users/<id>
 */
export default class User {
  constructor(Api) {
    this.user = new Api.Users.User({ id: 'me' });
    this.promise = null;
  }

  get accountId() {
    return this.user.get('accountId');
  }

  get fullName() {
    return `${this.user.get('firstName')} ${this.user.get('lastName')}`.trim();
  }

  fetch() {
    this.promise = this.user
      .fetch({
        headers: {
          // The /users/<id> endpoint does not accept the x-on-behalf-of-user header.
          // Current usage of this store is to fetch the account id of the signed-in
          // user so we just make sure the header doesn't include the sharer's id.
          'x-on-behalf-of-user': ''
        }
      })
      .then(() => {
        this.userInfoAvailable = true;
        return this;
      })
      .catch(e => {
        this.userInfoAvailable = false;
        logger.warn('Unable to get user info', { error: e });
      });
  }

  ready() {
    return this.promise;
  }
}
