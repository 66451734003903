/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe and its
 * suppliers and are protected by all applicable intellectual property laws,
 * including trade secret and or copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import styled from 'styled-components';
import Heading from '@react/react-spectrum/Heading';
import { Accordion, AccordionItem } from '@react/react-spectrum/Accordion';
import Button from '@react/react-spectrum/Button';
import Checkbox from '@react/react-spectrum/Checkbox';
import DateFilter from '../../component/DateFilter';
import PaymentFilter from '../../component/PaymentFilter';
import { analyticsFor } from '../../utils/analytics';
import { AccordianHeaderWithBadge } from '../AccordianWithBadge';

const FilterPanelContainer = styled.div`
  overflow-y: auto;
  width: 270px;
  padding: 24px;
  padding-right: 0;

  & .spectrum-Toast {
    display: inline-flex !important;
  }

  & .spectrum-CircleLoader {
    display: block !important;
  }

  & .context_board_single {
    display: block !important;
  }
`;

const ResetButtonContainer = styled.div`
  margin-top: 24px;
  margin-right: 16px;
  text-align: right;
`;

const StyledCheckbox = styled(Checkbox)`
  padding-top: 6px;
`;

const resetCallbacks = [];

export const FilterPanel = props => {
  const paymentFilterCount = () => {
    const filters = [
      !!props.paymentAmountMin,
      !!props.paymentAmountMax && props.paymentAmountMax !== props.paymentAmountMin,
      !!props.paymentCurrency,
      !!props.paymentStartDate,
      !!props.paymentEndDate && props.paymentEndDate !== props.paymentStartDate,
      !!props.paymentCompany
    ].filter(x => x);
    return filters.length;
  };
  // If the list is completed, we want to enable the payment filter
  const paymentFilterShown = props.allFacets?.completed?.count > 0 || paymentFilterCount() > 0;
  /**
   * DCSIA-12220 - Create a deep link into the filter panel for Sign Manage page to allow desktop clients to access the filters directly
   * Currently the filter panel only shows the date filter and the payment filter. If we add more filters in the future,
   * we need to update the selectedIndex array to include the new filter index based on the parameter passed in the
   * `filter_panel` query parameter.
   */
  const selectedIndex = paymentFilterShown ? [0, 1] : [0];
  const accordionProps = {
    multiselectable: true,
    defaultSelectedIndex: selectedIndex
  };

  const registerResetCallback = callback => {
    resetCallbacks.push(callback);
  };

  const resetFilters = () => {
    props.sendAnalytics(analyticsFor.FILTER_RESET);
    props.routingUtil.setDateRange(undefined, undefined, undefined);
    props.routingUtil.setVisibilityParam(undefined);
    props.routingUtil.setPaymentParams(undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    resetCallbacks.forEach(callback => callback());
  };

  const dateHeaderCount = () => {
    const filters = [
      !!(props.startDate || props.endDate || props.rangeType),
      props.visibility === 'SHOW_HIDDEN'
    ].filter(x => x);
    return filters.length;
  };

  const DateHeader = (
    <AccordianHeaderWithBadge
      count={dateHeaderCount()}
      title={props.intl.formatMessage({ id: 'filter.date' })}
      type="date"
    />
  );

  const PaymentFilterHeader = (
    <AccordianHeaderWithBadge
      count={paymentFilterCount()}
      title={props.intl.formatMessage({ id: 'payment_info.filter_heading' })}
      type="payment"
    />
  );

  return (
    <FilterPanelContainer>
      <Heading variant="subtitle2">{props.intl.formatMessage({ id: 'payment_info.filter_by_heading' })}</Heading>
      <Accordion {...accordionProps}>
        <AccordionItem header={DateHeader}>
          <DateFilter
            onChange={(rangeType, startDate, endDate) => {
              props.routingUtil.setDateRange(rangeType, startDate, endDate);
            }}
            sendAnalytics={props.sendAnalytics}
            rangeType={props.rangeType}
            startDate={props.startDate}
            endDate={props.endDate}
            data-testid="date-filter"
          />
          <StyledCheckbox
            label={props.intl.formatMessage({ id: 'filter.visibility.checkbox' })}
            checked={props.visibility === 'SHOW_HIDDEN'}
            onChange={checked => {
              let visibility = checked ? 'SHOW_HIDDEN' : 'SHOW_VISIBLE';
              props.routingUtil.setVisibilityParam(visibility);
              props.routingUtil.push();
            }}
            data-testid="show-hidden-checkbox"
          />
        </AccordionItem>
        <AccordionItem header={PaymentFilterHeader}>
          <PaymentFilter
            data-testid="payment-filter"
            onChange={(rangeType, startDate, endDate, amountMin, amountMax, currency, company) => {
              props.routingUtil.setPaymentParams(
                rangeType,
                startDate,
                endDate,
                amountMin,
                amountMax,
                currency,
                company
              );
            }}
            paymentAmountMin={props.paymentAmountMin}
            paymentAmountMax={props.paymentAmountMax}
            paymentCurrency={props.paymentCurrency}
            paymentDateRangeType={props.paymentDateRangeType}
            paymentStartDate={props.paymentStartDate}
            paymentEndDate={props.paymentEndDate}
            paymentCompany={props.paymentCompany}
            routingUtil={props.routingUtil}
            intl={props.intl}
            registerResetCallback={registerResetCallback}
            sendAnalytics={props.sendAnalytics}
          />
        </AccordionItem>
      </Accordion>
      <ResetButtonContainer>
        <Button data-testid="filter-panel-reset" onClick={() => resetFilters()}>
          {props.intl.formatMessage({ id: 'payment_info.reset' })}
        </Button>
      </ResetButtonContainer>
    </FilterPanelContainer>
  );
};
