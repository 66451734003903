/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { injectIntl } from 'react-intl';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import Select from '@react/react-spectrum/Select';
import CalendarDialog from './CalendarDialog';
import { media } from '../../utils/mediaQuery';
import { analyticsFor } from '../../utils/analytics';
import bindAndCatchAndThrow from '../../utils/bindAndCatchAndThrow';
import { formatDateForDisplay, isSameDate, calcDateRangeFromRangeType } from '../../utils/DateUtil';

const StyledSelect = styled(Select)`
  width: 100% !important;
`;

const MenuStyle = createGlobalStyle`
  @media ${media.zoom400Percent} {
    .date-filter-menu { max-height: 31px; }
  }
`;

class DateFilter extends React.Component {
  get value() {
    const { rangeType, startDate, endDate } = this.props;
    return !rangeType && startDate && endDate ? 'custom' : rangeType;
  }

  @bindAndCatchAndThrow
  handleDatePick({ startDate, endDate }) {
    const { onChange, sendAnalytics } = this.props;
    sendAnalytics(analyticsFor.DATE_PICKER_CHANGED);
    onChange('custom', startDate, endDate);
  }

  @bindAndCatchAndThrow
  selectOnChange(newValue) {
    const { onChange, sendAnalytics } = this.props;
    sendAnalytics(analyticsFor.DATE_FILTER_SELECTED, { rangeType: newValue });
    switch (newValue) {
      case 'day':
      case 'week':
      case 'month':
      case 'year':
        const { startDate, endDate } = calcDateRangeFromRangeType(newValue);
        onChange(newValue, startDate, endDate);
        break;
      case 'custom':
        this.showCalendarDialog();
        break;
      default:
        onChange();
    }
  }

  showCalendarDialog() {
    const { rangeType, startDate, endDate } = this.props,
      container = window.document.body;

    ModalContainer.show(
      <CalendarDialog
        onChange={this.handleDatePick}
        startDate={rangeType ? undefined : startDate}
        endDate={rangeType ? undefined : endDate}
      />,
      this,
      container
    );
  }

  render() {
    const { rangeType, startDate, endDate, intl } = this.props;

    let id = 'daterange.custom';
    let startDisplay, endDisplay;
    if (rangeType === 'custom' && startDate) {
      id = isSameDate(startDate, endDate) ? 'filter.date.custom.day' : 'filter.date.custom.range';
      startDisplay = formatDateForDisplay(startDate, intl);
      endDisplay = formatDateForDisplay(endDate, intl);
    }

    const selectOptions = [
      { label: intl.formatMessage({ id: 'daterange.all' }), value: 'all' },
      { label: intl.formatMessage({ id: 'daterange.day' }), value: 'day' },
      { label: intl.formatMessage({ id: 'daterange.week' }), value: 'week' },
      { label: intl.formatMessage({ id: 'daterange.month' }), value: 'month' },
      { label: intl.formatMessage({ id: 'daterange.year' }), value: 'year' },
      { label: intl.formatMessage({ id: id }, { date: startDisplay, endDate: endDisplay }), value: 'custom' }
    ];

    return (
      <div>
        <MenuStyle />
        <StyledSelect
          className="date-filter"
          menuClassName="date-filter-menu"
          onChange={this.selectOnChange}
          options={selectOptions}
          value={this.value}
        />
      </div>
    );
  }
}

DateFilter.defaultProps = {
  rangeType: 'all'
};

export default injectIntl(DateFilter);
