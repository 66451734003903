import { providers } from 'dc-core';
import logger from '../utils/logger';

/**
 * User's Esign Access Limits Information
 */
export default class UserEsignLimits {
  constructor() {
    this.promise = null;
  }

  fetch() {
    this.promise = providers
      .user()
      .then(user => {
        return user.getLimitsEsign().then(esignAccess => {
          this.esignAccess = esignAccess;
          return this;
        });
      })
      .catch(e => {
        logger.warn('Unable to get user esign limits', { error: e });
      });
  }

  doesUserHaveNoSignAccessOrReadOnlySignAccess() {
    return this.esignAccess === 'no_access' || this.esignAccess === 'read_only';
  }

  ready() {
    return this.promise;
  }
}
