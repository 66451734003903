import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Button from '@react/react-spectrum/Button';
import Popover from '@react/react-spectrum/Popover';

import FilterIcon from 'dc-icons/organizer/s_filter_18.svg';

import FilterTags from './FilterTags';
import Filters from './Filters';

import { analyticsFor } from '../../utils/analytics';
import { media, isMedia } from '../../utils/mediaQuery';

const FilterGroupContainer = styled.div`
  display: flex;
`;

const StyledPopover = styled(Popover)`
  width: 430px;
  padding-top: 16px !important;
`;

const getFilterCount = ({ dateRangeType, selectedGroup, visibility }) => {
  const count = [dateRangeType, selectedGroup].filter(item => !!item).length;
  return visibility === 'SHOW_HIDDEN' ? count + 1 : count;
};

const FilterGroup = props => {
  const isPhone = isMedia(media.phone);
  const filterCount = getFilterCount(props);
  const labelId = isPhone && filterCount > 0 ? 'filter.label.count' : 'filter.label';
  const actionButton = (
    <Button
      className="filter-button"
      label={props.intl.formatMessage({ id: labelId }, { count: filterCount })}
      icon={<FilterIcon className="spectrum-Icon spectrum-Icon--sizeS" role="img" />}
      variant="action"
      onClick={() => {
        props.sendAnalytics(analyticsFor.FILTER_ICON_CLICK, {
          filterPopoverOpen: props.isPopoverOpen
        });
        if (!props.isPopoverOpen) {
          props.sendAnalytics(analyticsFor.FILTER_OPEN);
          if (!isPhone) {
            props.setPopoverOpen(true);
          }
        }
      }}
    />
  );
  const filterComponent = (
    <Filters
      dateRangeType={props.dateRangeType}
      startDate={props.startDate}
      endDate={props.endDate}
      selectedGroup={props.selectedGroup}
      groups={props.groups}
      visibility={props.visibility}
      closePopover={() => {
        props.setPopoverOpen(false);
      }}
      sendAnalytics={props.sendAnalytics}
      routingUtil={props.routingUtil}
      open={props.isPopoverOpen}
      renderAsModal={isPhone}
      {...props}
    />
  );

  return (
    <FilterGroupContainer>
      {!isPhone && <FilterTags {...props} />}
      {!isPhone ? (
        <OverlayTrigger
          boundariesElement="container"
          placement={props.popoverPlacement}
          show={props.isPopoverOpen}
          trigger="click"
        >
          {actionButton}
          <StyledPopover className="filter-popover">{filterComponent}</StyledPopover>
        </OverlayTrigger>
      ) : (
        <ModalTrigger>
          {actionButton}
          {filterComponent}
        </ModalTrigger>
      )}
    </FilterGroupContainer>
  );
};

FilterGroup.defaultProps = {
  isPopoverOpen: false
};

const groupShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
});

FilterGroup.propTypes = {
  isPopoverOpen: PropTypes.bool,
  setPopoverOpen: PropTypes.func,
  visibility: PropTypes.string,
  dateRangeType: PropTypes.string,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  selectedGroup: groupShape,
  groups: PropTypes.arrayOf(groupShape),
  routingUtil: PropTypes.shape({
    setDateRange: PropTypes.func.isRequired,
    setGroup: PropTypes.func.isRequired,
    setQueryableField: PropTypes.func.isRequired,
    setVisibility: PropTypes.func.isRequired
  }).isRequired,
  sendAnalytics: PropTypes.func.isRequired,
  intl: PropTypes.shape.isRequired,
  popoverPlacement: PropTypes.string
};

// export the component with the localizations injected
export default injectIntl(FilterGroup);
