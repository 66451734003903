/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPlainTableCell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PlainTableCell = ({ className, children }) => {
  return <StyledPlainTableCell className={className}>{children}</StyledPlainTableCell>;
};

PlainTableCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default PlainTableCell;
