import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';

import { Tag } from '@react/react-spectrum/TagList';

import { formatDateForDisplay, isSameDate } from '../../utils/DateUtil';
import { analyticsFor } from '../../utils/analytics';

const TagContainer = styled.div`
  display: flex;
  padding-right: 6px;
`;

const FilterTag = ({ messageId, data, clearFilter, isPopoverOpen, sendAnalytics }) => {
  return (
    <Tag
      onClose={() => {
        if (isPopoverOpen === true) {
          return;
        }
        sendAnalytics(analyticsFor.FILTER_TAG_CLEAR, { messageId });
        clearFilter();
      }}
      onClick={e => {
        sendAnalytics(analyticsFor.FILTER_TAG_CLICK, {
          messageId,
          filterPopoverOpen: isPopoverOpen,
          filterTagCloseClick: e.target.tagName === 'BUTTON'
        });
      }}
      closable
    >
      <FormattedMessage id={messageId} values={data} />
    </Tag>
  );
};

const FilterTags = ({
  visibility,
  dateRangeType,
  selectedGroup,
  routingUtil,
  startDate,
  endDate,
  queryableField,
  sendAnalytics,
  isPopoverOpen,
  intl
}) => {
  return (
    <TagContainer>
      {dateRangeType && dateRangeType !== 'custom' && (
        <FilterTag
          messageId={`daterange.${dateRangeType}`}
          clearFilter={() => routingUtil.setDateRange()}
          isPopoverOpen={isPopoverOpen}
          sendAnalytics={sendAnalytics}
        />
      )}
      {dateRangeType === 'custom' && (
        <FilterTag
          messageId={isSameDate(startDate, endDate) ? 'filter.date.day' : 'filter.date.range'}
          clearFilter={() => routingUtil.setDateRange()}
          isPopoverOpen={isPopoverOpen}
          sendAnalytics={sendAnalytics}
          data={{
            date: formatDateForDisplay(startDate, intl),
            endDate: formatDateForDisplay(endDate, intl)
          }}
        />
      )}
      {selectedGroup && (
        <FilterTag
          messageId={'filter.group.tag'}
          clearFilter={() => routingUtil.setGroup()}
          isPopoverOpen={isPopoverOpen}
          sendAnalytics={sendAnalytics}
          data={{
            name: selectedGroup.label
          }}
        />
      )}
      {queryableField && queryableField !== 'all' && (
        <FilterTag
          messageId={`filter.field.${queryableField
            .replace('originalFileName', 'filename')
            .replace('recipientGroupName', 'groupName')
            .replace('textFieldContent', 'text')}`}
          clearFilter={() => routingUtil.setQueryableField()}
          isPopoverOpen={isPopoverOpen}
          sendAnalytics={sendAnalytics}
        />
      )}
      {visibility === 'SHOW_HIDDEN' && (
        <FilterTag
          messageId="filter.visibility.hidden"
          clearFilter={() => routingUtil.setVisibility('SHOW_VISIBLE')}
          isPopoverOpen={isPopoverOpen}
          sendAnalytics={sendAnalytics}
        />
      )}
    </TagContainer>
  );
};

FilterTags.defaultProps = {};

FilterTags.propTypes = {
  visibility: PropTypes.string,
  dateRangeType: PropTypes.string,
  selectedGroup: PropTypes.object,
  routingUtil: PropTypes.shape({
    setDateRange: PropTypes.func.isRequired,
    setVisibility: PropTypes.func.isRequired
  }).isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  sendAnalytics: PropTypes.func.isRequired,
  isPopoverOpen: PropTypes.bool,
  intl: PropTypes.shape.isRequired
};

// export the component with the localizations injected
export default injectIntl(FilterTags);
