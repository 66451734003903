/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/**
 * A temporary copy of some of the device provider implementation. This code
 * is to be removed once the device provider is moved into dc-core.
 */
class DeviceUtil {
  static isTouchDevice() {
    return navigator.maxTouchPoints > 0 || (!!navigator.platform && /iPad|iPhone/.test(navigator.platform));
  }

  static isMobileDevice() {
    return DeviceUtil.isTouchDevice() && window.orientation !== undefined;
  }
}

export default DeviceUtil;
